import closeIcon from "assets/vote/2022uniform/icon_close.png";

const CloseButton = ({ handleClose, fill, size }) => {
  return (
    <>
      <button
        onClick={handleClose}
        className="z-30 px-[43px] py-[29px] absolute right-0 top-0"
      >
        <img src={closeIcon} alt="closeIcon" className="sm:w-[18px] w-[14px]" />
      </button>
    </>
  );
};
export default CloseButton;
