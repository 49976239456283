import { useState, useContext, useEffect } from "react";
import { getCandidatesModal } from "services/vote/2023superpowers";
import { SUPERPOWERS_GAME_ID } from "config/CONSTANTS";
import { SuperpowersGameInfoContext } from "store/contex";
import styled from "styled-components";
import Modal from "components/vote/2023super/Modal";
import CloseButton from "components/vote/2023super/CloseButton";
import VoteButton from "components/vote/2023super/VoteButton";
import tiktokImage from "assets/vote/2023superpowers/DESKTOP/TikTok.png";
import igImage from "assets/vote/2023superpowers/DESKTOP/IG.png";
import moreImage from "assets/vote/2023superpowers/DESKTOP/more.png";
import gtag from "ga-gtag";
import WinPrize from "./WinPrize";
import Video from "./Video";

//test
// import test1 from "assets/vote/2022uniform/TEST/297103462_742414503708592_2454947768108980428_n.jpg";
// import test2 from "assets/vote/2022uniform/TEST/IMG_5722.JPG.jpg";
// import test3 from "assets/vote/2022uniform/TEST/IMG_5723.JPG.jpg";

const maxPlayerCount = 6;

// const testPhoto = [test1, test2, test3];
// const slideSettings = {
//   dots: true,
//   infinite: true,
//   arrows: false,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
// };

const CandidatesStyle = styled.div`
  background-size: 100% auto;
  position: relative;
  background-repeat: repeat-y;
  background-position: center center;
  width: 100vw;
  background-image: url(${(props) => props.backgroundImage});
`;

const CandidatesModal = styled.div`
  position: relative;
  width: 100%;
  max-width: 1000px;
  height: 90%;
  max-height: 640px;
  margin: 50px;
  border-radius: 30px;
  background: #1d1742;
  @media (max-width: 768px) {
    width: 90%;
    min-width: 320px;
    /* overflow: hidden; */
    /* max-width: 374px; */
    /* height: 0; */
    /* height: 286px; */

    /* padding-bottom: 76.47%; */
  }
  @media (max-width: 374px) {
    /* max-width: 374px;
    height: 0;
    max-height: 286px;
    padding-bottom: calc(76.47% - 34px); */
  }
  /* @media (max-width: 767px) {
    background-size: contain;
    max-width: 750px;
    height: 1092px;
  }
  @media (max-width: 510px) {
    background-size: contain;
    max-width: 400px;
    height: 1092px;
  } */
  .flex-item:nth-of-type(1) {
    order: 1;
  }
  .flex-item:nth-of-type(2) {
    order: 3;
  }
  .flex-item:nth-of-type(3) {
    order: 2;
  }
  .flex-item:nth-of-type(4) {
    order: 4;
  }
`;

const SeeMoreButton = styled.button`
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  /* width: 212px; */
  /* height: 67px; */
  padding: 18px 36px;
  background: #fc7454;
  font-weight: 400;
  border-radius: 37px;
  &:hover {
    /* background: #ffe000; */
  }
  @media (max-width: 510px) {
    width: 150px;
    width: 139px;
    height: 44px;
    font-size: 1rem;
  }
`;

export const CandidatesList = (props) => {
  const { gameInfo } = useContext(SuperpowersGameInfoContext);
  const {
    refIFrame,
    candidates,
    initPlayers,
    setInitPlayers,
    announceTime,
    userSession,
    winnerPlayers,
  } = props;
  const [modalData, setModalData] = useState(null);
  const [toggleMenuModal, setToggleMenuModal] = useState(false);
  const [playerModalInfo, setPlayerModalInfo] = useState(null);
  const [query, setQuery] = useState("");
  const [searchParam] = useState(["entryNumber", "nickname", "school"]);
  const [showNumber, setShowNumber] = useState(1);
  const filtered = [...candidates];
  const [nowPlayers, setNowPlayers] = useState([]);
  const [isCompositionEnd, setIsCompositionEnd] = useState(false);
  const clickModalHandler = (playerId) => {
    getCandidatesModal(playerId)
      .then((res) => {
        // console.log("layerModalInfo", res);
        setPlayerModalInfo(res.player);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
  };

  useEffect(() => {
    setNowPlayers(initPlayers.slice(0, showNumber * maxPlayerCount));
  }, [initPlayers, showNumber]);
  useEffect(() => {
    handleComposition();
  }, []);

  useEffect(() => {
    if (isCompositionEnd) {
      searchHandler();
    }
  }, [query, isCompositionEnd]);

  const handlingComposition = () => {
    setIsCompositionEnd(false);
  };
  const handleComposition = (e) => {
    setIsCompositionEnd(true);
  };

  useEffect(() => {
    if (isCompositionEnd) {
      searchHandler();
    }
  }, [query, isCompositionEnd]);

  const searchHandler = () => {
    setInitPlayers(
      filtered.filter((item) => {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          );
        });
      })
    );
    setShowNumber(1);
  };

  // const clickModalHandler = (playerId) => {
  //   getCandidatesModal(playerId)
  //     .then((res) => {
  //       setPlayerModalInfo(res.player);
  //     })
  //     .catch((err) => {
  //       console.log("axios err=", err);
  //     });
  // };

  const candidatesModalHandler = () => {
    setToggleMenuModal((preState) => !preState);
  };

  const voteHandler = (playerId) => {
    // ga4 api
    gtag("event", "點擊投票", {
      gameId: SUPERPOWERS_GAME_ID,
      userSession: userSession,
      send_to: "G-SR7XCS1GYX",
    });

    // bat api
    window.bat.track("buttonClick", {
      buttonNm: "點擊投票",
      gameId: SUPERPOWERS_GAME_ID,
      userSession: userSession,
    });

    // line tracking
    window._lt("send", "cv", { type: "vote" }, [
      "4255d428-e2bb-4c10-8a89-562815a6d9de",
    ]);
    gtag("event", "conversion", {
      send_to: "AW-862236629/Sv2uCKfJwdwDENXfkpsD",
    });

    const iframeItem = refIFrame.current;
    iframeItem.contentWindow.postMessage(
      JSON.stringify({
        method: "add_vote",
        data: {
          gameId: SUPERPOWERS_GAME_ID,
          playerId,
        },
      }),
      "*"
    );
  };

  return (
    <CandidatesStyle
      backgroundImage={
        window.innerWidth >= 640
          ? gameInfo && gameInfo.setPictures.background_2_D
          : gameInfo && gameInfo.setPictures.background_2_M
      }
    >
      <div className="w-[90%] sm:w-[75%] m-auto sm:pt-[128px] pt-[64px]">
        <div className="flex justify-end mx-auto">
          <div className="min-w-[160px]  w-[160px] relative sm:w-1/2 mr-[10%] sm:mr-0  flex sm:justify-center lg:justify-end justify-center lg:w-1/3 ">
            {/* <Search searchHandler={searchHandler}/> */}
            <div className="relative sm:w-[80%] w-[100%] z-20  ">
              <input
                className="h-[40px] leading-[36px] rounded-full bg-transparent text-12px sm:text-base font-black border-[2px] border-yellow-4 text-yellow-4 placeholder:text-yellow-4 w-full outline-none pl-[12px] sm:pl-[20px] pr-[26px] sm:pr-[41px]"
                type="text"
                placeholder="輸入編號 / 暱稱 / 學校"
                value={query}
                id="search"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onCompositionStart={handlingComposition}
                onCompositionUpdate={handlingComposition}
                onCompositionEnd={handleComposition}
              />
              {/* <div className="text-[12px] scale-[0.83]">
                {" "}
                輸入編號 / 暱稱 / 學校
              </div> */}
              {/* <div className="absolute h-full flex items-center sm:right-[18px] right-[8px] top-0"> */}
              {/* <img
                  src={searchIcon}
                  alt="searchIcon"
                  className="sm:h-[22px] h-[14px]"
                /> */}
              <svg
                className="absolute h-full hidden sm:flex items-center right-[18px] top-0"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.7099 20.2899L17.9999 16.6099C19.44 14.8143 20.1374 12.5352 19.9487 10.2412C19.76 7.94721 18.6996 5.81269 16.9854 4.27655C15.2713 2.74041 13.0337 1.91941 10.7328 1.98237C8.43194 2.04534 6.24263 2.98747 4.61505 4.61505C2.98747 6.24263 2.04534 8.43194 1.98237 10.7328C1.91941 13.0337 2.74041 15.2713 4.27655 16.9854C5.81269 18.6996 7.94721 19.76 10.2412 19.9487C12.5352 20.1374 14.8143 19.44 16.6099 17.9999L20.2899 21.6799C20.3829 21.7736 20.4935 21.848 20.6153 21.8988C20.7372 21.9496 20.8679 21.9757 20.9999 21.9757C21.1319 21.9757 21.2626 21.9496 21.3845 21.8988C21.5063 21.848 21.6169 21.7736 21.7099 21.6799C21.8901 21.4934 21.9909 21.2442 21.9909 20.9849C21.9909 20.7256 21.8901 20.4764 21.7099 20.2899ZM10.9999 17.9999C9.61544 17.9999 8.26206 17.5894 7.11091 16.8202C5.95977 16.051 5.06256 14.9578 4.53275 13.6787C4.00293 12.3996 3.86431 10.9921 4.13441 9.63427C4.4045 8.27641 5.07119 7.02912 6.05016 6.05016C7.02912 5.07119 8.27641 4.4045 9.63427 4.13441C10.9921 3.86431 12.3996 4.00293 13.6787 4.53275C14.9578 5.06256 16.051 5.95977 16.8202 7.11091C17.5894 8.26206 17.9999 9.61544 17.9999 10.9999C17.9999 12.8564 17.2624 14.6369 15.9497 15.9497C14.6369 17.2624 12.8564 17.9999 10.9999 17.9999Z"
                  fill="#F3D353"
                />
              </svg>
              <svg
                className="absolute h-full flex items-center right-[14px] top-0  sm:hidden"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.7099 20.2899L17.9999 16.6099C19.44 14.8143 20.1374 12.5352 19.9487 10.2412C19.76 7.94721 18.6996 5.81269 16.9854 4.27655C15.2713 2.74041 13.0337 1.91941 10.7328 1.98237C8.43194 2.04534 6.24263 2.98747 4.61505 4.61505C2.98747 6.24263 2.04534 8.43194 1.98237 10.7328C1.91941 13.0337 2.74041 15.2713 4.27655 16.9854C5.81269 18.6996 7.94721 19.76 10.2412 19.9487C12.5352 20.1374 14.8143 19.44 16.6099 17.9999L20.2899 21.6799C20.3829 21.7736 20.4935 21.848 20.6153 21.8988C20.7372 21.9496 20.8679 21.9757 20.9999 21.9757C21.1319 21.9757 21.2626 21.9496 21.3845 21.8988C21.5063 21.848 21.6169 21.7736 21.7099 21.6799C21.8901 21.4934 21.9909 21.2442 21.9909 20.9849C21.9909 20.7256 21.8901 20.4764 21.7099 20.2899ZM10.9999 17.9999C9.61544 17.9999 8.26206 17.5894 7.11091 16.8202C5.95977 16.051 5.06256 14.9578 4.53275 13.6787C4.00293 12.3996 3.86431 10.9921 4.13441 9.63427C4.4045 8.27641 5.07119 7.02912 6.05016 6.05016C7.02912 5.07119 8.27641 4.4045 9.63427 4.13441C10.9921 3.86431 12.3996 4.00293 13.6787 4.53275C14.9578 5.06256 16.051 5.95977 16.8202 7.11091C17.5894 8.26206 17.9999 9.61544 17.9999 10.9999C17.9999 12.8564 17.2624 14.6369 15.9497 15.9497C14.6369 17.2624 12.8564 17.9999 10.9999 17.9999Z"
                  fill="#F3D353"
                />
              </svg>
              {/* </div> */}
            </div>
          </div>
        </div>

        <div className="mx-auto flex flex-wrap sm:pt-[48px] pt-[40px] pb-[61px]">
          {nowPlayers.map((candidate, i) => (
            <div
              key={candidate.playerId}
              className={`relative w-full lg:w-1/3 sm:w-3/6 flex flex-col items-center sm:mb-[67px] mb-[48px] text-white  ${
                (i + 1) % 3 === 1 ? "lg:items-start" : ""
              } ${(i + 1) % 3 === 2 ? "lg:items-center" : ""} ${
                (i + 1) % 3 === 0 ? "lg:items-end" : ""
              }`}
            >
              <div className="relative w-[80%]">
                <div className="relative">
                  <div
                    // onClick={() => {
                    //   setToggleMenuModal((preState) => !preState);
                    //   setModalData(candidate);
                    //   clickModalHandler(candidate.playerId);
                    // }}
                    className="relative w-[100%] h-0 pb-[177.8%] mb-[20px]    group sm:overflow-hidden"
                  >
                    <Video video={candidate.playerVideo} />
                    <div className=" absolute top-0 sm:top-0 flex w-full justify-center pt-[4px] text-16px sm:text-20px font-bold h-[80px] bg-gradient-to-t from-[rgba(0,0,0,0)] to-[rgba(0,0,0,0.8)]">
                      No.{candidate.entryNumber}
                    </div>
                  </div>
                  {announceTime && candidate && (
                    <WinPrize
                      winnerPlayers={winnerPlayers}
                      userId={candidate.playerId}
                      candidatesModalHandler={candidatesModalHandler}
                      setModalData={setModalData}
                      candidate={candidate}
                      clickModalHandler={clickModalHandler}
                    />
                  )}
                </div>
                <div className=" flex flex-col justify-between w-full w-auto ">
                  <div className="inline-flex  justify-between ">
                    {/* mx-10px */}
                    <div
                      onClick={() => {
                        candidatesModalHandler();
                        setModalData(candidate);
                        clickModalHandler(candidate.playerId);
                      }}
                      className=" sm:text-[22px] text-[20px] cursor-pointer flex items-center font-bold underline"
                    >
                      {candidate.nickname}
                    </div>
                    {/* 票 */}
                    <div
                      className={`relative top-0 inline-flex text-yellow-4  items-center font-extrabold`}
                    >
                      <div className="text-[20px] sm:text-[22px]">
                        <span className={`  `}>
                          {String(candidate.voteCount).replace(
                            /(\d)(?=(\d{3})+$)/g,
                            "$1,"
                          )}
                        </span>
                        <span className=""> 票</span>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="flex items-center sm:pt-[14px] pt-[16px]">
                    <div className="sm:text-[18px] text-[16px] font-bold whitespace-pre-line ">
                      {candidate.school.replace("(", " \u000A (")}
                    </div>
                    <div className="flex justify-end mt-0  xl:pr-0px  flex-1">
                      <VoteButton
                        voteHandler={() => voteHandler(candidate.playerId)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {candidates.length !== 0 &&
            showNumber < initPlayers.length / maxPlayerCount && (
              <div className="flex justify-center w-full sm:pb-[35px] pb-[27px] mt-m10px sm:mt-[61px]">
                <button
                  className="w-[190px] sm:w-[244px] h-[50px] sm:h-[64px] rounded-full text-[25px] sm:text-32px text-black-4 bg-yellow-4  flex justify-center font-LINESeedJP_TTF_Eb items-center shadow-superButton"
                  onClick={() => {
                    setShowNumber(showNumber + 1);
                  }}
                >
                  <span className="mr-[4px] sm:mr-[8px]">看更多影片</span>
                  <img
                    src={moreImage}
                    alt="+ icon"
                    className="w-[16px] sm:w-[22px]"
                  />
                  {/* <img
                    src={
                      window.innerWidth >= 768
                        ? seeMore_button_pic_D
                        : seeMore_button_pic_M
                    }
                    alt="查看更多"
                    onClick={() => {
                      setShowNumber(showNumber + 1);
                    }}
                  /> */}
                </button>
                {/* <SeeMoreButton
                  onClick={() => {
                    setShowNumber(showNumber + 1);
                  }}
                >
                  <span>看更多制服</span>
                </SeeMoreButton> */}
              </div>
            )}

          <Modal
            handleClose={() => setToggleMenuModal(false)}
            isOpen={toggleMenuModal}
          >
            {playerModalInfo && (
              <CandidatesModal
                backgroundImage={
                  window.innerWidth < 640
                    ? gameInfo && gameInfo.setPictures.popup_M
                    : gameInfo && gameInfo.setPictures.popup_D
                }
                className="z-20 mx-auto "
              >
                <CloseButton handleClose={() => setToggleMenuModal(false)} />
                <div className="block  md:flex md:flex-row h-full   relative py-[40px] md:pt-[58px] md:pb-[70px] px-[40px] lg:px-[53px] justify-center overflow-y-scroll  scroll  min-h-0 flex-1 m-auto font-NotoSansTC">
                  {" "}
                  <h2 className="flex text-yellow-4 text-22px font-black mb-[13px]  md:hidden justify-center">
                    <span className="mr-[16px]">
                      No.{modalData.entryNumber}
                    </span>
                    <span>{modalData.nickname} </span>
                  </h2>
                  <div className="flex justify-center">
                    <div
                      className="w-[216px] h-[384.048px]  md:w-[288px] md:h-0 md:pb-[177.8%]
                          bg-[#d8d8d8] relative"
                    >
                      {playerModalInfo.playerVideo?.url ? (
                        <Video video={playerModalInfo.playerVideo} />
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-col items-center pt-[12px] md:pt-0 ml-0 md:ml-[40px] lg:ml-[64px]  md:h-[504.98px] md:w-full md:max-w-[479px]">
                    <div className="flex flex-col justify-between w-[216px] md:w-full md:max-w-[479px]  h-full">
                      <div className="flex-grow">
                        <h2 className="hidden md:flex text-yellow-4 text-32px font-black mb-[20px]">
                          <span className="mr-[16px]">
                            No.{modalData.entryNumber}
                          </span>
                          <span>{modalData.nickname} </span>
                        </h2>
                        <div className="whitespace-pre-line text-base md:text-20px text-white font-bold border-b-[0.67px] md:border-b border-white">
                          <p className="pb-[10px] md:pb-[20px] flex">
                            <span className="shrink mr-[1/2em]">學校 ： </span>
                            <span>
                              {" " + modalData.school.replace("(", " \u000A (")}
                            </span>
                          </p>

                          <p className="md:pb-[6px] flex">
                            <span
                              className="block shrink mr-[1/2em] 
                            min-w-[85.5px] md:min-w-[104.5px]"
                            >
                              興趣專長 ：
                            </span>
                            <span className="block">
                              {playerModalInfo?.info?.興趣專長}
                            </span>
                          </p>
                          <div className="pb-[4px] md:pb-[14px] flex items-center">
                            <p>社群 ：</p>
                            <div className="flex">
                              {playerModalInfo.socialLinks.instagram ? (
                                <a
                                  href={`https://www.instagram.com/${playerModalInfo.socialLinks.instagram}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className=""
                                >
                                  <img
                                    src={igImage}
                                    alt="igIcon"
                                    className="h-[40px] sm:h-[50px]"
                                  />
                                </a>
                              ) : null}
                              {playerModalInfo.socialLinks.tikTok ? (
                                <a
                                  href={`https://www.tiktok.com/@${playerModalInfo.socialLinks.tikTok}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className=""
                                >
                                  <img
                                    src={tiktokImage}
                                    alt="tiktokIcon"
                                    className="h-[40px] sm:h-[50px]"
                                  />
                                </a>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="pt-[12px] md:pt-[24px] text-white">
                          <p className="text-base md:text-20px  font-bold pb-[8px]">
                            影片介紹 ：
                          </p>
                          <p className="text-14px md:text-18px  font-bold">
                            {playerModalInfo.intro}
                          </p>
                        </div>
                      </div>
                      <div className="block mt-[24px] md:mt-0 md:flex justify-between text">
                        <p className="text-yellow-4 text-22px md:text-28px font-extrabold flex justify-between md:justify-start w-full flex-wrap font-LINESeedJP_TTF_Eb">
                          <span className="md:mr-[16px]">目前票數</span>{" "}
                          <span>
                            {" "}
                            {String(modalData.voteCount).replace(
                              /(\d)(?=(\d{3})+$)/g,
                              "$1,"
                            )}{" "}
                            票
                          </span>
                        </p>
                        <div className="shrink  flex justify-center mt-[10px] md:mt-0">
                          <button
                            onClick={() => voteHandler(modalData.playerId)}
                            className="w-[130px] h-[44px] flex justify-center items-center  text-base bg-yellow-4 rounded-full md:text-[20px]  font-black text-18px  text-black-4 shadow-superButton"
                          >
                            <span className="">投他 1 票</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CandidatesModal>
            )}
          </Modal>
        </div>
      </div>
    </CandidatesStyle>
  );
};
