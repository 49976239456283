import styled from "styled-components/macro";
import { MEDIA_QUERY_MD } from "assets/styles/responsive";
import forkIcon from "assets/playground/roulette/Desktop/Meteor_component_10_D.png";
import ErrorPopupLayout from "./ErrorPopupLayout";

const PopUpsButton = styled.button`
  background-image: url(${forkIcon});
  background-size: cover;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  ${MEDIA_QUERY_MD} {
    width: 27px;
    height: 27px;
    top: 0;
    right: 0;
  }
`;

const PopupContent = styled.p`
  font-size: 26px;
  color: #ec6e67;
  line-height: 40px;
  text-align: center;
  font-weight: 900;
  white-space: pre-line;
  letter-spacing: 0.1em;
  ${MEDIA_QUERY_MD} {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.08em;
  }
`;

const ErrorPopup = ({ handlePopupsRemove, errorMessage }) => {
  return (
    <ErrorPopupLayout>
      <PopUpsButton onClick={handlePopupsRemove}></PopUpsButton>
      <PopupContent>{errorMessage}</PopupContent>
    </ErrorPopupLayout>
  );
};
export default ErrorPopup;
