import styled from "styled-components/macro";
import { MEDIA_QUERY_MD } from "assets/styles/responsive";
import { centerShow } from "./animation";

const ErrorPopUpsWrapper = styled.div`
  position: fixed;
  width: 442px;
  top: 50%;
  left: 50%;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);
  border-radius: 18px;
  padding: 37px 48px;
  animation: ${centerShow} 0.3s ease-in-out;
  ${MEDIA_QUERY_MD} {
    width: 300px;
    padding: 33px 43px;
  }
`;

const ErrorPopupLayout = ({ children }) => {
  return <ErrorPopUpsWrapper>{children}</ErrorPopUpsWrapper>;
};
export default ErrorPopupLayout;
