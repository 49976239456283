import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { UniformGameInfoContext } from "store/contex";
import styled from "styled-components";
import Accordion from "components/vote/2022uniform/Accordion";
import hero_red_2_D from "assets/vote/2022uniform/DESKTOP/red_2_full_D.png";

const NoticeStyle = styled.div`
  position: relative;
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
  background-position: center bottom;
  max-width: 2880px;
  padding-top: 150px;
  padding-bottom: 126px;
  /* min-height: 500px; */
  background-image: url(${(props) => props.backgroundImage});
  background-size: 50%;
  /* padding: 3.125rem 2.5rem 15rem 2.8125rem; */

  .red {
    color: red;
  }
  .arrow img {
    margin-left: 27px;
  }
  .accordion-item {
    ul {
      list-style-type: disc;
      list-style-position: inside;
      font-size: 20px;
      color: #343434;
      letter-spacing: 2px;
      /* line-height: 1.7; */
      /* li:before {
        content: "";
        width: 6px;
        height: 6px;
        background-color: #000;
        border-radius: 50%;
        top: 0;
      } */
      li:first-child {
        /* padding-top: 23px; */
        padding-top: 23px;
        padding-bottom: 5px;
      }
      li:last-child {
        /* margin-bottom: 20px; */
      }
      li:not(:first-child) {
        padding-top: 24.5px;
        /* padding-bottom: 20px;
        margin-bottom: 20px; */
      }
      li:not(:last-child) {
        border-bottom: 0.5px solid #febe03;
        /* padding-bottom: 20px; */
        padding-bottom: 20px;
      }
    }
    li {
      text-indent: -28px;
      padding-left: 28px;
    }
    .accordion-title {
      /* font-weight: 900; */
      /* font-size: 1rem; */
    }
    .accordion-content {
      font-size: 0.875rem;
    }
  }
  @media (max-width: 640px) {
    /* padding: 6.1875rem 16.4375rem 30rem 16.4375rem; */
    padding-top: 75px;
    padding-bottom: 63px;
    .accordion-item {
      ul {
        font-size: 12px;
        li:first-child {
          /* margin-top: 2rem; */
        }
        li:not(:first-child) {
          padding-top: 11px;
          /* padding-bottom: 20px;
        margin-bottom: 20px; */
        }
        li:not(:last-child) {
          border-bottom: 0.5px solid #febe03;
          /* padding-bottom: 20px; */
          padding-bottom: 9px;
        }
        li:not(:last-child) {
          /* margin-bottom: 2rem; */
          /* padding-bottom: 2rem; */
        }
        li {
          text-indent: -16px;
          padding-left: 16px;
        }
      }
      .accordion-title {
        font-size: 16px;
      }
      .accordion-content {
        font-size: 12px;
      }
    }
  }
`;
export const Notice = () => {
  const { gameInfo } = useContext(UniformGameInfoContext);
  const accordionData = [
    {
      index: 1,
      title: "投票規範",
      content: (
        <ul className="font-GenYoGothic-B ">
          <li>登入通過審核的Meteor帳號(會員)每日可投3票</li>
          <li>使用FB登入(未通過審核的帳號)每日可投1票</li>
          <li>
            於活動頁右上方分享活動到Line或是FB單日可增加一票，分享到兩個社群可得2票
          </li>
          <li>可重複投給同一位參賽者</li>
        </ul>
      ),
    },

    {
      index: 2,
      title: "注意事項",
      content: (
        <ul className="font-GenYoGothic-B ">
          <li>
            當您參與本活動時，即表示您已充分閱讀、瞭解並同意接受本活動辦法之所有內容。包含活動方法、免責聲明、個人資料之蒐集辦法、領獎規範等。如有違反本注意事項之內容者，主辦方得取消其參加或得獎者資格。
          </li>
          <li>
            參賽者，若為未滿20歲之未成年人，應取得您的法定代理人或監護人之同意。法定代理人及監護人須充分瞭解本活動內容、活動條款等，參賽者方得參與本活動。當您參加本活動時，即視為您的法定代理人或監護人已同意並接受本活動之所有內容規範。
          </li>
          <li>
            對於個人資料，僅做為本活動使用，主辦方將盡保密之責，不做其他用途亦不會對外洩漏。詳細個人資料之規範詳閱此連結內容
            <a href="https://wooo.tw/8a9pWMm">https://wooo.tw/8a9pWMm</a>
          </li>
          <li>
            主辦方保有審核參加資格之權利，若使用不適當之圖片，主辦方得逕自刪除其內容並取消參加或獲選資格，不另行通知。
          </li>
          <li>
            參賽者有權擔保於活動網站所上傳之所有內容（包括但不限於活動照片等），須為原創內容，及參賽者個人所擁有，且該內容並未侵犯第三人之權利（包括但不限於智慧財產權等）。
          </li>
          <li>
            參賽者若為違反其相關法令，除需自行向第三者負損害賠償責任，並與主辦方無涉。惟，若主辦方因此受有損害者，參賽者應對主辦方負損害賠償責任。
          </li>
          <li>
            參賽者同意參與本活動時，主辦方即擁有所有參賽者於本活動之專屬授權，其授權內容（如照片），不得再提供其他商業活動使用，且主辦方（即被授權方）得於專案範圍內為轉授權。
          </li>
          <li>
            主辦方得於使用期限結束後，除參賽者以書面要求下架外，得免除下架。
          </li>
          <li>主辦方得於授權範圍內，得以著作財產權人之地位行使權利。</li>
          <li>主辦方有權使用參賽者於本活動上傳之照片進行行銷、宣傳用途。</li>
          <li>
            主辦方活動期間為維護比賽公平性，禁止網友發表不當言論內容、從事任何非法程式、假借不真實身分參加本活動，以及任何影響活動公平性或破壞本活動進行之規則，一旦經查證或告發，將取消其參加活動資格，並追究法律責任。
          </li>
          <li>
            主辦方保留擴大、修改、取消、終止該活動以及更改活動截止日期的權利，參賽者及投票者不得對主辦方主張任何權利。
          </li>
        </ul>
      ),
    },
    {
      index: 3,
      title: "領獎相關",
      content: (
        <ul className="font-GenYoGothic-B ">
          <li>得獎者有協助冠名及贊助廠商以及Meteor官方宣傳之義務。</li>
          <li>得獎者獲獎後須配合主辦方與冠名廠商合作影像拍攝事宜。</li>
          <li>
            得獎者必須在Instagram限時動態上分享Meteor指定三篇圖文。如得獎者未配合主辦方推廣，視為得獎者未盡得獎義務，將無法獲得該獎金，得獎者不得藉此向主辦方提出任何請求。
          </li>
          <li>
            截止投票後，將於本活動首頁公布得獎名單資訊並使用Meteor社群之站內信通知得獎者，票數最高前三名者，完成上述條件後可獲得獎金，並有機會洽談進一步的合作機會。
          </li>
          <li>
            比賽得獎名單公佈後，若得獎者未於指定之期限內回覆主辦方，將取消其資格，主辦方將以候補名額遞補。
          </li>
          <li>
            活動獎金匯款範圍僅限台、澎、金、馬地區。得獎者須於指定之期限內，填妥領據後寄回（寄回時間以郵戳為憑），並經由主辦方確認簽收。得獎人若逾指定之期限寄回，視為放棄得獎資格，主辦方不另行通知，得獎者不得藉此向主辦方提出任何請求。
          </li>
          <li>
            得獎者需確認其聯絡方式之正確性。若因資料錯誤，導致無法聯繫時，主辦方將不再另行補寄通知，該得獎者直接取消其資格，主辦方將以後補名額遞補，得獎者不得藉此向主辦方提出任何請求。
          </li>
        </ul>
      ),
    },
  ];

  return (
    <NoticeStyle
      backgroundImage={
        window.innerWidth >= 640
          ? gameInfo && gameInfo.setPictures.background_1_D
          : gameInfo && gameInfo.setPictures.background_1_M
      }
    >
      <img
        src={hero_red_2_D}
        alt="Meteor2022 高校制服大賞紅色二"
        className="absolute bottom-[0] left-[0] max-w-[507px] w-[33%] translate-y-[50%] -translate-x-[50%]"
      />
      <div className="sm:w-[60%] w-full relative m-auto">
        {accordionData.map(({ title, content, index }) => (
          <Accordion
            key={title}
            index={index}
            title={title}
            content={content}
          />
        ))}
      </div>
    </NoticeStyle>
  );
};
