import { useState } from "react";
// import ARROW_UP from "assets/vote/ARROW_UP.png";
// import ARROW_DOWN from "assets/vote/ARROW_DOWN.png";
import plusIcon from "assets/vote/2023superpowers/DESKTOP/more.png";
import minusIcon from "assets/vote/2023superpowers/DESKTOP/less.png";
import styled from "styled-components";

const AccordionTitle = styled.div`
  &::before {
    content: "";
    background: url(${(props) => props.img});
    top: 0;
    right: 0;
    width: ${(props) => props.width};
    height: 20px;
  }
`;

const Accordion = ({ title, content, index }) => {
  const [isActive, setIsActive] = useState(false);
  const clickAccordionHandler = () => {
    setIsActive((prevState) => !prevState);
  };

  return (
    <div className="accordion-item text-18px sm:text-[28px] text-[#1D1742] font-LINESeedJP_TTF_Eb sm:pt-[24px] pt-[16px]  first:pt-0">
      <div
        className="flex justify-center items-center accordion-title arrow cursor-pointer"
        onClick={clickAccordionHandler}
      >
        <div className="relative flex justify-center items-center">
          <span className="mt-[5px] font-LINESeedJP_TTF_Eb">{title}</span>
          {isActive ? (
            <img
              src={minusIcon}
              alt="展開減號"
              width={window.innerWidth >= 640 ? "19px" : "12px"}
              className="absolute top-[50%] sm:-right-[36px] -right-[24px]"
            />
          ) : (
            <img
              src={plusIcon}
              alt="未展開加號"
              width={window.innerWidth >= 640 ? "19px" : "12px"}
              className="absolute top-[50%]  sm:-right-[36px] -right-[24px] -translate-y-1/2"
            />
          )}
        </div>
      </div>
      {isActive && (
        <>
          <div className="accordion-content sm:w-[655px] w-[290px] m-auto">
            {content}
          </div>
        </>
      )}
      {+index === 3 ? null : (
        <hr
          className={`h-[1.5px] sm:h-[2px] bg-[rgba(29,23,66,.3)] sm:w-[655px]  m-auto sm:mt-[24px] mt-[16px] border-t-0 ${
            isActive ? "w-[290px]" : "w-[264px]"
          }`}
        />
      )}
    </div>
  );
};
export default Accordion;
