import styled from "styled-components"

const LoadCss = styled.div`
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    
    @-webkit-keyframes rotate {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    .size__wrapper {
      width: ${props => props.size};
      height: ${props => props.size};
    }

    
    .loading__icon {
      width: 100%;
      height: 100%;
      border: solid 10px ${props => props.color};
      border-radius: 50%;
      border-right-color: transparent;
      border-bottom-color: transparent;
      -webkit-transition: all 0.5s ease-in;
      -webkit-animation-name: rotate;
      -webkit-animation-duration: 1s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
    
      transition: all 0.5s ease-in;
      animation-name: rotate;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  `

const Loading = ({ color = "#f3d353", size= "70px" }) => {
  return (
      <LoadCss
        className="fixed flex justify-center items-center inset-0 overflow-hidden z-50 bg-[rgba(0,0,0,.6)]"
        color={ color }
        size={ size }>
      <div className="size__wrapper">
        <div className="loading__icon"></div>
      </div>
    </LoadCss>
  );
}
export default Loading;
