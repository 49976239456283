import { useState } from "react";
import { UNIFORM_GAME_ID } from "config/CONSTANTS";
import { uploadImage, singUpForm } from "services/vote/2022highschooluniform";
import { useForm } from "react-hook-form";
import gtag from "ga-gtag";

import Modal from "components/vote/Modal";
import Alert from "components/vote/Alert";

import CloseButton from "components/vote/CloseButton";

const SignUp = (props) => {
  const [uploadImageSize, setUploadImageSize] = useState(false);
  const uploadImageModalHandler = () => {
    setUploadImageSize((preState) => !preState);
  };

  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);
  const [image3, setImage3] = useState([]);
  const [image1Url, setImage1Url] = useState("");
  const [image2Url, setImage2Url] = useState("");
  const [image3Url, setImage3Url] = useState("");

  const [singUpErrorMessage, setSingUpErrorMessage] = useState([]);
  const { userSession, setToggleSignUpModal } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const introValue = watch("intro");

  const uploadImg = async (e, type) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const allowedExtensions = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/gif",
    ];

    if (file) {
      if (!allowedExtensions.includes(file.type)) {
        alert("您上傳的檔案格式不支援，請重新上傳～謝謝!");
        return false;
      }
      if (file.size > 3000000) {
        setUploadImageSize(true);
        setSingUpErrorMessage([
          "上傳圖片檔案過大",
          "抱歉上傳圖片不可超過 3MB 喔，請重新上傳檔案，謝謝!",
          false,
        ]);
        return false;
      }
      switch (type) {
        case "image1":
          // for 縮圖顯示
          const image1 = await readFileAsync(file);
          setImage1([image1]);
          // for 縮圖顯示 end
          uploadImage(formData)
            .then((res) => {
              setImage1Url(res.result);
            })
            .catch((err) => {
              console.log("axios err=", err);
            });
          break;
        case "image2":
          const image2 = await readFileAsync(file);
          setImage2([image2]);

          uploadImage(formData)
            .then((res) => {
              setImage2Url(res.result);
            })
            .catch((err) => {
              console.log("axios err=", err);
            });
          break;
        case "image3":
          const image3 = await readFileAsync(file);
          setImage3([image3]);

          uploadImage(formData)
            .then((res) => {
              setImage3Url(res.result);
            })
            .catch((err) => {
              console.log("axios err=", err);
            });
          break;
        default:
          break;
      }
    }
  };
  const readFileAsync = (e) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        generateFromImage(reader.result, 300, 300, 1, (imgdata) => {
          resolve({
            id: performance.now(),
            url: imgdata,
            type: "image",
          });
        });
      };
      reader.onerror = reject;
      reader.readAsDataURL(e);
    });
  };

  const generateFromImage = (
    img,
    MAX_WIDTH = 300,
    MAX_HEIGHT = 300,
    quality = 1,
    callback
  ) => {
    let canvas = document.createElement("canvas");
    let image = new Image();

    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL("image/jpeg", quality);
      callback(dataUrl);
    };
    image.src = img;
  };

  const onSubmit = (data) => {
    const { email, nickname, phone, intro, socialLinks } = data;
    const gameId = UNIFORM_GAME_ID;
    const playerPhotos = [image1Url, image2Url, image3Url];

    const userId = userSession;

    const singUpFormData = {
      gameId,
      userId,
      nickname,
      email,
      phone,
      intro,
      playerPhotos,
      socialLinks: { instagram: socialLinks },
      info: {},
    };

    singUpForm(singUpFormData)
      .then((res) => {
        if (res.playerId) {
          // ga4 api
          gtag("event", "送出報名", {
            gameId: UNIFORM_GAME_ID,
            userSession: userSession,
            send_to: "G-SR7XCS1GYX",
          });

          // bat api
          window.bat.track("buttonClick", {
            buttonNm: "送出報名",
            gameId: UNIFORM_GAME_ID,
            userSession: userSession,
          });
          gtag("event", "conversion", {
            send_to: "AW-862236629/LwrUCMy88dwDENXfkpsD",
          });
          //fb
          window.fbq("track", "CompleteRegistration", {
            gameId: UNIFORM_GAME_ID,
          });
          //line
          window._lt(
            "send",
            "cv",
            {
              type: "CompleteRegistration",
            },
            ["4255d428-e2bb-4c10-8a89-562815a6d9de"]
          );
          alert("報名成功,請等候米特管理員審核喔，謝謝!");
          setToggleSignUpModal(false);
          // setUploadImageSize(true)
          // setSingUpErrorMessage(['報名成功','請等候米特管理員審核喔，謝謝!',true])
          // setUploadImageSize(false)
          return;
        }
      })
      .catch((err) => {
        if (err) {
          setUploadImageSize(true);
          setSingUpErrorMessage([
            "報名失敗",
            err.error + "，若有任何疑問請聯絡官方人員，謝謝!",
            false,
          ]);
          return;
        }
      });
  };

  return (
    <>
      <div className="relative bg-white max-w-xl flex flex-col items-center justify-center rounded-lg py-12 px-4 sm:px-6 lg:px-8 sm:m-0 m-4 font-GenYoGothic-M max-h-[696px] h-[90%]">
        <svg
          className="absolute top-N18px"
          width="195"
          height="52"
          viewBox="0 0 195 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2.37598"
            width="192.602"
            height="40.4439"
            transform="rotate(3.36818 2.37598 0)"
            fill="#FFE000"
            fillOpacity="0.86"
          />
        </svg>
        <CloseButton
          size="50"
          fill={"#282828"}
          handleClose={() => setToggleSignUpModal(false)}
        />
        <div className="w-full overflow-y-auto h-600px">
          <div className="flex items-center justify-center mb-10">
            <span className="mt-6 text-center text-3xl font-extrabold text-gray-900 whitespace-pre-line">
              2022 Meteor{"\u000A"}高校制服大賞報名
            </span>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label
                htmlFor="nickname"
                className="block text-gray-700 text-base font-bold mb-2"
              >
                <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                  暱稱/英文名 (票選活動使用)
                </span>
              </label>
              <input
                id="nickname"
                name="nickname"
                type="text"
                autoComplete="nickname"
                className="appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base"
                placeholder="暱稱最長5個字"
                {...register("nickname", { required: true, maxLength: 5 })}
              />
              {errors.nickname && (
                <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                  請輸入暱稱或不可超過五個字數
                </small>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="email-address"
                className="block text-gray-700 text-base font-bold mb-2"
              >
                <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                  信箱
                </span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className="appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base"
                placeholder="請輸入電子信箱"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                  請輸入電子信箱
                </small>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="phone"
                className="block text-gray-700 text-base font-bold mb-2"
              >
                <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                  連絡電話(僅作活動得獎聯繫之用)
                </span>
              </label>
              <input
                id="phone"
                name="phone"
                type="number"
                autoComplete="phone"
                className="appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base"
                {...register("phone", { required: true, min: 1 })}
              />
              {errors.phone && (
                <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                  請輸入聯絡電話
                </small>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="socialLinks"
                className="block text-gray-700 text-base font-bold mb-2"
              >
                Instagram 帳號 (選填/會顯示在個人票選頁面)
              </label>
              <input
                id="socialLinks"
                name="socialLinks"
                type="text"
                autoComplete="socialLinks"
                className="appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base"
                placeholder="範例: meteor.tw"
                {...register("socialLinks")}
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="intro"
                className="block text-gray-700 text-base font-bold mb-2"
              >
                <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                  制服介紹
                </span>
              </label>
              <textarea
                id="intro"
                name="intro"
                type="text"
                autoComplete="intro"
                className="appearance-none relative block
                        w-full px-[15px] py-[11px] border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base"
                rows="4"
                placeholder="字數限制50字"
                {...register("intro", { required: true, maxLength: 50 })}
              ></textarea>
              {errors.intro && (
                <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                  請輸入制服介紹 or 不可超過50字數限制
                </small>
              )}
              {introValue && introValue.length > 51 ? (
                <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                  不可超過50字數限制
                </small>
              ) : (
                ""
              )}
            </div>

            <div className="flex justify-center bg-grey-lighter mb-4">
              <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-1 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-2 hover:text-white">
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">
                  {image1 ? "上傳正面全身制服照" : "重新上傳"}
                </span>
                <span className="block text-xs text-red-500">
                  請上傳正方形(1:1)照片
                </span>
                <span className="block text-xs text-red-500">
                  照片需為清晰五官
                </span>
                <input
                  {...register("picture", { required: true })}
                  className="hidden block w-full text-sm text-slate-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-sm file:font-semibold
                                file:bg-violet-50 file:text-violet-700
                                hover:file:bg-violet-100"
                  type="file"
                  name="picture"
                  onChange={(e) => uploadImg(e, "image1")}
                />
              </label>
            </div>
            {errors.picture && (
              <div className="flex justify-center relative left-12px top-N18px text-red-500 text-xs italic">
                請上傳正面全身照片
              </div>
            )}
            {image1 &&
              image1.map((media) => (
                <img
                  className="mx-auto max-w-none w-auto h-auto"
                  key={media.id}
                  src={media.url}
                  alt=""
                />
              ))}

            <div className="flex justify-center bg-grey-lighter mb-4">
              <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-1 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-2 hover:text-white">
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">
                  {image2 ? "上傳制服照1" : "重新上傳"}
                </span>
                <span className="block text-xs text-red-500">
                  請上傳正方形(1:1)照片
                </span>
                <span className="block text-xs text-red-500">
                  不限半身/全身
                </span>
                <input
                  {...register("picture1")}
                  className="hidden block w-full text-sm text-slate-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-sm file:font-semibold
                                file:bg-violet-50 file:text-violet-700
                                hover:file:bg-violet-100"
                  type="file"
                  name="picture1"
                  onChange={(e) => uploadImg(e, "image2")}
                />
              </label>
            </div>
            {image2 &&
              image2.map((media) => (
                <img
                  className="mx-auto max-w-none w-auto h-auto"
                  key={media.id}
                  src={media.url}
                  alt=""
                />
              ))}

            <div className="flex justify-center bg-grey-lighter mb-4">
              <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-1 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-2 hover:text-white">
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">
                  {image3 ? "上傳制服照2" : "重新上傳"}
                </span>
                <span className="block text-xs text-red-500">
                  請上傳正方形(1:1)照片
                </span>
                <span className="block text-xs text-red-500">
                  不限半身/全身
                </span>
                <input
                  {...register("picture2")}
                  className="hidden block w-full text-sm text-slate-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-sm file:font-semibold
                                file:bg-violet-50 file:text-violet-700
                                hover:file:bg-violet-100"
                  type="file"
                  name="picture2"
                  onChange={(e) => uploadImg(e, "image3")}
                />
              </label>
            </div>
            {image3 &&
              image3.map((media) => (
                <img
                  className="mx-auto max-w-none w-auto h-auto"
                  key={media.id}
                  src={media.url}
                  alt=""
                />
              ))}

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="agree"
                  name="agree"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500
                            border-gray-300 rounded"
                  defaultChecked
                  {...register("agree", { required: true })}
                />
                <label
                  className="ml-2 block text-sm text-gray-900"
                  htmlFor="agree"
                >
                  我已閱讀
                  <a
                    className="text-orange-1"
                    target="_blank"
                    rel="noreferrer"
                    href="https://game.meteor.today/vote/2022highschooluniform"
                  >
                    活動注意事項
                  </a>
                  及
                  <a
                    href="https://drive.google.com/file/d/1-PliaCapCgCiuR7nX99tiDxx2SqIcG5G/view"
                    className="text-orange-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    個資同意書
                  </a>
                  ，並同意上傳照片後主辦單位擁有無償重製及公開播送之相關權利
                </label>
              </div>
              {errors.agree && (
                <div className="flex justify-center relative left-12px top-N18px text-red-500 text-xs italic">
                  請勾選注意事項
                </div>
              )}
            </div>

            <div className="flex w-full justify-center">
              <button
                type="submit"
                className="group relative w-3/5
                            py-2 px-4 border border-transparent  text-sm font-medium
                            rounded-30px text-white bg-blue-1 hover:bg-indigo-700
                            focus:outline-none focus:ring-2 focus:ring-offset-2
                            focus:ring-indigo-500"
              >
                送出報名
              </button>
            </div>
          </form>
          <div className="w-full text-sm mt-20px text-left">
            報名時遇到問題嗎？
            <a
              href="https://m.me/MeteorApp"
              className="text-orange-1"
              target="_blank"
              rel="noreferrer"
            >
              請聯絡粉絲專頁！
            </a>
          </div>
        </div>
      </div>
      <Modal
        handleClose={() => uploadImageModalHandler(false)}
        isOpen={uploadImageSize}
      >
        <Alert
          title={singUpErrorMessage[0]}
          content={singUpErrorMessage[1]}
          success={setSingUpErrorMessage[2]}
          uploadImageModalHandler={uploadImageModalHandler}
        />
      </Modal>
    </>
  );
};

export default SignUp;
