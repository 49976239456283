import { useState, useEffect } from "react";
import { logShare } from "services/vote/2023superpowers";
import { SUPERPOWERS_GAME_ID } from "config/CONSTANTS";
import useScreenSize from "hooks/useScreenSize";
import { isMobile } from "react-device-detect";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faShare } from "@fortawesome/free-solid-svg-icons";
import faShare from "assets/vote/2023superpowers/DESKTOP/Share.png";
import gtag from "ga-gtag";

import Modal from "components/vote/Modal";
import CloseButton from "components/vote/CloseButton";
import Logo from "components/vote/2023super/Logo";

import igIcon from "assets/vote/2023superpowers/DESKTOP/IG.png";
import arrowLineR from "assets/vote/MOBILE/METEOR_campaign_short_arrowline_M_R.png";
import arrowLineL from "assets/vote/MOBILE/METEOR_campaign_short_arrowline_M_L.png";
import userIcon from "assets/vote/2023superpowers/DESKTOP/Sign up.png";
import hamburgerMIcon from "assets/vote/2023superpowers/MOBILE/Hamburger_M.png";

import styled from "styled-components";
import { useRef } from "react";

const shareMessage = "Meteor超級影音大賽快來投出全好看的影音!";
const styles = {
  shareSectiom:
    "sm:w-1/2 cursor-pointer text-yellow-4 sm:py-15px  text-center hover:text-white border-b-[2px] border-yellow-4 last:border-b-transparent py-[20px]  text-18px font-bold sm:text-base sm:font-normal  w-full",
};
const ShareGroup = styled.ul`
  position: fixed;
  top: 61px;
  right: 0;
  background: #1d1742;
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-weight: 600; */
  font-size: 16px;
  font-family: GenSenRoundedTW-M;
`;

export const Navbar = (props) => {
  const { userSession, myProfileModalHandler, navbarColor } = props;
  const windowDimenion = useScreenSize();

  const shareData = {
    userId: userSession,
    gameId: SUPERPOWERS_GAME_ID,
    shareOn: "",
  };
  const navRef = useRef(null);
  const [showShare, setShowShare] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    const location = window.location.href;
    setCurrentUrl(location);
  }, []);

  const [toggleMenuModal, setToggleMenuModal] = useState(false);
  const [navbarBg, setNavbarBg] = useState(false);
  const [desktopMenu, setDesktopMenu] = useState(false);

  const changeBackground = () => {
    window.scrollY >= 66 ? setNavbarBg(true) : setNavbarBg(false);
  };
  useEffect(() => {
    if (isMobile) {
      setDesktopMenu(false);
    } else {
      setDesktopMenu(true);
    }
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  }, []);

  const toggleMenuModalHandler = () => {
    setToggleMenuModal((preState) => !preState);
  };

  const igShare = () => {
    window.open(`https://www.instagram.com/meteor.tw/`);
  };
  const lineShare = () => {
    shareData.shareOn = "line";
    logShare(shareData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });

    // ga4 api
    gtag("event", "點擊分享Line", {
      gameId: SUPERPOWERS_GAME_ID,
      userSession: userSession,
      send_to: "G-SR7XCS1GYX",
    });

    // bat api
    window.bat.track("share", {
      method: "line",
      gameId: SUPERPOWERS_GAME_ID,
      userSession: userSession,
    });

    window.open(
      `https://lineit.line.me/share/ui?url=${currentUrl}&text=%23${shareMessage}`
    );
  };
  const fbShare = (width, height) => {
    shareData.shareOn = "facebook";

    // api
    logShare(shareData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });

    // ga4 api
    gtag("event", "點擊分享Facebook", {
      gameId: SUPERPOWERS_GAME_ID,
      userSession: userSession,
      send_to: "G-SR7XCS1GYX",
    });

    // bat api
    window.bat.track("share", {
      method: "facebook",
      gameId: SUPERPOWERS_GAME_ID,
      userSession: userSession,
    });

    const leftPosition = window.screen.width / 2 - (width / 2 + 10);
    const topPosition = window.screen.height / 2 - (height / 2 + 50);
    const windowFeatures =
      "status=no,height=" +
      height +
      ",width=" +
      width +
      ",resizable=yes,left=" +
      leftPosition +
      ",top=" +
      topPosition +
      ",screenX=" +
      leftPosition +
      ",screenY=" +
      topPosition +
      ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
    window.open(
      "http://www.facebook.com/sharer.php?u=" +
        encodeURIComponent(currentUrl) +
        "&hashtag=%23" +
        encodeURIComponent(shareMessage),
      "sharer",
      windowFeatures
    );
    return false;
  };
  const urlCopy = () => {
    navigator.clipboard.writeText(currentUrl);
    alert("複製成功");
  };
  useEffect(() => {
    if (navbarColor) {
      navRef.current.style.background = "#0C092D";
    } else {
      navRef.current.style.background = "transparent";
    }
  }, [navbarColor]);
  return (
    <nav
      id="header"
      className={`bg-black-5 md:mt-0" fixed w-full z-30 top-0 sm:px-[37px] sm:py-[13px] py-[5px] px-[6px]`}
      ref={navRef}
    >
      <div className="w-full mx-auto flex flex-wrap items-center justify-between">
        <Logo />
        <div className="flex sm:hidden ">
          {userSession && (
            <div
              onClick={myProfileModalHandler}
              className="cursor-pointer text-white  rounded-md "
            >
              <img src={userIcon} alt="userIcon" className="h-[50px] "></img>
            </div>
          )}
          <button id="nav-toggle" onClick={toggleMenuModalHandler} className="">
            <img
              src={hamburgerMIcon}
              alt="hamburgerMIcon"
              className="h-[50px] "
            ></img>
          </button>
        </div>

        <div
          className="w-full flex-grow sm:flex sm:items-center sm:w-auto  sm:mt-0 bg-white sm:bg-transparent text-black  lg:p-0  z-20 text-14px hidden "
          id="nav-content"
        >
          <ul className="list-reset text-base sm:flex justify-end flex-1 items-center">
            <li onClick={igShare} className=" cursor-pointer ">
              <img src={igIcon} alt="IGlogo" className="h-[50px]" />
            </li>
            <li
              className="cursor-pointer"
              onClick={() => {
                setShowShare(!showShare);
                // setToggleMenuModal(true);
              }}
            >
              {/* <i class="fa-regular fa-share"></i> */}
              <img
                src={faShare}
                alt="分享按鈕"
                className={`${
                  showShare ? "text-yellow-3" : "text-white"
                } h-[50px] ml-[17px]`}
              />
            </li>

            {userSession && (
              <li
                onClick={myProfileModalHandler}
                className="ml-[17px]  cursor-pointer text-white rounded-md "
              >
                <img src={userIcon} alt="userIcon" className="h-[50px]"></img>
              </li>
            )}
          </ul>
        </div>

        {showShare && (
          <ShareGroup navbarBg={navbarBg}>
            <li onClick={lineShare} className={styles.shareSectiom}>
              LINE
            </li>

            <li
              onClick={() => fbShare(400, 300)}
              className={styles.shareSectiom}
            >
              FACEBOOK
            </li>

            <li onClick={urlCopy} className={styles.shareSectiom}>
              複製連結
            </li>
          </ShareGroup>
        )}
        {/* <Modal handleClose={() => setShowShare(false)} isOpen={showShare}>
          <CloseButton
            size="30"
            fill={"#fff"}
            handleClose={() => setShowShare(false)}
          />
          <div className="w-screen h-screen bg-blue-1 text-white z-20 text-base font-bold">
            <div className="w-screen h-screen flex flex-col pb-50px px-85px mt-50px items-center justify-center text-center bg-blue-2">
              <ul>
                <li onClick={lineShare} className="mr-33px cursor-pointer">
                  LINE
                </li>
                <img src={arrowLineR} alt="" />
                <li
                  onClick={() => fbShare(400, 300)}
                  className="mr-33px cursor-pointer"
                >
                  FACEBOOK
                </li>
                <img src={arrowLineL} alt="" />
                <li onClick={urlCopy} className="mr-33px cursor-pointer">
                  複 製 連 結
                </li>
              </ul>
            </div>
          </div>
        </Modal> */}
        {/* <Modal
          handleClose={() => setToggleMenuModal(false)}
          isOpen={toggleMenuModal}
        > */}
        {/* <CloseButton
            size="30"
            fill={"#fff"}
            handleClose={() => setToggleMenuModal(false)}
          /> */}
        {/* <div className="w-screen h-screen bg-blue-1 text-white z-20 text-base font-bold"> */}
        {/* <div className="absolute inset-0">
          <div className="bg-black-5 md:mt-0 fixed w-full z-30 top-0 sm:px-[37px] sm:py-[13px] py-[5px] px-[6px] flex justify-between">
            <Logo />
            <div className="flex sm:hidden ">
              <button
                id="nav-toggle"
                onClick={toggleMenuModalHandler}
                className=""
              >
                <img
                  src={hamburgerMIcon}
                  alt="hamburgerMIcon"
                  className="h-[50px] "
                ></img>
              </button>
            </div>
          </div>
        </div> */}
        {toggleMenuModal ? (
          <div className="w-screen h-screen absolute top-[60px] bottom-0 left-0 right-0 flex flex-col pb-50px px-85px  items-center justify-center text-center bg-black-4 text-yellow-4  text-base font-bold z-40">
            <ul className="flex flex-col  items-center w-full">
              <li onClick={igShare} className={styles.shareSectiom}>
                INSTAGRAM
              </li>
              <li onClick={fbShare} className={styles.shareSectiom}>
                FACEBOOK
              </li>
              <li onClick={lineShare} className={styles.shareSectiom}>
                LINE
              </li>
            </ul>
            {/* </div> */}
          </div>
        ) : null}
        {/* </Modal> */}
      </div>
    </nav>
  );
};
