import closeIcon from "assets/vote/2023superpowers/DESKTOP/close.png";

const CloseButton = ({ handleClose, fill, size }) => {
  return (
    <>
      <button
        onClick={handleClose}
        className="z-30 w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] absolute right-[16px] top-[16px] sm:right-[13px] sm:top-[30px]"
      >
        <img src={closeIcon} alt="closeIcon" className="sm:w-[40px] w-[30px]" />
      </button>
    </>
  );
};
export default CloseButton;
