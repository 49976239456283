import { useContext } from "react";
import { GameInfoContext } from "store/contex";
import { BackgroundPicture } from "components/vote/BackgroundPicture";

export const Hero = () => {
  const { gameInfo } = useContext(GameInfoContext);
  return (
    <>
      <picture>
        {window.innerWidth >= 751 ? (
          <BackgroundPicture
            src={gameInfo && gameInfo.setPictures.background_1_D}
            alt="Meteor2022 校園男神選拔"
          />
        ) : (
          <BackgroundPicture
            src={gameInfo && gameInfo.setPictures.background_1_M}
            alt="Meteor2022 校園男神選拔"
          />
        )}
      </picture>
    </>
  );
};
