import styled from "styled-components/macro";
import { MEDIA_QUERY_MD } from "assets/styles/responsive";
import directionMobileBackground from "assets/playground/roulette/Mobile/Meteor_Roulette_3_M@3x.png";
const LotteryDirectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 47px 0 25px 0;
  ${MEDIA_QUERY_MD} {
    padding: 23px 0 25px 0;
  }
`;
const LotteryDirectionTitle = styled.h2`
  color: #666666;
  font-size: 24px;
  font-weight: 900;
  width: 628px;
  padding-bottom: 32px;
  margin-bottom: 32px;
  text-align: center;
  border-bottom: 1px solid #666666;
  ${MEDIA_QUERY_MD} {
    font-size: 18px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    width: 280px;
  }
`;
const LotteryDirectionContentUl = styled.ul`
  color: #666666;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  ${MEDIA_QUERY_MD} {
    font-size: 16px;
  }
`;
const LotteryDirectionContent = ({ index, description }) => {
  return (
    <LotteryDirectionContentWrapper>
      <LotteryDirectionContentDecimal>{index}</LotteryDirectionContentDecimal>
      <LotteryDirectionContentDescription>
        {description}
      </LotteryDirectionContentDescription>
    </LotteryDirectionContentWrapper>
  );
};
const LotteryDirectionContentWrapper = styled.li`
  display: flex;
  max-width: 570px;
  & + & {
    padding-top: 16px;
  }
  ${MEDIA_QUERY_MD} {
    max-width: 280px;
  }
`;
const LotteryDirectionContentDecimal = styled.p`
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 50%;
  background-color: #f4da41;
  text-align: center;
  ${MEDIA_QUERY_MD} {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
`;
const LotteryDirectionContentDescription = styled.p`
  padding-left: 18px;
  flex: 1;
  ${MEDIA_QUERY_MD} {
    padding-left: 14px;
  }
`;
const DirectionMobileBackground = styled.img`
  display: none;
  ${MEDIA_QUERY_MD} {
    display: block;
    margin-top: 24px;
    width: 100%;
  }
`;
const LotteryDirection = ({ descriptions }) => {
  return (
    <>
      {descriptions && (
        <LotteryDirectionWrapper>
          <LotteryDirectionTitle>遊戲說明</LotteryDirectionTitle>
          <LotteryDirectionContentUl>
            {descriptions.map((description, i) => (
              <LotteryDirectionContent
                description={description}
                index={i + 1}
                key={i}
              />
            ))}
          </LotteryDirectionContentUl>
          <DirectionMobileBackground src={`${directionMobileBackground}`} />
        </LotteryDirectionWrapper>
      )}
    </>
  );
};

export default LotteryDirection;
