import styled from "styled-components/macro";
import { MEDIA_QUERY_MD } from "assets/styles/responsive";
import { centerShow } from "./animation";
import forkIcon from "assets/playground/roulette/Desktop/Meteor_component_10_D.png";
import popUpWrapperPicture from "assets/playground/roulette/Desktop/Meteor_component_9_D.png";
import popUpLostPicture from "assets/playground/roulette/Desktop/Meteor_component_11_D.png";
import popUpDeficiencyPicture from "assets/playground/roulette/Desktop/Meteor_component_12_D.png";

const PopUpsWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 442px;
  height: 310px;
  top: 50%;
  left: 50%;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);
  border-radius: 18px;
  padding: 44px 0 0 0;
  animation: ${centerShow} 0.3s ease-in-out;
  letter-spacing: 0.1em;
  ${MEDIA_QUERY_MD} {
    width: 300px;
    height: 220px;
    padding: 34px 0 0 0;
  }
`;
const PopUpsStreamer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 408px;
  height: 253px;
  background-image: url(${popUpWrapperPicture});
  background-size: cover;
  z-index: -1;
  ${MEDIA_QUERY_MD} {
    width: 276.92px;
    height: 172.27px;
  }
`;
const PopUpsTitle = styled.h2`
  padding-bottom: 32px;
  font-size: 36px;
  font-weight: 900;
  color: #ec6e67;
  line-height: 1;
  ${MEDIA_QUERY_MD} {
    font-size: 24px;
    padding-bottom: 20px;
  }
`;

const PopUpsBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PopUpsButtonBodyTitle = styled.p`
  color: #666666;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  ${MEDIA_QUERY_MD} {
    font-size: 18px;
  }
`;
const PopUpsImg = styled.div`
  width: 60px;
  height: 60px;

  background: ${(props) => (props.img ? `url(${props.img})` : "gray")};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 16px 0;
  ${MEDIA_QUERY_MD} {
    width: 40px;
    height: 40px;
    margin: 12px 0;
  }
`;
const LotteryItem = styled(PopUpsButtonBodyTitle)``;
const PopUpsButton = styled.button`
  background-image: url(${forkIcon});
  background-size: cover;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  ${MEDIA_QUERY_MD} {
    width: 27.15px;
    height: 27.15px;
    top: 5.43px;
    right: 5.43px;
  }
`;

const PopUpsLostTitle = styled(PopUpsTitle)`
  /* font-size: 36px; */
  font-weight: 900;
  color: #666666;
  line-height: 1;
  /* padding-bottom: 32px; */
  ${MEDIA_QUERY_MD} {
  }
`;
const PopUpsLostImg = styled.div`
  background-image: url(${popUpLostPicture});
  background-size: cover;
  width: 161px;
  height: 151px;
  ${MEDIA_QUERY_MD} {
    width: 111px;
    height: 104px;
  }
`;
const PopUpsDeficiencyTitle = styled(PopUpsTitle)`
  padding-bottom: 20px;
  ${MEDIA_QUERY_MD} {
    padding-bottom: 12px;
  }
`;
const PopUpsDeficiencyImg = styled.div`
  background-image: url(${popUpDeficiencyPicture});
  background-size: cover;
  width: 111px;
  height: 172px;
  ${MEDIA_QUERY_MD} {
    width: 77px;
    height: 120px;
  }
`;
const LotteryIntro = styled.div`
  font-weight: 700;
  color: #666;
  margin-top: 15px;
  ${MEDIA_QUERY_MD} {
    font-size: 12px;
  }
`;

const LotteryPopUps = ({ handlePopupsRemove, item, errorMessage }) => {
  return (
    <PopUpsWrapper>
      <PopUpsButton onClick={handlePopupsRemove}></PopUpsButton>
      {!errorMessage &&
        item &&
        (item.name !== "槓龜" ? (
          <>
            <PopUpsStreamer></PopUpsStreamer>
            <PopUpsTitle>恭喜中獎</PopUpsTitle>
            <PopUpsBody>
              <PopUpsButtonBodyTitle>恭喜抽到</PopUpsButtonBodyTitle>
              <PopUpsImg img={item?.imgurUrl} />
              <LotteryItem>{item?.name}</LotteryItem>
              { item.prize.mailContent && <LotteryIntro>得獎資訊已於站內信寄送，請立即前往回覆</LotteryIntro> }
            </PopUpsBody>
          </>
        ) : (
          <>
            {/* 槓龜 */}
            <PopUpsLostTitle>再接再厲</PopUpsLostTitle>
            <PopUpsLostImg></PopUpsLostImg>
          </>
        ))}

      {errorMessage && (
        <>
          {/* 不足 */}
          <PopUpsDeficiencyTitle>M幣不足</PopUpsDeficiencyTitle>
          <PopUpsDeficiencyImg></PopUpsDeficiencyImg>
        </>
      )}
    </PopUpsWrapper>
  );
};

export default LotteryPopUps;
