import { useContext } from "react";
import { GameInfoContext } from "store/contex";

const MobileWinPrize = ({ winPrizeIndex, gameInfo }) => {
  return (
    <>
      <div className="absolute bottom-8px left-N6px">
        <img
          src={gameInfo && gameInfo.setPictures.crown_D}
          alt={winPrizeIndex + 1}
        />
      </div>
      <div className="absolute bottom-8px left-4px text-black-1 font-bold text-14px">
        第{winPrizeIndex + 1}名
      </div>
    </>
  );
};

const BrowserWinPrize = ({
  winPrizeIndex,
  gameInfo,
  candidatesModalHandler,
  setModalData,
  candidate,
  clickModalHandler,
}) => {
  return (
    <>
      <div
        onClick={() => {
          candidatesModalHandler();
          setModalData(candidate);
          clickModalHandler(candidate.playerId);
        }}
        className="absolute bottom-47px left-N10px cursor-pointer"
      >
        <img
          src={gameInfo && gameInfo.setPictures.crown_D}
          alt={winPrizeIndex + 1}
        />
      </div>
      <div className="absolute bottom-50px left-10px text-black-1 font-bold text-18px">
        第{winPrizeIndex + 1}名
      </div>
    </>
  );
};

const WinPrize = ({
  winnerPlayers,
  userId,
  candidatesModalHandler,
  setModalData,
  candidate,
  clickModalHandler,
}) => {
  const { gameInfo } = useContext(GameInfoContext);
  const winPrizeIndex = winnerPlayers
    .map(({ playerId }) => playerId)
    .indexOf(userId);
  return (
    <>
      {winPrizeIndex >= 0 && (
        <>
          {window.innerWidth > 600 ? (
            <BrowserWinPrize
              winPrizeIndex={winPrizeIndex}
              gameInfo={gameInfo}
              candidatesModalHandler={candidatesModalHandler}
              setModalData={setModalData}
              candidate={candidate}
              clickModalHandler={clickModalHandler}
            />
          ) : (
            <MobileWinPrize winPrizeIndex={winPrizeIndex} gameInfo={gameInfo} />
          )}
        </>
      )}
    </>
  );
};

export default WinPrize;
