const TTSvg = () => {
  return (

<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Icon_TT">
<path id="Vector" d="M26.3862 10.8871C26.3862 10.0826 26.2327 9.28435 25.9503 8.52906C25.7416 7.9764 25.4162 7.47287 24.9988 7.06145C24.5813 6.64388 24.078 6.31843 23.5316 6.10965C22.7765 5.82718 21.9785 5.67366 21.1743 5.67366C19.8299 5.59998 19.4309 5.59998 16.03 5.59998C12.6291 5.59998 12.23 5.59998 10.8856 5.67366C10.0814 5.67366 9.28339 5.82718 8.52832 6.10965C7.97582 6.31843 7.47244 6.64388 7.06114 7.06145C6.6437 7.47901 6.31834 7.98254 6.10962 8.52906C5.82723 9.28435 5.67376 10.0826 5.67376 10.8871C5.6001 12.2319 5.6001 12.631 5.6001 16.0329C5.6001 19.4348 5.6001 19.834 5.67376 21.1788C5.68604 21.9893 5.83337 22.7876 6.10962 23.549C6.31834 24.0956 6.6437 24.5991 7.06114 25.0105C7.47858 25.4281 7.97582 25.7535 8.53445 25.9623C9.28953 26.2448 10.0876 26.3983 10.8918 26.3983C12.2362 26.472 12.6352 26.472 16.0361 26.472C19.437 26.472 19.836 26.472 21.1804 26.3983C21.9846 26.3983 22.7827 26.2448 23.5377 25.9623C24.0902 25.7535 24.5936 25.4342 25.0111 25.0105C25.4285 24.5991 25.7539 24.0956 25.9626 23.549C26.245 22.7937 26.3923 21.9893 26.3984 21.1788C26.4721 19.834 26.4721 19.4348 26.4721 16.0329C26.4721 12.631 26.4721 12.2319 26.3984 10.8871H26.3862ZM21.5058 14.8109C21.4014 14.8232 21.2971 14.8293 21.1927 14.8293C20.0386 14.8293 18.9643 14.246 18.332 13.2819V18.5506C18.332 20.6998 16.5886 22.4437 14.44 22.4437C12.2914 22.4437 10.548 20.6998 10.548 18.5506C10.548 16.4013 12.2914 14.6574 14.44 14.6574C14.5198 14.6574 14.5996 14.6635 14.6794 14.6697V16.5917C14.5996 16.5794 14.5198 16.5671 14.44 16.5671C13.3412 16.5671 12.451 17.4575 12.451 18.5567C12.451 19.6559 13.3412 20.5463 14.44 20.5463C15.5389 20.5463 16.5088 19.6804 16.5088 18.5813L16.5272 9.63437H18.3627C18.5346 11.2801 19.8606 12.5696 21.5119 12.6863V14.8171" fill="white"/>
</g>
</svg>

  )}
export default TTSvg
