import { Helmet } from "react-helmet-async";

const Meta = ({ title, description, meta = [] }) => {
  return (
    <Helmet>
      <title>2023 Meteor奇人異士大賞</title>
      <meta
        name="description"
        content="Meteor,米特論壇,學生社群,米特學生社群,高中生,高職生,高校生,大學生,國中生,學校,校園,JK,制服,全部看板,熱門"
      />
      <meta
        name="Meteor"
        content="Meteor,米特論壇,學生社群,米特學生社群,高中生,高職生,高校生,大學生,國中生,學校,校園,JK,制服,全部看板,熱門"
      />
      <meta
        name="twitter:card"
        content="https://storage.meteor.today/image/6406c2f012ac43515c678f4f.jpg"
      />
      <meta name="twitter:site" content="https://game.meteor.today/" />
      <meta name="twitter:creator" content="meteor" />

      <meta name="twitter:title" content="2023 Meteor奇人異士大賞" />
      <meta name="twitter:label1" content="" />
      <meta name="twitter:data1" content="" />
      <meta
        name="og:description"
        content="Meteor,米特論壇,學生社群,米特學生社群,高中生,高職生,高校生,大學生,國中生,學校,校園,JK,制服,全部看板,熱門"
      />
      <meta property="og:title" content="2023 Meteor奇人異士大賞" />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="hhttps://game.meteor.today/vote/2023superpowers"
      />
      <meta
        property="og:image"
        content="https://storage.meteor.today/image/6406c2f012ac43515c678f4f.jpg"
      />
      <meta
        property="og:site_name"
        content="Meteor米特 [https://game.meteor.today/vote/2023superpowers]"
      />
      <meta property="fb:app_id" content="1717255128492433" />
    </Helmet>
  );
};

export default Meta;
