import { METEORURL } from "config/CONSTANTS";
import { useEffect } from "react";
import { getLogin } from "services/vote/2023superpowers/getLogin";

const useVideoTokenMessage = (setId, refIFrame, setVideoLoading) => {
  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.origin !== METEORURL) {
        return;
      }
      const { data } = e;
      switch (data.method) {
        case "videoToken":
          if (data.status !== 200) {
            if (data.data === "請先登入") {
              window.location.href = `https://meteor.today/login?next=${window.location.href}`;
            }
            alert(data.data);
            getLogin(refIFrame);
            setVideoLoading(false);
            return;
          } else {
            const { cloudFileId, uploadToken } = data.data;
            setId({ cloudFileId, uploadToken });
            getLogin(refIFrame);
          }
          break;
        default:
          break;
      }
    });
  }, []);
};
export default useVideoTokenMessage;
