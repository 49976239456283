import { UNIFORM_GAME_ID } from "config/CONSTANTS";
import pingIcon_D from "assets/vote/2022uniform/DESKTOP/flow_D.png";
import pingIcon_M from "assets/vote/2022uniform/MOBILE/flow_M.png";
import styled from "styled-components";
import gtag from "ga-gtag";

const SignUpStyle = styled.div`
  position: fixed;
  right: 0;
  top: 60%;
  cursor: pointer;
  z-index: 999;
  width: 175px;
  @media (max-width: 640px) {
    width: 52px;
  }
`;
const SignUpButton = (props) => {
  const { singUpModalHandler, userSession } = props;

  const singUpClickHandler = () => {
    if (userSession) {
      // ga4 api
      gtag("event", "點擊報名-浮動icon", {
        gameId: UNIFORM_GAME_ID,
        userSession: userSession,
        send_to: "G-SR7XCS1GYX",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-浮動icon",
        gameId: UNIFORM_GAME_ID,
        userSession: userSession,
      });

      const data = {
        userId: userSession,
        gameId: UNIFORM_GAME_ID,
      };
      fetch("https://meteor.today/gameelection/get_my_player_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            alert("您已經報名囉");
            return;
          } else {
            singUpModalHandler(true);
          }
        });
    } else if (!userSession) {
      // ga4 api
      gtag("event", "點擊報名-浮動icon", {
        gameId: UNIFORM_GAME_ID,
        userSession: "未登入",
        send_to: "G-SR7XCS1GYX",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-浮動icon",
        gameId: UNIFORM_GAME_ID,
        userSession: "未登入",
      });

      alert("請先登入");
      window.location.href =
        "https://meteor.today/login?next=https://game.meteor.today/vote/2022highschooluniform";
      return;
    } else {
      singUpModalHandler(true);
    }
  };

  return (
    <SignUpStyle>
      <img
        onClick={singUpClickHandler}
        src={window.innerWidth >= 640 ? pingIcon_D : pingIcon_M}
        alt="2022Meteor"
      />
    </SignUpStyle>
  );
};

export default SignUpButton;
