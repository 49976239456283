import { useState, useEffect } from "react";
import { logShare } from "services/vote";
import { GAME_ID } from "config/CONSTANTS";
import useScreenSize from "hooks/useScreenSize";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import gtag from "ga-gtag";

import Modal from "components/vote/Modal";
import CloseButton from "components/vote/CloseButton";
import Logo from "components/vote/Logo";

import igIcon from "assets/vote/SOCIAL_ICON_IG.png";
import arrowLineR from "assets/vote/MOBILE/METEOR_campaign_short_arrowline_M_R.png";
import arrowLineL from "assets/vote/MOBILE/METEOR_campaign_short_arrowline_M_L.png";

import styled from "styled-components";

const shareMessage = "Meteor高校男神選拔賽快來為支持的男神投票吧！";

const ShareGroup = styled.ul`
  position: fixed;
  top: 70px;
  right: 0;
  background: ${(props) => (props.navbarBg ? "#1236B8" : "#fff")};
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
`;

export const Navbar = (props) => {
  const { userSession, myProfileModalHandler } = props;
  const windowDimenion = useScreenSize();

  const shareData = {
    userId: userSession,
    gameId: GAME_ID,
    shareOn: "",
  };

  const [showShare, setShowShare] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    const location = window.location.href;
    setCurrentUrl(location);
  }, []);

  const [toggleMenuModal, setToggleMenuModal] = useState(false);
  const [navbarBg, setNavbarBg] = useState(false);
  const [desktopMenu, setDesktopMenu] = useState(false);

  const changeBackground = () => {
    window.scrollY >= 66 ? setNavbarBg(true) : setNavbarBg(false);
  };
  useEffect(() => {
    if (isMobile) {
      setDesktopMenu(false);
    } else {
      setDesktopMenu(true);
    }
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  }, []);

  const toggleMenuModalHandler = () => {
    setToggleMenuModal((preState) => !preState);
  };

  const igShare = () => {
    window.open(`https://www.instagram.com/meteor.tw/`);
  };
  const lineShare = () => {
    shareData.shareOn = "line";
    logShare(shareData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });

    // ga4 api
    gtag("event", "點擊分享Line", {
      gameId: GAME_ID,
      userSession: userSession,
    });

    // bat api
    window.bat.track("share", {
      method: "line",
      gameId: GAME_ID,
      userSession: userSession,
    });

    window.open(
      `https://lineit.line.me/share/ui?url=${currentUrl}&text=%23${shareMessage}`
    );
  };
  const fbShare = (width, height) => {
    shareData.shareOn = "facebook";

    // api
    logShare(shareData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });

    // ga4 api
    gtag("event", "點擊分享Facebook", {
      gameId: GAME_ID,
      userSession: userSession,
    });

    // bat api
    window.bat.track("share", {
      method: "facebook",
      gameId: GAME_ID,
      userSession: userSession,
    });

    const leftPosition = window.screen.width / 2 - (width / 2 + 10);
    const topPosition = window.screen.height / 2 - (height / 2 + 50);
    const windowFeatures =
      "status=no,height=" +
      height +
      ",width=" +
      width +
      ",resizable=yes,left=" +
      leftPosition +
      ",top=" +
      topPosition +
      ",screenX=" +
      leftPosition +
      ",screenY=" +
      topPosition +
      ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
    window.open(
      "http://www.facebook.com/sharer.php?u=" +
        encodeURIComponent(currentUrl) +
        "&hashtag=%23" +
        encodeURIComponent(shareMessage),
      "sharer",
      windowFeatures
    );
    return false;
  };
  const urlCopy = () => {
    navigator.clipboard.writeText(currentUrl);
    alert("複製成功");
  };
  return (
    <nav
      id="header"
      className={`${
        navbarBg ? "bg-blue-1 md:mt-0" : "bg-transparent"
      } fixed w-full z-30 top-0 px-10px py-10px`}
    >
      <div className="w-full mx-auto flex flex-wrap items-center justify-between">
        <Logo />
        <div className="flex lg:hidden pr-2">
          {userSession && (
            <div
              onClick={myProfileModalHandler}
              className="mr-33px cursor-pointer text-white p-1 rounded-md mt-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10"
                fill="none"
                viewBox="0 0 27 27"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
          )}
          <button
            id="nav-toggle"
            onClick={toggleMenuModalHandler}
            className="flex items-center p-1 text-black-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            <svg
              className="fill-white h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        {desktopMenu && windowDimenion.winWidth > 821 && (
          <div
            className="w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 lg:pr-10px z-20 text-14px "
            id="nav-content"
          >
            <ul className="list-reset text-base lg:flex justify-end flex-1 items-center">
              <li onClick={igShare} className=" cursor-pointer mr-12px">
                <img src={igIcon} alt="" width="50px" />
              </li>
              <li
                className="cursor-pointer"
                onClick={() => {
                  setShowShare(!showShare);
                }}
              >
                {/* <i class="fa-regular fa-share"></i> */}
                <FontAwesomeIcon
                  icon={faShare}
                  className={`${showShare ? "text-yellow-3" : "text-white"}`}
                  style={{
                    width: "28px",
                    height: "28px",
                    padding: "0 15px 0 10px",
                  }}
                />
              </li>

              {userSession && (
                <li
                  onClick={myProfileModalHandler}
                  className="mr-33px cursor-pointer text-white p-1 rounded-md mt-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 27 27"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </li>
              )}
            </ul>
          </div>
        )}
        {showShare && (
          <ShareGroup navbarBg={navbarBg}>
            <li
              onClick={lineShare}
              className={`mr-33px cursor-pointer ${
                navbarBg ? "text-white" : "text-blue-1"
              } py-15px w-full text-center hover:text-yellow-3`}
            >
              LINE
            </li>
            <li
              onClick={() => fbShare(400, 300)}
              className={`mr-33px cursor-pointer ${
                navbarBg ? "text-white" : "text-blue-1"
              } py-15px w-full text-center hover:text-yellow-3`}
            >
              FACEBOOK
            </li>
            <li
              onClick={urlCopy}
              className={`mr-33px cursor-pointer ${
                navbarBg ? "text-white" : "text-blue-1"
              } py-15px w-full text-center hover:text-yellow-3`}
            >
              複製連結
            </li>
          </ShareGroup>
        )}
        <Modal
          handleClose={() => setToggleMenuModal(false)}
          isOpen={toggleMenuModal}
        >
          <CloseButton
            size="30"
            fill={"#fff"}
            handleClose={() => setToggleMenuModal(false)}
          />
          <div className="w-screen h-screen bg-blue-1 text-white z-20 text-base font-bold">
            <div className="w-screen h-screen flex flex-col pb-50px px-85px mt-50px items-center justify-center text-center bg-blue-2">
              <ul>
                <li onClick={igShare} className="mr-33px">
                  INSTAGRAM
                </li>
                <img src={arrowLineR} alt="" />
                <li onClick={fbShare} className="mr-33px">
                  FACEBOOK
                </li>
                <img src={arrowLineL} alt="" />
                <li onClick={lineShare} className="mr-33px">
                  LINE
                </li>
              </ul>
            </div>
          </div>
        </Modal>
      </div>
    </nav>
  );
};
