import { useContext } from "react";
// import { useContext, useEffect, useRef } from "react";
import { Girl2023InfoContext } from "store/contex";
import styled from "styled-components";

const ActiveContainerStyle = styled.div`
  position: relative;
  display: block;
  z-index: 10;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 91vw;
  margin-top:-80px;
  @media (max-width: 640px) {
    margin-top:-100px;
    height: 307.2vw;
  }
`;

export const Activities = () => {
  const { gameInfo } = useContext(Girl2023InfoContext);
  return (
    <ActiveContainerStyle
      image={
        gameInfo &&
        (window.innerWidth >= 640
          ? gameInfo.setPictures.gameIntro_D
          : gameInfo.setPictures.gameIntro_M)
      }
      id="activities"
    >
    </ActiveContainerStyle>
  );
};
