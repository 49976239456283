import { useState, useMemo, useEffect } from "react";
import { GameInfoContext } from "../store/contex";
import { getGameInfo } from "../services/vote";

const GameInfoProvider = (props) => {
  const [gameInfo, setGameInfo] = useState(null);

  useEffect(() => {
    getGameInfo()
      .then((res) => {
        setGameInfo(res.game);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
    return () => {
      console.log("axios cleanup.");
    };
  }, []);

  const value = useMemo(() => ({ gameInfo, setGameInfo }), [gameInfo]);
  return (
    <GameInfoContext.Provider value={value}>
      {props.children}
    </GameInfoContext.Provider>
  );
};

export default GameInfoProvider;
