import styled from "styled-components";

const NavBarMenuHeader = styled.div`
  font-size: 24px;
  padding: 10px;
  width: 125px;
  border-bottom: 2px solid #FFF;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 16px;
    font-weight: 900;
    padding: 12px;
    width: 116px;
    border-bottom: 1.5px solid #FFF;
  }
`;

export default NavBarMenuHeader;
