import { useContext, useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
// import "assets/styles/video.css";
import { isDesktop, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UniformGameInfoContext } from "store/contex";
import styled from "styled-components";
import {
  faCompress,
  faExpand,
  faPause,
  faPlay,
  faVolumeControlPhone,
  faVolumeHigh,
  faVolumeMute,
  faVolumeOff,
} from "@fortawesome/free-solid-svg-icons";
import playImage from "assets/vote/2023superpowers/DESKTOP/play.png";
import pauseImage from "assets/vote/2023superpowers/DESKTOP/pause.png";
import volumeHighImage from "assets/vote/2023superpowers/DESKTOP/volumeHigh.png";
import expandImage from "assets/vote/2023superpowers/DESKTOP/expand.png";
import volumeMuteImage from "assets/vote/2023superpowers/DESKTOP/volumeMute.png";
// import "videojs-contrib-hls";

const VedioStyle = styled.div`
  // background-repeat: repeat;
  // background-position: center top;
  // max-width: 2880px;
  // min-height: 1026px;
  // background-image: url(${(props) => props.backgroundImage});
  // background-size: 50%;
  // padding-top: 20px;
  .video-list {
    display: flex;
    flex-wrap: wrap;
  }
  .video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }

  .video-container .video-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-title {
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
  .video-item {
    width: calc(33.333% - 16px);
    margin-right: 16px;
    margin-bottom: 16px;
  }
  .video-item:nth-child(3n) {
    margin-right: 0;
  }

  .video-title:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    .video-item {
      flex-wrap: nowrap;
    }

    .video-item {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const VIDEO_LIST = [
  {
    id: 1,
    title: "影音說明文字1",
    url: "https://storage.meteor.today/video/61f6df8af82da432e77f1d08.mp4",
  },
  {
    id: 2,
    title: "影音說明文字2",
    url: "https://storage.cloud.google.com/award-dancing-cats/61961d939714654614a50fdd.MOV",
  },
  {
    id: 3,
    title: "影音說明文字3",
    url: "https://storage.googleapis.com/bat-meteor-video/6141bfb68bc609d646688c36.MP4",
  },
  {
    id: 4,
    title: "影音說明文字4",
    url: "https://storage.googleapis.com/bat-meteor-video/617108d253ea6fb21809c414.MP4",
  },
  {
    id: 5,
    title: "影音說明文字5",
    url: "https://storage.googleapis.com/bat-meteor-video/6188f45aa101e1dca0d1080f.MOV",
  },
  {
    id: 6,
    title: "影音說明文字6",
    url: "https://storage.googleapis.com/bat-meteor-video/6194ff58e88ecb6326ef1619.MOV",
  },
];

const Video = ({ video }) => {
  const videoRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(0);
  const [duration, setDuration] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  useEffect(() => {
    const nowInner = window.innerWidth;
    if (isMobile || nowInner <= 640) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const video = entry.target;
              if (video) {
                const playPromise = video.play();
                if (playPromise !== undefined) {
                  playPromise
                    .then((_) => {
                      console.log("有出來嗎?");
                      video.play();
                      setIsPlaying(true);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }
            } else {
              const video = entry.target;
              if (video) {
                video.pause();
                setIsPlaying(false);
              }
            }
          });
        },
        { threshold: 0.3 }
      );

      observer.observe(videoRef.current);

      return () => observer.disconnect();
    }
  }, [videoRef.current]);
  // const [player, setPlayer] = useState(null);
  // useEffect(() => {
  //   if (videoRefs.current) {
  //     const nowInnerWidth = window.innerWidth;
  //     const options = {
  //       // fluid: true,
  //       autoplay: isMobile || nowInnerWidth <= 640 ? "muted" : false,
  //       controls: true,
  //       loop: true,
  //       sources: [
  //         {
  //           src: `${video.url}`,
  //         },
  //       ],
  //       userActions: {
  //         hotkeys: true,
  //       },
  //       controlBar: {
  //         pictureInPictureToggle: false,
  //       },
  //       ios: {
  //         playsinline: true, // 开启 playsinline
  //         preload: "auto",
  //       },
  //       html5: {
  //         vhs: {
  //           enableLowInitialPlaylist: true,
  //           smoothQualityChange: true,
  //         },
  //         nativeAudioTracks: false,
  //         nativeVideoTracks: false,
  //         dash: {
  //           enableLowInitialPlaylist: true,
  //           smoothQualityChange: true,
  //         },
  //         overrides: {
  //           hls: {
  //             enableLowInitialPlaylist: true,
  //             smoothQualityChange: true,
  //           },
  //         },
  //       },
  //     };

  //     const playerInstance = videojs(videoRefs.current, options, () => {});

  //     setPlayer(playerInstance);

  //     return () => {
  //       if (playerInstance) {
  //         playerInstance.dispose();
  //       }
  //     };
  //   }
  // }, [video.url]);

  // useEffect(() => {
  //   if (player) {
  //     const handleVisibilityChange = () => {
  //       if (document.hidden) {
  //         player.pause();
  //       } else {
  //         player.play();
  //       }
  //     };

  //     document.addEventListener("visibilitychange", handleVisibilityChange);

  //     return () => {
  //       document.removeEventListener(
  //         "visibilitychange",
  //         handleVisibilityChange
  //       );
  //     };
  //   }
  // }, [player]);
  useEffect(() => {
    const video = videoRef.current;
    setCurrentTime(video.currentTime);
    const intervalId = setInterval(
      () => setCurrentTime(video.currentTime),
      1000
    );
    return () => clearInterval(intervalId);
  }, [video.url]);
  useEffect(() => {
    if (videoRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.volume = 0;
        videoRef.current.muted = true;
      }
    }
  }, []);
  const handleMute = () => {
    if (isMuted) {
      setVolume(1);
      videoRef.current.volume = 1;
    } else {
      setVolume(0);
      videoRef.current.volume = 0;
    }
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };
  function handleLoadedMetadata() {
    const { duration } = videoRef.current;
    setDuration(duration);
  }
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(1, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };
  const handleProgress = () => {
    const video = videoRef.current;
    const progress = (video.currentTime / video.duration) * 100;
    setProgress(progress);
  };

  const handleSeek = (event) => {
    const video = videoRef.current;
    const seekTime =
      (event.nativeEvent.offsetX / event.currentTarget.offsetWidth) *
      video.duration;
    video.currentTime = seekTime;
  };

  // const handleVolumeChange = (event) => {
  //   const video = videoRef.current;
  //   const volumeLevel =
  //     event.nativeEvent.offsetY / event.currentTarget.offsetHeight;
  //   video.volume = parseFloat(volumeLevel.toFixed(1));
  //   setVolume(video.volume);
  // };

  const handleVolumeSeek = (event) => {
    const video = videoRef.current;
    const volumeLevel =
      event.nativeEvent.offsetY / event.currentTarget.offsetHeight;
    video.volume = volumeLevel.toFixed(1);
    setVolume(video.volume);
  };
  const handleFullscreen = () => {
    const videoElement = videoRef.current;

    if (!document.fullscreenElement) {
      if (videoElement.webkitEnterFullscreen) {
        videoElement.webkitEnterFullscreen();
      } else if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      }

      setIsFullscreen(true);
    } else {
      if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }

      setIsFullscreen(false);
    }
  };
  return (
    <div className="w-full  absolute inset-0">
      {/* <div data-vjs-player> */}
      {/* <video
        ref={videoRefs}
        className="video-js vjs-9-16 vjs-big-play-centered"
        autoPlay={isMobile || window.innerWidth <= 640}
        width="auto"
        height="auto"
        playsInline
        muted
      /> */}
      {/* </div> */}
      <video
        ref={videoRef}
        src={`${video.url}#t=0.1`}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          // objectFit: isFullscreen ? "contain" : "cover",
        }}
        autoPlay={isMobile}
        muted={isMobile}
        loop={true}
        playsInline
        // controls
        onClick={togglePlay}
        onTimeUpdate={handleProgress}
        // onVolumeChange={handleVolumeChange}
        onLoadedMetadata={handleLoadedMetadata}
      />
      <div className="absolute bottom-0  flex flex-col justify-end w-full  px-[16px] pb-[16px] h-[80px] bg-gradient-to-t from-[rgba(0,0,0,0.8)] to-[rgba(0,0,0,0)] font-NotoSansTC">
        <div className="flex  items-center justify-between">
          <div className="flex  items-center ">
            <button
              className="min-w-[24px] h-[24px] mr-[8px] flex justify-center items-center"
              onClick={togglePlay}
            >
              {isPlaying ? (
                <img
                  src={pauseImage}
                  alt="暫停按鈕"
                  className="w-[24px] h-[24px]"
                />
              ) : (
                <img
                  src={playImage}
                  alt="撥放按鈕"
                  className="w-[24px] h-[24px]"
                />
              )}
            </button>
            <div className="text-14px mr-[15px] font-bold leading-none flex text-white">
              <span>{currentTime ? formatTime(currentTime) : "-:--"}</span>/
              <span>{duration ? formatTime(duration) : "-:--"}</span>
            </div>
            <div className="flex w-[40%]">
              <button
                onClick={handleMute}
                className="mr-[10px] min-w-[24px] h-[24px]"
              >
                {isMuted ? (
                  <img
                    src={volumeMuteImage}
                    alt="靜音icon"
                    className="w-[24px] h-[24px]"
                  />
                ) : (
                  <img
                    src={volumeHighImage}
                    alt="音量最大icon"
                    className="w-[24px] h-[24px]"
                  />
                )}
              </button>
              {/* <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={volume}
                onChange={handleVolumeChange}
              /> */}
              {/* <div
              className="w-full bg-black-5 h-[10px] rounded-full"
              onClick={handleVolumeSeek}
            >
              <div
                className="bg-white h-[10px] rounded-full"
                style={{ width: `${volume * 100}%` }}
              />
            </div> */}
            </div>
          </div>
          <button className="w-[24px] h-[24px]" onClick={handleFullscreen}>
            {/* {isFullscreen ? (
              <FontAwesomeIcon icon={faCompress} />
            ) : ( */}
            <img src={expandImage} alt="放大按鈕" />
            {/* )} */}
          </button>
        </div>

        <div
          className="bg-[#ffffff66] h-[4px] rounded-full mt-[10px]"
          onClick={handleSeek}
        >
          <div
            className="bg-white h-full rounded-full"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default Video;
