import { useContext, useEffect, useRef } from "react";
import { SuperpowersGameInfoContext } from "store/contex";
import styled from "styled-components";

const ActiveContainerStyle = styled.div`
  position: relative;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  &::before {
    content: "";
    display: block;
    padding-bottom: 79.93vw;
  }
  @media (max-width: 640px) {
    /* display: none; */
    &::before {
      content: "";
      display: block;
      padding-bottom: 360.8vw;
    }
  }
`;

export const Activities = () => {
  const { gameInfo } = useContext(SuperpowersGameInfoContext);
  return (
    // <ActiveContainerStyle
    //   image={gameInfo && gameInfo.setPictures.background_2_D}
    // >
    <ActiveContainerStyle
      image={
        gameInfo &&
        (window.innerWidth >= 640
          ? gameInfo.setPictures.background_3_D
          : gameInfo.setPictures.background_3_M)
      }
      id="activities"
    >
      {/* <img src={} alt={'說明'}/>       */}
      {/* <div className="flex justify-center h-[10vw]">
        <button
          className={`bg-yellow-4 text-black-4 text-32px rounded-full flex justify-center items-center  w-[182px] h-[64px] h-[64px]drop-shadow-superButton `}
          ref={buttonRef}
        >
          我要報名
        </button>
      </div> */}

      {/* <img
        src={hero_blue_1_D}
        alt="2023 Meteor超級影音大賽藍色一"
        className="absolute top-[6.5%] right-[0] max-w-[333px] w-[23.125%] translate-x-[20%] md:block hidden"
      />
      <div className="sm:w-[75%] w-full m-auto relative">
        <picture className="flex flex-col items-center">
          <img
            src={
              window.innerWidth >= 640
                ? activities_title_pic_D
                : activities_title_pic_M
            }
            alt="2023 Meteor超級影音大賽活動辦法標題"
            // width={"100%"}
            className="sm:w-[78.2%] w-full sm:mb-[120px] mb-[36px]"
          />
          <img
            src={
              window.innerWidth >= 640
                ? activities_content_pic_D
                : activities_content_pic_M
            }
            alt="2023 Meteor超級影音大賽活動辦法"
            // width={window.innerWidth >= "640px" ? "85%" : "100%"}
            className="sm:w-[85%] w-100%"
          />
        </picture>
      </div> */}
      {/* {window.innerWidth >= 751 ? (
          <BackgroundPicture
            src={gameInfo && gameInfo.setPictures.gameIntro_D}
            alt="2023 Meteor超級影音大賽活動辦法"
          />
        ) : (
          <BackgroundPicture
            src={gameInfo && gameInfo.setPictures.gameIntro_M}
            alt="2023 Meteor超級影音大賽活動辦法"
          />
        )} */}
    </ActiveContainerStyle>
  );
};
