import { useContext } from "react";
import { GameInfoContext } from "store/contex";
import { BackgroundPicture } from "components/vote/BackgroundPicture";

export const Activities = () => {
  const { gameInfo } = useContext(GameInfoContext);
  return (
    <>
      <picture className="flex justify-center">
        {window.innerWidth >= 751 ? (
          <BackgroundPicture
            src={gameInfo && gameInfo.setPictures.gameIntro_D}
            alt="2022男神選拔活動辦法"
          />
        ) : (
          <BackgroundPicture
            src={gameInfo && gameInfo.setPictures.gameIntro_M}
            alt="2022男神選拔活動辦法"
          />
        )}
      </picture>
    </>
  );
};
