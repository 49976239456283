import styled from "styled-components";

const NavBarMenu = styled.div`
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  padding: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
    &:hover {
      color: #E9F800;
      transition: color 0.2s ease-out;
    }
    &:hover > svg path {
      fill: #E9F800;
      transition: fill 0.2s ease-out;
    }
    svg {
      height: 32px;
      margin-right: 8px;
    }
  @media (max-width: 640px) {
    font-size: 14px;
    font-weight: 700;
    svg {
      height: 30px;
      margin-right: 4px;
    }
  }
`;

export default NavBarMenu;
