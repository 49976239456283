import React from "react";
import logoImg from "assets/vote/2022uniform/icon_meteor_LOGO.png";

const Logo = () => {
  return (
    <div className="flex items-center">
      <a className="text-black" href="https://meteor.today/board/all">
        <img src={logoImg} alt="米特logo" className="sm:w-[36px] w-[22px]" />
      </a>
    </div>
  );
};

export default Logo;
