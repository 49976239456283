import { useState } from "react";
import ArrowDSvg from "assets/icon/ArrowDSvg.js";
import styled from "styled-components";
import { noticeActiveStore } from "stores/noticeActive.js"

const ArrowWrapper = styled.div`
  margin-top: 7px;
  svg {
    height: 12px;
    width: 20px;
    @media (max-width: 640px) {
      height: 8px;
      width: 14px;
    }
  }
`;

const Accordion = ({ title, content, index }) => {
  let [isActive, setIsActive] = useState(false);

  if (index === 2) {
    isActive = noticeActiveStore().isActive
    setIsActive = noticeActiveStore().setIsActive
  }

  const clickAccordionHandler = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="accordion-item font-NotoSansTC sm:py-[32px] py-[20px]  border-blue-11 border-b-2  last:border-b-0 ">
      <div
        className="flex justify-center items-center accordion-title arrow cursor-pointer"
        onClick={clickAccordionHandler}
      >
        <div className="relative flex justify-center items-center">
          <span className="font-black sm:text-[24px] text-[16px] mt-[5px] sm:mr-[24px] mr-[8px]">{title}</span>

          {isActive ? (
            <ArrowWrapper
              className="rotate-180">
              <ArrowDSvg />
            </ArrowWrapper>
          ) : (
            <ArrowWrapper>
              <ArrowDSvg />
            </ArrowWrapper>
          )}
        </div>
      </div>
      {isActive && (
        <>
          <div className="accordion-content sm:w-[655px] w-[290px] text-12px sm:text-[20px] font-medium  m-auto">
            {content}
          </div>
        </>
      )}
    </div>
  );
};
export default Accordion;
