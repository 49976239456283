import { useState, useMemo, useEffect } from "react";
import { Girl2023InfoContext } from "../store/contex";
import { getGirl2023Info } from "../services/vote/2023girl";

const Girl2023InfoProvider = (props) => {
  const [gameInfo, setGameInfo] = useState(null);

  useEffect(() => {
    getGirl2023Info()
      .then((res) => {
        setGameInfo(res.game);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
    return () => {
      console.log("axios cleanup.");
    };
  }, []);

  const value = useMemo(() => ({ gameInfo, setGameInfo }), [gameInfo]);
  return (
    <Girl2023InfoContext.Provider value={value}>
      {props.children}
    </Girl2023InfoContext.Provider>
  );
};

export default Girl2023InfoProvider;
