import styled from "styled-components";
import { MEDIA_QUERY_MD } from "assets/styles/responsive";
import { centerShow } from "./animation";
import lotteryRecordPicture from "assets/playground/roulette/Desktop/Meteor_component_13_D.png";
import lotteryRecordFork from "assets/playground/roulette/Desktop/Meteor_component_10_D.png";
import lotteryRecordMobilePicture from "assets/playground/roulette/Mobile/Meteor_component_1_M.png";

const LotteryRecordWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 442px;
  height: 548px;
  background-image: url(${lotteryRecordPicture});
  background-size: contain;
  background-repeat: no-repeat;
  animation: ${centerShow} 0.3s linear;
  ${MEDIA_QUERY_MD} {
    background-image: url(${lotteryRecordMobilePicture});
    width: 299px;
    height: 440px;
  }
`;
const LotteryRecordTitle = styled.h2`
  color: #333333;
  font-size: 36px;
  font-weight: 900;
  padding-top: 35px;
  text-align: center;
  line-height: 1;
  letter-spacing: 0.1em;
  ${MEDIA_QUERY_MD} {
    font-size: 24px;
    padding-top: 24px;
  }
`;
const LotteryRecordButton = styled.button`
  width: 40px;
  height: 40px;
  background-image: url(${lotteryRecordFork});
  background-size: cover;
  position: absolute;
  top: 78px;
  right: 8px;
  cursor: pointer;
  ${MEDIA_QUERY_MD} {
    width: 27.06px;
    height: 27.06px;
    top: 52.76px;
    right: 5.41px;
  }
`;
const LotteryRecordItemUl = styled.ul`
  margin: 0 auto;
  max-height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  font-size: 20px;
  font-weight: 700;
  color: #666666;
  width: 376px;
  ${(props) =>
    props.shadow &&
    `background: linear-gradient(
    0deg,
    rgba(61, 61, 61, 0.15) 0%,
    rgba(217, 217, 217, 0.15) 4%,
    rgba(255, 255, 255, 1) 10%
  );
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);`}

  overflow-y: overlay;
  ${MEDIA_QUERY_MD} {
    margin-top: 47px;
    font-size: 16px;
    width: 243px;
    max-height: 309px;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-button {
    display: none;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;

    background-color: #e3e3e3;

    border: 1px solid #e3e3e3;
  }

  &::-webkit-scrollbar-track {
    box-shadow: transparent;
  }
`;
const LotteryRecordNoItem = styled.p`
  text-align: center;
`;

const LotteryRecordItem = ({ item }) => {
  return (
    <LotteryRecordItemWrapper>
      <LotteryRecordItemDate>{item.createdAt}</LotteryRecordItemDate>
      <LotteryRecordItemName>{item.name}</LotteryRecordItemName>
    </LotteryRecordItemWrapper>
  );
};
const LotteryRecordItemWrapper = styled.li`
  width: 336px;
  display: flex;
  padding: 12px 0 12px 12px;
  &:not(:last-child) {
    border-bottom: 1.5px solid #e3e3e3;
  }
  ${MEDIA_QUERY_MD} {
    width: 228px;
    padding: 10px 0 10px 4px;
    font-size: 14px;
  }
`;
const LotteryRecordItemDate = styled.p`
  width: 112px;
  text-align: center;
  margin-right: 20px;
  letter-spacing: 0.08em;
  ${MEDIA_QUERY_MD} {
    width: 85px;
    margin-right: 8px;
    letter-spacing: 0.06em;
  }
`;
const LotteryRecordItemName = styled.p`
  margin: 0;
  letter-spacing: 0.08em;
  ${MEDIA_QUERY_MD} {
    letter-spacing: 0.06em;
  }
`;

const RouletteRecord = ({ lotteryRecord, handlePopupsRemove }) => {
  return (
    <LotteryRecordWrapper>
      <LotteryRecordTitle>中獎紀錄</LotteryRecordTitle>
      <LotteryRecordButton onClick={handlePopupsRemove}></LotteryRecordButton>
      <LotteryRecordItemUl shadow={lotteryRecord.length > 6}>
        {!lotteryRecord.length ? (
          <LotteryRecordNoItem>{"< 無紀錄 >"}</LotteryRecordNoItem>
        ) : (
          lotteryRecord.map((item, i) => (
            <LotteryRecordItem key={i} item={item} />
          ))
        )}
      </LotteryRecordItemUl>
    </LotteryRecordWrapper>
  );
};

export default RouletteRecord;
