import { METEORURL, Girl_2023_ID } from "config/CONSTANTS";
import styled from "styled-components";
import gtag from "ga-gtag";

const SignUpStyle = styled.div`
  background: #FFF;
  border: 7px solid #383EFF;
  width: 100px;
  height: 100px;
  font-size: 24px;
  color: #FF55B3;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: calc(6.1vw - 47px);
  bottom: 118px;
  line-height: 30px;
  border-radius: 100%;
  cursor: pointer;
  z-index: 20;
  @media (max-width: 640px) {
    width: 60px;
    height: 60px;
    border-width: 4px;
    font-size: 16px;
    line-height: 18px;
    line-spacing: 1.12px;
    right: 6px;
  }
`;
const SignUpButton = (props) => {
  const { setToggleMyProfileModal, userSession } = props;

  const singUpClickHandler = () => {
    if (userSession) {
      // ga4 api
      gtag("event", "點擊報名-浮動icon", {
        gameId: Girl_2023_ID,
        userSession: userSession,
        send_to: "G-NQTWZ5RW0Y",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-浮動icon",
        gameId: Girl_2023_ID,
        userSession: userSession,
      });

      const data = {
        userId: userSession,
        gameId: Girl_2023_ID,
      };
      fetch(`${METEORURL}/gameelection/get_my_player_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            alert("您已經報名囉");
            return;
          } else {
            setToggleMyProfileModal(true);
          }
        });
    } else {
      // ga4 api
      gtag("event", "點擊報名-浮動icon", {
        gameId: Girl_2023_ID,
        userSession: "未登入",
        send_to: "G-NQTWZ5RW0Y",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-浮動icon",
        gameId: Girl_2023_ID,
        userSession: "未登入",
      });

      alert("請先登入");
      window.location.href = `${METEORURL}/login?next=${window.location.href}`;
      return;
    }
  };

  return (
    <SignUpStyle onClick={singUpClickHandler}>
      <span>點我</span>
      <span>報名</span>
    </SignUpStyle>
  );
};

export default SignUpButton;
