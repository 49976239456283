import { useState, useContext, useEffect } from "react";
import { getCandidatesModal } from "services/vote/2022highschooluniform";
import { UNIFORM_GAME_ID } from "config/CONSTANTS";
import { UniformGameInfoContext } from "store/contex";
import { isMobile } from "react-device-detect";
import Slider from "react-slick";
import styled from "styled-components";
import Modal from "components/vote/Modal";
import CloseButton from "components/vote/2022uniform/CloseButton";
import VoteButton from "components/vote/2022uniform/VoteButton";
import IgSvg from "components/vote/IgSvg";
import ArrowLineR from "components/vote/ArrowLineR";
import gtag from "ga-gtag";
import WinPrize from "./WinPrize";
import seeMore_button_pic_D from "assets/vote/2022uniform/DESKTOP/BUTTON_1_D.png";
import seeMore_button_pic_M from "assets/vote/2022uniform/MOBILE/BUTTON_1_M.png";
import searchIcon from "assets/vote/2022uniform/icon_SEARCH.png";
import igIcon from "assets/vote/2022uniform/icon_INS_PINK.png";

//test
// import test1 from "assets/vote/2022uniform/TEST/297103462_742414503708592_2454947768108980428_n.jpg";
// import test2 from "assets/vote/2022uniform/TEST/IMG_5722.JPG.jpg";
// import test3 from "assets/vote/2022uniform/TEST/IMG_5723.JPG.jpg";

const maxPlayerCount = 6;

// const testPhoto = [test1, test2, test3];
const slideSettings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const CandidatesStyle = styled.div`
  /* background-size: cover; */
  background-repeat: repeat;
  background-position: center top;
  max-width: 2880px;
  min-height: 1026px;
  background-image: url(${(props) => props.backgroundImage});
  background-size: 50%;
  padding-top: 20px;
  /* @media (max-width: 640px) {
    max-width: 375px;
  } */
`;

const CandidatesModal = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 707px;
  height: 553px;
  background-image: url(${(props) => props.backgroundImage});
  .slick-next:before,
  .slick-prev:before {
    color: #282828;
  }
  @media (max-width: 640px) {
    max-width: 374px;
    /* height: 0; */
    height: 286px;
    /* padding-bottom: 76.47%; */
  }
  @media (max-width: 374px) {
    max-width: 374px;
    height: 0;
    max-height: 286px;
    padding-bottom: calc(76.47% - 34px);
  }
  /* @media (max-width: 767px) {
    background-size: contain;
    max-width: 750px;
    height: 1092px;
  }
  @media (max-width: 510px) {
    background-size: contain;
    max-width: 400px;
    height: 1092px;
  } */
  .flex-item:nth-of-type(1) {
    order: 1;
  }
  .flex-item:nth-of-type(2) {
    order: 3;
  }
  .flex-item:nth-of-type(3) {
    order: 2;
  }
  .flex-item:nth-of-type(4) {
    order: 4;
  }
`;

const SeeMoreButton = styled.button`
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  /* width: 212px; */
  /* height: 67px; */
  padding: 18px 36px;
  background: #fc7454;
  font-weight: 400;
  border-radius: 37px;
  &:hover {
    /* background: #ffe000; */
  }
  @media (max-width: 510px) {
    width: 150px;
    width: 139px;
    height: 44px;
    font-size: 1rem;
  }
`;

export const CandidatesList = (props) => {
  const { gameInfo } = useContext(UniformGameInfoContext);
  const {
    refIFrame,
    candidates,
    initPlayers,
    setInitPlayers,
    announceTime,
    userSession,
    winnerPlayers,
  } = props;
  const [modalData, setModalData] = useState(null);
  const [toggleMenuModal, setToggleMenuModal] = useState(false);
  const [playerModalInfo, setPlayerModalInfo] = useState(null);
  const [query, setQuery] = useState("");
  const [searchParam] = useState(["entryNumber", "nickname", "school"]);
  const [showNumber, setShowNumber] = useState(1);
  const filtered = [...candidates];
  const [nowPlayers, setNowPlayers] = useState([]);
  const [isCompositionEnd, setIsCompositionEnd] = useState(false);
  const clickModalHandler = (playerId) => {
    getCandidatesModal(playerId)
      .then((res) => {
        setPlayerModalInfo(res.player);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
  };

  useEffect(() => {
    setNowPlayers(initPlayers.slice(0, showNumber * maxPlayerCount));
  }, [initPlayers, showNumber]);

  useEffect(() => {
    handleComposition();
  }, []);

  useEffect(() => {
    if (isCompositionEnd) {
      searchHandler();
    }
  }, [query, isCompositionEnd]);

  const handlingComposition = () => {
    setIsCompositionEnd(false);
  };
  const handleComposition = (e) => {
    setIsCompositionEnd(true);
  };

  const searchHandler = () => {
    setInitPlayers(
      filtered.filter((item) => {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          );
        });
      })
    );
    setShowNumber(1);
  };

  const candidatesModalHandler = () => {
    setToggleMenuModal((preState) => !preState);
  };

  const voteHandler = (playerId) => {
    // ga4 api
    gtag("event", "點擊投票", {
      gameId: UNIFORM_GAME_ID,
      userSession: userSession,
      send_to: "G-SR7XCS1GYX",
    });

    // bat api
    window.bat.track("buttonClick", {
      buttonNm: "點擊投票",
      gameId: UNIFORM_GAME_ID,
      userSession: userSession,
    });

    // line tracking
    window._lt("send", "cv", { type: "vote" }, [
      "4255d428-e2bb-4c10-8a89-562815a6d9de",
    ]);
    gtag("event", "conversion", {
      send_to: "AW-862236629/Sv2uCKfJwdwDENXfkpsD",
    });

    const iframeItem = refIFrame.current;
    iframeItem.contentWindow.postMessage(
      JSON.stringify({
        method: "add_vote",
        data: {
          gameId: UNIFORM_GAME_ID,
          playerId,
        },
      }),
      "*"
    );
  };

  return (
    <CandidatesStyle
      backgroundImage={
        window.innerWidth >= 640
          ? gameInfo && gameInfo.setPictures.background_1_D
          : gameInfo && gameInfo.setPictures.background_1_M
      }
    >
      <div className="w-[90%] sm:w-[75%] m-auto ">
        <div className="flex justify-end mx-auto ">
          <div className="relative w-1/2 mt-49px sm:mr-0  flex sm:justify-center lg:justify-end justify-center lg:w-1/3">
            {/* <Search searchHandler={searchHandler}/> */}
            <div className="relative sm:w-[80%] w-[80%] z-20">
              <input
                className=" sm:w-full w-[133.33%] sm:ml-[0] -ml-[17%] block bg-white text-orange-2 xl:text-[16px] lg:text-[14px]
                 sm:text-base text-[12px] focus:outline-none border-2 border-orange-2  sm:py-8px sm:px-[20px] sm:pr-[40px] px-[7px] py-[7.5px] pr-[26px] sm:pt-[11px] sm:rounded-15px rounded-[9.6px] placeholder-orange-2 sm:scale-[1] scale-[0.75] sm:h-auto h-[33.33px]"
                type="text"
                placeholder="輸入編號 / 暱稱 / 學校"
                value={query}
                id="search"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onCompositionStart={handlingComposition}
                onCompositionUpdate={handlingComposition}
                onCompositionEnd={handleComposition}
              />
              {/* <div className="text-[12px] scale-[0.83]">
                {" "}
                輸入編號 / 暱稱 / 學校
              </div> */}
              <div className="absolute h-full flex items-center sm:right-[12px] right-[8px] top-0">
                <img
                  src={searchIcon}
                  alt="searchIcon"
                  className="sm:h-[28px] h-[14px]"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto flex flex-wrap sm:pt-[70px] pt-[27px] ">
          {nowPlayers.map((candidate, i) => (
            <div
              key={candidate.playerId}
              className={`relative w-full lg:w-1/3 w-3/6 flex flex-col items-center sm:mb-[70px] mb-[27px] text-blue-3 font-DelaGothicOne ${
                (i + 1) % 3 === 1 ? "lg:items-start" : ""
              } ${(i + 1) % 3 === 2 ? "lg:items-center" : ""} ${
                (i + 1) % 3 === 0 ? "lg:items-end" : ""
              }`}
            >
              <div className="relative w-[80%]">
                <div className="relative">
                  <div
                    onClick={() => {
                      setToggleMenuModal((preState) => !preState);
                      setModalData(candidate);
                      clickModalHandler(candidate.playerId);
                    }}
                    style={{
                      backgroundImage: `url(${candidate.playerPhotos[0]})`,
                      // backgroundImage: `url(${testPhoto[0]})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                    className="relative w-[100%] h-0 pb-[100%] sm:mb-[27px] mb-[10px] cursor-pointer  bg-white group sm:overflow-hidden"
                  >
                    <div className="hidden sm:opacity-0 sm:group-hover:opacity-100 sm:duration-300 sm:absolute sm:inset-x-0 sm:bottom-0 sm:flex sm:justify-center sm:items-center sm:h-50px sm:text-xl sm:h-64px c-mask sm:text-white sm:font-semibold">
                      No.{candidate.entryNumber}
                    </div>
                  </div>
                  {announceTime && candidate && (
                    <WinPrize
                      winnerPlayers={winnerPlayers}
                      userId={candidate.playerId}
                      candidatesModalHandler={candidatesModalHandler}
                      setModalData={setModalData}
                      candidate={candidate}
                      clickModalHandler={clickModalHandler}
                    />
                  )}
                </div>
                <div className=" flex flex-col justify-between w-full w-auto ">
                  <div className="inline-flex  justify-between ">
                    {/* mx-10px */}
                    <div
                      onClick={() => {
                        candidatesModalHandler();
                        setModalData(candidate);
                        clickModalHandler(candidate.playerId);
                      }}
                      className=" sm:text-[22px] sm:text-[22px] text-[14px] cursor-pointer flex items-center font-GenYoGothic-M font-black"
                    >
                      {candidate.nickname}
                    </div>
                    {/* 票 */}
                    <div
                      className={`relative top-0 inline-flex text-orange-2  items-center`}
                    >
                      <div className="">
                        <span
                          className={`sm:text-[22px] sm:text-[22px] text-[14px]`}
                        >
                          {String(candidate.voteCount).replace(
                            /(\d)(?=(\d{3})+$)/g,
                            "$1,"
                          )}
                        </span>
                        <span className="sm:text-[22px] sm:text-base text-12px">
                          {" "}
                          票
                        </span>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="flex items-start sm:pt-[27px] pt-[10px]">
                    <div className="font-normal sm:text-[18px] sm:text-base text-[12px] mt-0 sm:mt-2 font-GenYoGothic-B whitespace-pre-line sm:scale-[1] scale-[91.67%]">
                      {candidate.school.replace("(", " \u000A (")}
                    </div>
                    <div className="flex  justify-end mt-0  xl:pr-0px font-GenYoGothic-B flex-1">
                      <VoteButton
                        voteHandler={() => voteHandler(candidate.playerId)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {candidates.length !== 0 &&
            showNumber < initPlayers.length / maxPlayerCount && (
              <div className="flex justify-center w-full sm:pb-[35px] pb-[27px] mt-m10px sm:mt-[12px] sm:mt-0px">
                <button className="max-w-[298px] sm:w-[20.69%] w-[131px]">
                  <img
                    src={
                      window.innerWidth >= 768
                        ? seeMore_button_pic_D
                        : seeMore_button_pic_M
                    }
                    alt="查看更多"
                    onClick={() => {
                      setShowNumber(showNumber + 1);
                    }}
                  />
                </button>
                {/* <SeeMoreButton
                  onClick={() => {
                    setShowNumber(showNumber + 1);
                  }}
                >
                  <span>看更多制服</span>
                </SeeMoreButton> */}
              </div>
            )}

          <Modal
            handleClose={() => setToggleMenuModal(false)}
            isOpen={toggleMenuModal}
          >
            {playerModalInfo && (
              <CandidatesModal
                backgroundImage={
                  window.innerWidth < 640
                    ? gameInfo && gameInfo.setPictures.popup_M
                    : gameInfo && gameInfo.setPictures.popup_D
                }
                className="z-20 mx-auto flex  block   sm:pl-[53px] sm:pt-[77px] relative pl-[40px] pt-[34px]"
              >
                <CloseButton
                  size="60"
                  handleClose={() => setToggleMenuModal(false)}
                />
                {/* <CandidatesModal backgroundImage={isMobile ? gameInfo && gameInfo.setPictures.popup_M : gameInfo && gameInfo.setPictures.popup_D } className="z-20 mx-auto sm:flex sm:flex-wrap block sm:px-150px sm:pt-150px sm:pb-100px pt-280px px-30px title-top"> */}
                {
                  <div className="relative w-[142px] h-[142px] sm:w-[284px]  sm:mr-[46px] mr-[20px] flex flex-col  justify-start items-center">
                    {/* {window.innerWidth < 768 && (
                      <div className="absolute right-0 top-0">
                        <CloseButton
                          size="40"
                          handleClose={() => setToggleMenuModal(false)}
                        />
                      </div>
                    )} */}
                    {/* {window.innerWidth < 768 && (
                      <div className="text-18px font-black text-blue-1 mb-4">
                        No. {modalData.entryNumber}
                      </div>
                    )} */}

                    <div
                      className="w-[142px] h-[142px] mb-20px sm:w-[284px] sm:h-[284px] 
                        cursor-pointer  bg-[#d8d8d8]"
                    >
                      <Slider {...slideSettings}>
                        {/* {testPhoto.map((item) => { */}
                        {playerModalInfo.playerPhotos.map((item) => {
                          return (
                            item && (
                              <div key={item}>
                                <div
                                  className="w-[142px] h-[142px] 
                                  sm:w-[284px] sm:h-[284px] overflow-hidden"
                                  style={{
                                    backgroundImage: `url(${item})`,
                                    backgroundPosition: "center center",
                                    backgroundSize: "cover",
                                  }}
                                ></div>
                              </div>
                            )
                          );
                        })}
                      </Slider>
                    </div>
                    <div
                      className="flex text-black-1  sm:justify-between w-full 
                          mt-3
                        "
                    >
                      <div className="inline-flex flex-col  font-DelaGothicOne w-full ">
                        <div className="flex justify-between items-center sm:text-[22px] text-20px text-blue-3 text-[12px] sm:pb-[9px] sm:pt-[5px]sm:mt-[0] sm:mb-[0] -mt-[8px] -mb-[3px]">
                          <div className="inline-flex sm:text-[22px] sm:scale-[1] scale-[83%] sm:ml-0 -ml-[3px]">
                            No. {modalData.entryNumber}
                          </div>
                          <div className=" sm:text-[22px] text-20px text-base cursor-pointer text-[12px] sm:scale-[1] scale-[83%] font-GenYoGothic-M font-black sm:mr-0 -mr-[6px]">
                            {modalData.nickname}
                          </div>
                        </div>
                        <div className="flex justify-between  w-full">
                          {" "}
                          <div className="font-normal sm:text-[16px] text-12px  font-GenYoGothic-B text-blue-3 whitespace-pre-line sm:scale-[1] scale-[83%] sm:ml-[0] -ml-[6px]">
                            <span className="">
                              {modalData.school.replace("(", " \u000A (")}
                            </span>
                          </div>
                          <div className=" inline-flex  text-orange-2 flex-1 justify-end ">
                            <div className="sm:text-20px text-[10px]  sm:scale-[1] scale-[83%] sm:mr-[0] -mr-[3px]">
                              <span className="whitespace-nowrap sm:scale-[1] scale-[83%]">
                                {String(modalData.voteCount).replace(
                                  /(\d)(?=(\d{3})+$)/g,
                                  "$1,"
                                )}{" "}
                                票
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  <div className="relative w-full sm:w-[271px] flex flex-col  justify-start sm:px-0 w-[136px] text-24px sm:pt-[22px] pt-[11px]">
                    <div className="sm:text-20px text-12px text-black-1">
                      <p className="sm:text-[22px] border-b border-b-solid border-b-orange-2 font-DelaGothicOne text-orange-2 sm:w-[96px] w-full sm:tracking-[1.83px] tracking-[0.92px] text-14px">
                        制服介紹
                      </p>
                      <p className="sm:mt-[17px] mt-[8px]  text-[#434343] sm:text-[16px] text-[12px] sm:tracking-[2.53px] tracking-[1.58px]font-GenYoGothic-B sm:mb-[25px] mb-[16px] break-words">
                        {playerModalInfo.intro}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      {playerModalInfo.socialLinks.instagram ? (
                        <a
                          href={`https://www.instagram.com/${playerModalInfo.socialLinks.instagram}`}
                          target="_blank"
                          rel="noreferrer"
                          className="block h-full flex items-center  pr-10px"
                        >
                          <img
                            src={igIcon}
                            alt="igIcon"
                            className="h-[17px] sm:h-[29px]"
                          />
                        </a>
                      ) : (
                        <div></div>
                      )}
                      <div className="flex w-1/2 justify-end  sm:mt-0 ">
                        {/* <VoteButton
                            voteHandler={() => voteHandler(modalData.playerId)}
                          /> */}
                        <button
                          onClick={() => voteHandler(modalData.playerId)}
                          className="sm:w-[116px] sm:h-[41px] w-[63px] h-[21px] flex justify-center items-center bg-orange-2 sm:rounded-[24px] rounded-[12px] sm:text-14px text-[12px] text-white font-GenYoGothic-B tracking-[1.17px]"
                        >
                          <span className="sm:scale-[1] scale-[83%]">
                            投他 1 票
                          </span>
                        </button>
                      </div>
                    </div>

                    {/* {window.innerWidth < 768 && (
                      <div className="flex justify-between items-center mt-24px">
                        {playerModalInfo.socialLinks.instagram && (
                          <a
                            href={`https://www.instagram.com/${playerModalInfo.socialLinks.instagram}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IgSvg />
                          </a>
                        )}
                        <VoteButton
                          voteHandler={() => voteHandler(modalData.playerId)}
                        />
                      </div>
                    )} */}
                  </div>
                }
              </CandidatesModal>
            )}
          </Modal>
        </div>
      </div>
    </CandidatesStyle>
  );
};
