import { useContext, useEffect, useRef } from "react";
import { METEORURL, SUPERPOWERS_GAME_ID } from "config/CONSTANTS";
import { SuperpowersGameInfoContext } from "store/contex";
import SignUpButtonImage from "assets/vote/2023superpowers/DESKTOP/SignUpButton.png";
import gtag from "ga-gtag";
export const Process = (props) => {
  const { setToggleSignUpModal, userSession, registerDateOver } = props;
  const { gameInfo } = useContext(SuperpowersGameInfoContext);

  const singUpClickHandler = () => {
    if (userSession) {
      // ga4 api
      gtag("event", "點擊報名-跑馬燈", {
        gameId: SUPERPOWERS_GAME_ID,
        userSession: userSession,
        send_to: "G-SR7XCS1GYX",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-跑馬燈",
        gameId: SUPERPOWERS_GAME_ID,
        userSession: userSession,
      });

      const data = {
        userId: userSession,
        gameId: SUPERPOWERS_GAME_ID,
      };
      fetch(`${METEORURL}/gameelection/get_my_player_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            alert("您已經報名囉");
            return;
          } else {
            setToggleSignUpModal(true);
          }
        });
    } else if (!userSession) {
      // ga4 api
      gtag("event", "點擊報名-跑馬燈", {
        gameId: SUPERPOWERS_GAME_ID,
        userSession: "未登入",
        send_to: "G-SR7XCS1GYX",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-跑馬燈",
        gameId: SUPERPOWERS_GAME_ID,
        userSession: "未登入",
      });

      alert("請先登入");
      window.location.href = `${METEORURL}/login?next=${window.location.href}`;
      return;
    } else {
      setToggleSignUpModal(true);
    }
  };
  return (
    <div className="bg-mobileProcess sm:bg-webProcess pb-[238.4%] sm:pb-[69.58%]  bg-cover bg-no-repeat relative bg-center">
      {registerDateOver ? (
        <button
          onClick={singUpClickHandler}
          className="absolute bottom-[7.27%] sm:bottom-[3.99%] left-1/2 -translate-x-1/2"
        >
          <img
            src={SignUpButtonImage}
            alt="報名按鈕"
            className="w-[171.878px] sm:w-[calc(100vw/1440*222)] max-w-[222px]"
          />
        </button>
      ) : null}
    </div>
  );
};
