import { useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import ReactPortal from "./ReactPortal";
import "./ModalStyles.css";

const Modal = ({ children, isOpen, handleClose }) => {
  const nodeRef = useRef(null);
  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === "Escape" ? handleClose() : null);
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [handleClose]);

  const closeHandler = (target) => {
    if (target && target.className && target.className.includes('modal-content')) {
      handleClose()
    }
  }

  return (
    <ReactPortal
      wrapperId="portal-modal-container">
      <CSSTransition
        in={isOpen}
        timeout={{ entry: 0, exit: 300 }}
        unmountOnExit
        classNames="modal"
        nodeRef={nodeRef}
      >
        <div className="modal" ref={nodeRef}>
          <div
            className="modal-content"
            onClick={(e) => closeHandler(e.target)}>
            {children}
          </div>
        </div>
      </CSSTransition>
    </ReactPortal>
  );
};
export default Modal;
