const LineSvg = () => {
  return (

<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.867 13.6766H14.327C14.2411 13.6766 14.1797 13.7441 14.1797 13.8239V17.1867C14.1797 17.2664 14.2472 17.3339 14.327 17.3339H14.867C14.9529 17.3339 15.0142 17.2664 15.0142 17.1867V13.8239C15.0142 13.7441 14.9467 13.6766 14.867 13.6766Z" fill="white"/>
<path d="M18.5857 13.6767H18.0457C17.9597 13.6767 17.8984 13.7442 17.8984 13.8239V15.8183L16.3581 13.738C16.3581 13.738 16.352 13.7258 16.3459 13.7258V13.7135H16.3336V13.7012H16.3152V13.6889H16.2968V13.6767H16.2538V13.6644H15.6893C15.6034 13.6644 15.542 13.7319 15.542 13.8117V17.1744C15.542 17.2542 15.6095 17.3217 15.6893 17.3217H16.2293C16.3152 17.3217 16.3765 17.2542 16.3765 17.1744V15.1801L17.9168 17.2603C17.9168 17.2603 17.9413 17.2849 17.9536 17.2972H17.9659V17.3094H17.9966C17.9966 17.3094 18.0211 17.3217 18.0334 17.3217H18.5734C18.6593 17.3217 18.7207 17.2542 18.7207 17.1744V13.8117C18.7207 13.7319 18.6532 13.6644 18.5734 13.6644" fill="white"/>
<path d="M13.5599 16.4933H12.0871V13.8239C12.0871 13.738 12.0196 13.6766 11.9398 13.6766H11.3998C11.3139 13.6766 11.2526 13.7441 11.2526 13.8239V17.1867C11.2526 17.2296 11.271 17.2664 11.2955 17.291C11.2955 17.291 11.3569 17.3401 11.3998 17.3401H13.5599C13.6458 17.3401 13.7071 17.2726 13.7071 17.1928V16.6528C13.7071 16.5669 13.6396 16.5055 13.5599 16.5055" fill="white"/>
<path d="M21.574 14.5173C21.6599 14.5173 21.7213 14.4498 21.7213 14.37V13.83C21.7213 13.7441 21.6538 13.6828 21.574 13.6828H19.414C19.371 13.6828 19.3342 13.7012 19.3097 13.7257C19.2851 13.7564 19.2667 13.7932 19.2667 13.8362V17.1989C19.2667 17.2419 19.2851 17.2787 19.3097 17.3033C19.3097 17.3033 19.371 17.3524 19.414 17.3524H21.574C21.6599 17.3524 21.7213 17.2849 21.7213 17.2051V16.6651C21.7213 16.5792 21.6538 16.5178 21.574 16.5178H20.1074V15.9471H21.574C21.6599 15.9471 21.7213 15.8796 21.7213 15.7998V15.2598C21.7213 15.1739 21.6538 15.1126 21.574 15.1126H20.1074V14.5419H21.574V14.5173Z" fill="white"/>
<path d="M21.5804 6H11.2835C8.36866 6 6 8.36253 6 11.2835V21.5865C6 24.5013 8.36253 26.87 11.2835 26.87H21.5865C24.5013 26.87 26.87 24.5075 26.87 21.5865V11.2835C26.87 8.36866 24.5075 6 21.5865 6H21.5804ZM22.4886 19.1933C20.8686 21.0527 17.2542 23.3231 16.4319 23.6729C15.6097 24.0166 15.7324 23.452 15.7631 23.2556C15.7815 23.1391 15.8735 22.5929 15.8735 22.5929C15.8981 22.3965 15.9287 22.0897 15.849 21.8934C15.7631 21.6786 15.4194 21.5681 15.1678 21.5129C11.4614 21.022 8.71844 18.4324 8.71844 15.3396C8.71844 11.891 12.1794 9.08049 16.4319 9.08049C20.6845 9.08049 24.1454 11.891 24.1454 15.3396C24.1454 16.7203 23.6116 17.966 22.4947 19.1872L22.4886 19.1933Z" fill="white"/>
</svg>
  )}

export default LineSvg
