import { useContext } from "react";
import { METEORURL, Girl_2023_ID } from "config/CONSTANTS";
import { Girl2023InfoContext } from "store/contex";
import styled from "styled-components";
import gtag from "ga-gtag";
import { pump } from "assets/styles/animation/pump"
import eggB from "assets/vote/2023girl/DESKTOP/eggB.png"
import eggO from "assets/vote/2023girl/DESKTOP/eggO.png"
import eggG from "assets/vote/2023girl/DESKTOP/eggG.png"
import eggY from "assets/vote/2023girl/DESKTOP/eggY.png"

const ProcessContainerStyle = styled.div`
  position: relative;
  display: block;
  z-index: 0;
  margin-top: -10vw;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 80vw;
  @media (max-width: 640px) {
    height: 187vw;
    margin-top: -20vw;
  }
`;

const EggBlue = styled.img`
  top: 21.5%;
  left: 7%;
  width: 10vw;
  ${pump(50, 1.5, 0.8, 'blue2', 'rotate(-15deg)')}
  @media (max-width: 640px) {
    width: 12vw;
    top: 19%;
    left: 3%;
  }
`;

const EggGreen = styled.img`
  bottom: 27.5%;
  left: -2%;
  width: 13vw;
  ${pump(30, 1.2, 1.2, 'green')}
  @media (max-width: 640px) {
    bottom: 20.5%;
    width: 19.7vw;
  }
`;

const EggOrange = styled.img`
  top: 20%;
  right: -1%;
  width: 16vw;
  ${pump(60, 1.5, 0.8, 'orange2')}
  @media (max-width: 640px) {
    top: 13%;
    right: -2%;
    width: 22.7vw;
  }
`;
const EggYellow = styled.img`
  bottom: 34%;
  right: 8%;
  width: 9vw;
  ${pump(30, 1.2, 1.1, 'yellow2', 'rotate(170deg)')}
  @media (max-width: 640px) {
    bottom: 27%;
    width: 14vw;
  }
`;
export const Process = (props) => {
  const { setToggleMyProfileModal, userSession, registerDateOver } = props;
  const { gameInfo } = useContext(Girl2023InfoContext);

  const singUpClickHandler = () => {
    if (userSession) {
      // ga4 api
      gtag("event", "點擊報名-跑馬燈", {
        gameId: Girl_2023_ID,
        userSession: userSession,
        send_to: "G-NQTWZ5RW0Y",
      });
      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-跑馬燈",
        gameId: Girl_2023_ID,
        userSession: userSession,
      });

      const data = {
        userId: userSession,
        gameId: Girl_2023_ID,
      };
      fetch(`${METEORURL}/gameelection/get_my_player_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            alert("您已經報名囉");
            return;
          } else {
            setToggleMyProfileModal(true);
          }
        });
    } else {
      // ga4 api
      gtag("event", "點擊報名-跑馬燈", {
        gameId: Girl_2023_ID,
        userSession: "未登入",
        send_to: "G-NQTWZ5RW0Y",
      });
      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-跑馬燈",
        gameId: Girl_2023_ID,
        userSession: "未登入",
      });
      alert("請先登入");
      window.location.href = `${METEORURL}/login?next=${window.location.href}`;
      return;
    }
  };
  return (
    <ProcessContainerStyle
      image={
        gameInfo &&
        (window.innerWidth >= 640
          ? gameInfo.setPictures.signUpIntro_D
          : gameInfo.setPictures.signUpIntro_M)
      }
      id="processes"
    >
      <EggBlue
        src={window.innerWidth >= 640 ? eggB : eggB}
        alt="2023 Meteor校園女神選拔賽背景藍蛋"
      />
      <EggGreen
        src={window.innerWidth >= 640 ? eggG : eggG}
        alt="2023 Meteor校園女神選拔賽背景藍蛋"
      />
      <EggOrange
        src={window.innerWidth >= 640 ? eggO : eggO}
        alt="2023 Meteor校園女神選拔賽背景藍蛋"
      />
      <EggYellow
        src={window.innerWidth >= 640 ? eggY : eggY}
        alt="2023 Meteor校園女神選拔賽背景藍蛋"
      />
      {registerDateOver ? (
        <button
          onClick={singUpClickHandler}
          className="absolute bottom-[24.3%] sm:bottom-[19%] lg:bottom-[24.3%] bottom-[23%] left-1/2 -translate-x-1/2 sm:py-[6px] sm:px-[20px] py-[4px] px-[14px] bg-pink-11 sm:text-[24px] text-[14px] font-black sm:rounded-[12px] rounded-[8px] text-[#FFF] bg-pink-11 leading-normal cursor:point">
          我要報名
        </button>
      ) : null}
    </ProcessContainerStyle>
  );
};
