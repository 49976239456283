import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { getAllCandidates } from "services/vote/2023superpowers";
import { Navbar } from "./components/Navbar";
import { Hero } from "./components/Hero";
import { VideoList } from "./components/VideoList";
import { CandidatesList } from "./components/CandidatesList";
import { Activities } from "./components/Activities";
import { Process } from "./components/Process";
import { Notice } from "./components/Notice";
import SignUpButton from "./components/SignUpButton";
import Modal from "components/vote/2023super/Modal";
import SignUp from "./components/SignUp";
import MyProfile from "./components/MyProfile";
import Meta from "./components/Meta";
import { SuperpowersGameInfoContext } from "store/contex";
import gtag from "ga-gtag";
import "assets/styles/scroll.css";
import "assets/styles/checkbox.css";
import { useRoutes } from "react-router-dom";
import { METEORURL } from "config/CONSTANTS";
import LinkToNew from "components/vote/LinkToNew"

const Superpowers = () => {
  const { gameInfo } = useContext(SuperpowersGameInfoContext);
  const [vote, setVote] = useState([]);
  const [navbarColor, setNavbarColor] = useState(false);
  const [registerDateOver, setRegisterDateOver] = useState(false);
  const [announceTime, setAnnounceTime] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [initPlayers, setInitPlayers] = useState([]);
  const [winnerPlayers, setWinnerPlayers] = useState([]);
  const [userSession, setUserSession] = useState(null);
  const [toggleSignUpModal, setToggleSignUpModal] = useState(false);
  const [toggleMyProfileModal, setToggleMyProfileModal] = useState(false);
  const [signUpButtonVisible, setSignUpButtonVisible] = useState(false);
  const myRef = useRef(null);
  const refIFrame = useRef(null);
  const test = [
    {
      entryNumber: 1,
      nickname: "南一中電神",
      playerId: "625a52728ec0ffaa61059427",
      playerVideo: {
        id: 1,
        title: "影音說明文字1",
        url: "https://storage.meteor.today/video/61f6df8af82da432e77f1d08.mp4",
      },
      school: "臺南一中",
      voteCount: 9902,
    },
    {
      entryNumber: 2,
      nickname: "南一中電神",
      playerId: "625a52728ec0ffaa61059421",
      playerVideo: {
        id: 2,
        title: "影音說明文字2",
        url: "https://storage.cloud.google.com/award-dancing-cats/61961d939714654614a50fdd.MOV",
      },
      school: "臺南一中",
      voteCount: 9902,
    },
    {
      entryNumber: 3,
      nickname: "南一中電神",
      playerId: "625a52728ec0ffaa61059422",
      playerVideo: {
        id: 3,
        title: "影音說明文字3",
        url: "https://storage.googleapis.com/bat-meteor-video/6141bfb68bc609d646688c36.MP4",
      },
      school: "臺南一中",
      voteCount: 9902,
    },
    {
      entryNumber: 4,
      nickname: "南一中電神",
      playerId: "625a52728ec0ffaa61059423",
      playerVideo: {
        id: 4,
        title: "影音說明文字4",
        url: "https://storage.googleapis.com/bat-meteor-video/617108d253ea6fb21809c414.MP4",
      },
      school: "臺南一中",
      voteCount: 9902,
    },
    {
      entryNumber: 5,
      nickname: "南一中電神",
      playerId: "625a52728ec0ffaa61059424",
      playerVideo: {
        id: 5,
        title: "影音說明文字5",
        url: "https://storage.googleapis.com/bat-meteor-video/6188f45aa101e1dca0d1080f.MOV",
      },
      school: "臺南一中",
      voteCount: 9902,
    },
    {
      entryNumber: 6,
      nickname: "南一中電神",
      playerId: "625a52728ec0ffaa61059426",
      playerVideo: {
        id: 6,
        title: "影音說明文字6",
        url: "https://storage.googleapis.com/bat-meteor-video/6194ff58e88ecb6326ef1619.MOV",
      },
      school: "臺南一中",
      voteCount: 9902,
    },
    {
      entryNumber: 7,
      nickname: "南一中電神",
      playerId: "625a52728ec0ffaa61059425",
      playerVideo: {
        id: 7,
        title: "影音說明文字6",
        url: "https://storage.googleapis.com/bat-meteor-video/6194ff58e88ecb6326ef1619.MOV",
      },
      school: "臺南一中",
      voteCount: 9902,
    },
    {
      entryNumber: 8,
      nickname: "南一中電神",
      playerId: "625a52728ec0ffaa61059428",
      playerVideo: {
        id: 7,
        title: "影音說明文字6",
        url: "https://storage.googleapis.com/bat-meteor-video/6188f45aa101e1dca0d1080f.MOV",
      },
      school: "臺南一中",
      voteCount: 9902,
    },
    {
      entryNumber: 9,
      nickname: "南一中電神",
      playerId: "625a52728ec0ffaa61059429",
      playerVideo: {
        id: 7,
        title: "影音說明文字6",
        url: "https://storage.googleapis.com/bat-meteor-video/617108d253ea6fb21809c414.MP4",
      },
      school: "臺南一中",
      voteCount: 9902,
    },
  ];
  useEffect(() => {
    gtag("event", "page_view", {
      page_title: "Meteor2023 | 奇人異事情大賞",
      page_location: "https://game.meteor.today",
      page_path: "/vote/2023superpowers",
      send_to: "G-QCEE9DSCCW",
    });
  }, []);
  useEffect(() => {
    if (gameInfo) {
      const registerStart = gameInfo.registerStart;
      const registerEnd = gameInfo.registerEnd;
      const announceTime = gameInfo.announceTime;
      const announceDate = new Date(announceTime);

      const date_now = new Date().getTime();
      const dateStart = new Date(registerStart).getTime();
      const dateEnd = new Date(registerEnd).getTime();
      // console.log(gameInfo, "時間", date_now, dateStart, date_now, dateEnd);
      if (date_now > dateStart && date_now < dateEnd) {
        // console.log("時間正確");
        setRegisterDateOver(true);
      } else {
        setRegisterDateOver(false);
      }

      if (announceDate < date_now) {
        setAnnounceTime(true);
      } else {
        setAnnounceTime(false);
      }
    }
  }, [gameInfo]);

  const listenToScroll = useCallback(() => {
    let heightToHideFrom = myRef.current.getBoundingClientRect().y;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    // if (winScroll < heightToHideFrom) {
    if (heightToHideFrom >= 0) {
      signUpButtonVisible && setSignUpButtonVisible(false);
      setNavbarColor(false);
    } else {
      setSignUpButtonVisible(true);
      setNavbarColor(true);
    }
  }, [signUpButtonVisible]);
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [listenToScroll]);

  const myProfileModalHandler = () => {
    setToggleMyProfileModal((preState) => !preState);
  };
  useEffect(() => {
    getAllCandidates()
      .then((res) => {
        setCandidates(
          res.players.sort((a, b) => {
            if (b.voteCount === a.voteCount) {
              return a.entryNumber - b.entryNumber;
            }
            return b.voteCount - a.voteCount;
          })
        );
        // setCandidates(test);
        setInitPlayers(
          res.players.sort((a, b) => {
            if (b.voteCount === a.voteCount) {
              return a.entryNumber - b.entryNumber;
            }
            return b.voteCount - a.voteCount;
          })
        );
        // setInitPlayers(test);
        setWinnerPlayers(
          res.players.sort((a, b) => b.voteCount - a.voteCount).slice(0, 3)
        );
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
    return () => {
      console.log("axios cleanup.");
    };
  }, [vote]);

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        xfbml: true,
        version: "v12.0",
        appId: "1717255128492433",
      });
      window.FB.XFBML.parse();
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/zh_TW/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    return () => {
      if (window.FB) {
        window.FB.XFBML.parse();
      }
    };
  }, []);

  const getLogin = () => {
    const iframeItem = refIFrame.current;
    iframeItem.contentWindow.postMessage(
      JSON.stringify({ method: "get_login" }),
      "*"
    );
  };

  const onLoad = () => {
    getLogin();
  };

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.origin !== METEORURL) {
        return;
      }
      const { data } = e;
      // console.log(data);
      setUserSession(data.data.userId);

      switch (data.method) {
        case "add_vote":
          if (data.status !== 200) {
            if (data.data === "請先登入") {
              window.location.href = `${METEORURL}/login?next=${window.location.href}`;
            }
            alert(data.data);
            getLogin();
            return;
          } else {
            setVote([...vote, 1]);
            alert("投票成功");
            getLogin();
          }

          break;
        default:
          break;
      }
    });
  }, []);

  const singUpModalHandler = () => {
    if (!userSession) {
      alert("請先登入");
      window.location.href = `${METEORURL}/login?next=${window.location.href}`;
      return;
    }
    setToggleSignUpModal((preState) => !preState);
  };

  return (
    <div className="font-NotoSansTC overflow-hidden">
      <Meta />
      <Navbar
        myProfileModalHandler={myProfileModalHandler}
        refIFrame={refIFrame}
        userSession={userSession}
        navbarColor={navbarColor}
      />
      <div className="flex flex-col">
        <section>
          <Hero />
        </section>
        {/* <section>
          <VideoList />
        </section> */}
        <section ref={myRef}>
          <CandidatesList
            userSession={userSession}
            refIFrame={refIFrame}
            candidates={candidates}
            setCandidates={setCandidates}
            initPlayers={initPlayers}
            setInitPlayers={setInitPlayers}
            winnerPlayers={winnerPlayers}
            announceTime={announceTime}
          />
        </section>
        <section>
          <Activities />
        </section>
        <section>
          <Process
            registerDateOver={registerDateOver}
            userSession={userSession}
            setToggleSignUpModal={setToggleSignUpModal}
          />
        </section>
        <section>
          <Notice />
        </section>
      </div>
      <LinkToNew />
      {/* {signUpButtonVisible && ( */}
      {signUpButtonVisible && registerDateOver && (
        <SignUpButton
          userSession={userSession && userSession}
          singUpModalHandler={singUpModalHandler}
        />
      )}
      <iframe
        ref={refIFrame}
        title="meteor"
        onLoad={onLoad}
        id="iframe_dom_box"
        style={{ width: "0", height: "0" }}
        src={`${METEORURL}/iframe/handsome2022`}
        frameBorder="0"
      ></iframe>
      <Modal
        handleClose={() => setToggleSignUpModal(false)}
        isOpen={toggleSignUpModal}
      >
        <SignUp
          userSession={userSession}
          setToggleSignUpModal={setToggleSignUpModal}
          refIFrame={refIFrame}
        />
      </Modal>
      {userSession && (
        <Modal
          handleClose={() => setToggleMyProfileModal(false)}
          isOpen={toggleMyProfileModal}
        >
          <MyProfile
            userSession={userSession}
            myProfileModalHandler={myProfileModalHandler}
            setToggleMyProfileModal={setToggleMyProfileModal}
            refIFrame={refIFrame}
          />
        </Modal>
      )}
    </div>
  );
};

export default Superpowers;
