const VoteButton = (props) => {
  const { voteHandler } = props;
  return (
    <>
      <button
        onClick={voteHandler}
        className="md:w-[130px] md:h-[44px] w-[118px] h-[40px] flex justify-center items-center  text-base bg-yellow-4 rounded-full md:text-[20px]  font-black text-18px  text-black-4 shadow-superButton"
      >
        <span className="">投他 1 票</span>
      </button>
    </>
  );
};

export default VoteButton;
