import { BASEURL, TESTBASEURL } from "./CONTANTS";

const distinguishUrl = (url) => {
  if (!(url === BASEURL || url === TESTBASEURL)) {
    return BASEURL + "/minigame";
  }
  return url + "/minigame";
};

export const playMiniGameFetch = async (miniGameId, userId, url = BASEURL) => {
  const nowURL = distinguishUrl(url);
  try {
    const response = await fetch(nowURL + "/play_miniGame", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        miniGameId,
        userId,
      }),
    });
    if (!response.ok) throw await response.json();
    return await response.json();
  } catch (error) {
    if (!error.error) {
      return { error: "發生錯誤，請聯絡米特FB粉絲專頁。" };
    }
    return error;
  }
};

export const getMiniGameRecordsFetch = async (
  miniGameId,
  userId,
  url = BASEURL
) => {
  const nowURL = distinguishUrl(url);
  try {
    const response = await fetch(nowURL + "/get_miniGame_records", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        miniGameId,
        userId,
      }),
    });
    if (!response.ok) throw await response.json();
    return await response.json();
  } catch (error) {
    if (!error.error) {
      return { error: "發生錯誤，請聯絡米特FB粉絲專頁。" };
    }
    return error;
  }
};
