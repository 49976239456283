const IGSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.1891 5.59998H10.8831C7.96466 5.59998 5.6001 7.96454 5.6001 10.883V21.189C5.6001 24.106 7.96466 26.472 10.8831 26.472H21.1891C24.1061 26.472 26.4721 24.1074 26.4721 21.189V10.883C26.4721 7.96454 24.1075 5.59998 21.1891 5.59998ZM23.4257 19.0071C23.4257 21.4547 21.4337 23.4467 18.9862 23.4467H13.086C10.6385 23.4467 8.64648 21.4547 8.64648 19.0071V13.107C8.64648 10.6595 10.6385 8.66744 13.086 8.66744H18.9862C21.4337 8.66744 23.4257 10.6595 23.4257 13.107V19.0086V19.0071Z" fill="white"/>
    <path d="M18.9861 10.0578H13.0859C11.4046 10.0578 10.0367 11.4256 10.0367 13.107V19.0086C10.0367 20.6899 11.4046 22.0578 13.0859 22.0578H18.9861C20.6674 22.0578 22.0353 20.6899 22.0353 19.0086V13.107C22.0353 11.4256 20.6674 10.0578 18.9861 10.0578ZM16.0972 19.6974C14.0897 19.6974 12.4575 18.0639 12.4575 16.0578C12.4575 14.0517 14.0911 12.4181 16.0972 12.4181C18.1033 12.4181 19.7368 14.0503 19.7368 16.0578C19.7368 18.0653 18.1033 19.6974 16.0972 19.6974ZM19.9491 13.0395C19.4711 13.0395 19.0831 12.6515 19.0831 12.1735C19.0831 11.6956 19.4711 11.3076 19.9491 11.3076C20.4271 11.3076 20.8151 11.6956 20.8151 12.1735C20.8151 12.6515 20.4271 13.0395 19.9491 13.0395Z" fill="white"/>
    <path d="M16.097 13.7256C14.8107 13.7256 13.7648 14.7715 13.7648 16.0578C13.7648 17.3441 14.8107 18.39 16.097 18.39C17.3833 18.39 18.4278 17.3441 18.4278 16.0578C18.4278 14.7715 17.3819 13.7256 16.097 13.7256Z" fill="white"/>
    </svg>
  )
}
export default IGSvg
