import styled from "styled-components/macro";
import useRoulette from "hooks/roulette/useRoulette";
import RouletteHome from "./components/RouletteHome";
import Loading from "./components/Loading";
import { useEffect } from "react";
import gtag from "ga-gtag";

const RouletteWrapper = styled.div`
  max-width: 1440px;
  position: relative;
  background-color: #bce3ff;
  min-height: calc(100vh - 50px);
  margin: 0px auto;
  overflow: hidden;
`;
const Roulette = () => {
  const {
    rouletteInfo,
    prizes,
    lotteryFreeTimes,
    userCoin,
    preAngle,
    rouletteRotate,
    popupShow,
    setPopupShow,
    handleChangeRouletteRotate,
    handlePopupsRemove,
    lottery,
    lotteryRecord,
    handleRecord,
    errorMessage,
    handleMouseClick,
    canPlayTimes,
  } = useRoulette();
  useEffect(() => {
    gtag("event", "page_view", {
      page_title: "Meteor輪盤",
      page_location: "https://game.meteor.today",
      page_path: "/playground/roulette",
      send_to: "G-1YPCHKVB9X",
    });
  }, []);
  return (
    <RouletteWrapper onClick={handleMouseClick}>
      {(popupShow === "loading" || popupShow === "loadingError") && (
        <Loading popupShow={popupShow} errorMessage={errorMessage} />
      )}
      <RouletteHome
        data={{
          rouletteInfo,
          prizes,
          lotteryFreeTimes,
          userCoin,
          preAngle,
          rouletteRotate,
          handleChangeRouletteRotate,
          popupShow,
          setPopupShow,
          handlePopupsRemove,
          lottery,
          handleRecord,
          lotteryRecord,
          errorMessage,
          canPlayTimes,
        }}
      />
    </RouletteWrapper>
  );
};

export default Roulette;
