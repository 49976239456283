import { SYSTEM_ERROR } from "config/CONSTANTS";
import axios from "axios";
import { LOG_SHARE } from "./CONSTANTS";

/**
 * Function to vote.
 */
export const logShare = ({ userId, gameId, shareOn }) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(LOG_SHARE(), {
          userId,
          gameId,
          shareOn,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("logShare > axios err=", err);
          reject("Error in logShare axios!");
        });
    } catch (error) {
      console.error("in userServices > logShare, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
