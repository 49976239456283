import { useState } from "react";
import ARROW_UP from "assets/vote/ARROW_UP.png";
import ARROW_DOWN from "assets/vote/ARROW_DOWN.png";
import plusIcon from "assets/vote/2022uniform/icon_MORE.png";
import minusIcon from "assets/vote/2022uniform/icon_MINUS.png";
import styled from "styled-components";

const AccordionTitle = styled.div`
  &::before {
    content: "";
    background: url(${(props) => props.img});
    top: 0;
    right: 0;
    width: ${(props) => props.width};
    height: 20px;
  }
`;

const Accordion = ({ title, content, index }) => {
  const [isActive, setIsActive] = useState(false);
  const clickAccordionHandler = () => {
    setIsActive((prevState) => !prevState);
  };

  return (
    <div className="accordion-item text-[28px] text-blue-3 sm:pt-[23px] pt-[10px]">
      <div
        className="flex justify-center items-center accordion-title arrow  font-DelaGothicOne cursor-pointer"
        onClick={clickAccordionHandler}
      >
        <div className="relative">
          {title}
          {isActive ? (
            <img
              src={minusIcon}
              alt="展開減號"
              width={window.innerWidth >= 640 ? "23px" : "10px"}
              className="absolute top-[45%] sm:-right-[47px] -right-[31px]"
            />
          ) : (
            <img
              src={plusIcon}
              alt="未展開加號"
              width={window.innerWidth >= 640 ? "22px" : "10px"}
              className="absolute sm:top-[27%] top-[35%] sm:-right-[46px] -right-[30px]"
            />
          )}
        </div>
      </div>
      {isActive && (
        <>
          <div className="accordion-content sm:w-[59.5%] w-[235px] m-auto">
            {content}
          </div>
        </>
      )}
      {+index === 3 ? (
        ""
      ) : (
        <hr className="h-[2px] bg-[#febe03] max-w-[858px] sm:w-[59.5%] m-auto sm:mt-[23px] mt-[10px] w-[235px]" />
      )}
    </div>
  );
};
export default Accordion;
