import { useState, useContext, useEffect } from "react";
import { getCandidatesModal } from "services/vote";
import { GAME_ID } from "config/CONSTANTS";
import { GameInfoContext } from "store/contex";
import { isMobile } from "react-device-detect";
import Slider from "react-slick";
import styled from "styled-components";
import Modal from "components/vote/Modal";
import CloseButton from "components/vote/CloseButton";
import VoteButton from "components/vote/VoteButton";
import IgSvg from "components/vote/IgSvg";
import ArrowLineR from "components/vote/ArrowLineR";
import gtag from "ga-gtag";
import WinPrize from "./WinPrize";

const maxPlayerCount = 6;

const slideSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const CandidatesStyle = styled.div`
  background-size: cover;
  background-repeat: repeat;
  background-position: center top;
  max-width: 2880px;
  min-height: 1026px;
  background-image: url(${(props) => props.backgroundImage});
  @media (max-width: 510px) {
    min-height: 600px;
  }
`;

const CandidatesModal = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 1300px;
  height: 829px;
  background-image: url(${(props) => props.backgroundImage});
  .slick-next:before,
  .slick-prev:before {
    color: #282828;
  }
  @media (max-width: 768px) {
    background-size: contain;
    max-width: 750px;
    height: 1092px;
  }
  @media (max-width: 510px) {
    background-size: contain;
    max-width: 400px;
    height: 1092px;
  }
  .flex-item:nth-of-type(1) {
    order: 1;
  }
  .flex-item:nth-of-type(2) {
    order: 3;
  }
  .flex-item:nth-of-type(3) {
    order: 2;
  }
  .flex-item:nth-of-type(4) {
    order: 4;
  }
`;

const SeeMoreButton = styled.button`
  color: #1c4bf5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.44rem;
  width: 200px;
  height: 63px;
  background: #ffffff;
  font-weight: 700;
  border-radius: 30px;
  &:hover {
    background: #ffe000;
  }
  @media (max-width: 510px) {
    width: 150px;
    width: 139px;
    height: 44px;
    font-size: 1rem;
  }
`;

export const CandidatesList = (props) => {
  const { gameInfo } = useContext(GameInfoContext);
  const {
    refIFrame,
    candidates,
    initPlayers,
    setInitPlayers,
    announceTime,
    userSession,
    winnerPlayers,
  } = props;
  const [modalData, setModalData] = useState(null);
  const [toggleMenuModal, setToggleMenuModal] = useState(false);
  const [playerModalInfo, setPlayerModalInfo] = useState(null);
  const [query, setQuery] = useState("");
  const [searchParam] = useState(["entryNumber", "nickname", "school"]);
  const [showNumber, setShowNumber] = useState(1);
  const filtered = [...candidates];
  const [nowPlayers, setNowPlayers] = useState([]);
  const [isCompositionEnd, setIsCompositionEnd] = useState(false);
  const clickModalHandler = (playerId) => {
    getCandidatesModal(playerId)
      .then((res) => {
        setPlayerModalInfo(res.player);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
  };

  useEffect(() => {
    setNowPlayers(initPlayers.slice(0, showNumber * maxPlayerCount));
  }, [initPlayers, showNumber]);

  useEffect(() => {
    handleComposition();
  }, []);

  useEffect(() => {
    if (isCompositionEnd) {
      searchHandler();
    }
  }, [query, isCompositionEnd]);

  const handlingComposition = () => {
    setIsCompositionEnd(false);
  };
  const handleComposition = (e) => {
    setIsCompositionEnd(true);
  };

  const searchHandler = () => {
    setInitPlayers(
      filtered.filter((item) => {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          );
        });
      })
    );
    setShowNumber(1);
  };

  const candidatesModalHandler = () => {
    setToggleMenuModal((preState) => !preState);
  };

  const voteHandler = (playerId) => {
    // ga4 api
    gtag("event", "點擊投票", {
      gameId: GAME_ID,
      userSession: userSession,
    });

    // bat api
    window.bat.track("buttonClick", {
      buttonNm: "點擊投票",
      gameId: GAME_ID,
      userSession: userSession,
    });

    // line tracking
    window._lt("send", "cv", { type: "vote" }, [
      "4255d428-e2bb-4c10-8a89-562815a6d9de",
    ]);

    const iframeItem = refIFrame.current;
    iframeItem.contentWindow.postMessage(
      JSON.stringify({
        method: "add_vote",
        data: {
          gameId: GAME_ID,
          playerId,
        },
      }),
      "*"
    );
  };

  return (
    <CandidatesStyle
      backgroundImage={
        isMobile
          ? gameInfo && gameInfo.setPictures.background_2_M
          : gameInfo && gameInfo.setPictures.background_2_D
      }
    >
      <div className="flex justify-end mx-auto sm:px-99px px-24px">
        <div className="relative mt-49px sm:mr-0 mr-15px">
          {/* <Search searchHandler={searchHandler}/> */}
          <input
            className="block bg-transparent text-white sm:text-18px text-10px font-bold focus:outline-none border-2 border-yellow-2 pr-20px sm:p-4 py-8px px-16px rounded-15px placeholder-yellow-2"
            type="text"
            placeholder="輸入編號 / 暱稱 / 學校"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            onCompositionStart={handlingComposition}
            onCompositionUpdate={handlingComposition}
            onCompositionEnd={handleComposition}
          />
          <svg
            onClick={searchHandler}
            className="absolute sm:top-20px right-12px top-7px cursor-pointer"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.7099 20.2899L17.9999 16.6099C19.44 14.8143 20.1374 12.5352 19.9487 10.2412C19.76 7.94721 18.6996 5.81269 16.9854 4.27655C15.2713 2.74041 13.0337 1.91941 10.7328 1.98237C8.43194 2.04534 6.24263 2.98747 4.61505 4.61505C2.98747 6.24263 2.04534 8.43194 1.98237 10.7328C1.91941 13.0337 2.74041 15.2713 4.27655 16.9854C5.81269 18.6996 7.94721 19.76 10.2412 19.9487C12.5352 20.1374 14.8143 19.44 16.6099 17.9999L20.2899 21.6799C20.3829 21.7736 20.4935 21.848 20.6153 21.8988C20.7372 21.9496 20.8679 21.9757 20.9999 21.9757C21.1319 21.9757 21.2626 21.9496 21.3845 21.8988C21.5063 21.848 21.6169 21.7736 21.7099 21.6799C21.8901 21.4934 21.9909 21.2442 21.9909 20.9849C21.9909 20.7256 21.8901 20.4764 21.7099 20.2899ZM10.9999 17.9999C9.61544 17.9999 8.26206 17.5894 7.11091 16.8202C5.95977 16.051 5.06256 14.9578 4.53275 13.6787C4.00293 12.3996 3.86431 10.9921 4.13441 9.63427C4.4045 8.27641 5.07119 7.02912 6.05016 6.05016C7.02912 5.07119 8.27641 4.4045 9.63427 4.13441C10.9921 3.86431 12.3996 4.00293 13.6787 4.53275C14.9578 5.06256 16.051 5.95977 16.8202 7.11091C17.5894 8.26206 17.9999 9.61544 17.9999 10.9999C17.9999 12.8564 17.2624 14.6369 15.9497 15.9497C14.6369 17.2624 12.8564 17.9999 10.9999 17.9999Z"
              fill="#F7DD41"
            />
          </svg>
        </div>
      </div>

      <div className="mx-auto flex flex-wrap sm:mt-94px pt-44px sm:px-99px px-24px">
        {nowPlayers.map((candidate, i) => (
          <div
            key={candidate.playerId}
            className="relative w-full lg:w-1/3 w-3/6 flex flex-col mb-50px items-center text-white"
          >
            <div className="relative">
              <div
                onClick={() => {
                  setToggleMenuModal((preState) => !preState);
                  setModalData(candidate);
                  clickModalHandler(candidate.playerId);
                }}
                style={{
                  backgroundImage: `url(${candidate.playerPhotos[0]})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
                className="relative w-154px h-154px 2xl:w-342px 2xl:h-342px sm:w-242px sm:h-242px sm:mb-36px mb-15px cursor-pointer rounded-full bg-white group sm:overflow-hidden"
              >
                <div className="hidden sm:opacity-0 sm:group-hover:opacity-100 sm:duration-300 sm:absolute sm:inset-x-0 sm:bottom-0 sm:flex sm:justify-center sm:items-center sm:h-50px sm:text-xl sm:h-64px c-mask sm:text-white sm:font-semibold">
                  No.{candidate.entryNumber}
                </div>
                {i < 3 &&
                  announceTime &&
                  candidate &&
                  window.innerWidth < 600 && (
                    <WinPrize
                      winnerPlayers={winnerPlayers}
                      userId={candidate.playerId}
                      candidatesModalHandler={candidatesModalHandler}
                      setModalData={setModalData}
                      candidate={candidate}
                      clickModalHandler={clickModalHandler}
                    />
                  )}
              </div>
              {announceTime && candidate && window.innerWidth > 600 && (
                <WinPrize
                  winnerPlayers={winnerPlayers}
                  userId={candidate.playerId}
                  candidatesModalHandler={candidatesModalHandler}
                  setModalData={setModalData}
                  candidate={candidate}
                  clickModalHandler={clickModalHandler}
                />
              )}
            </div>
            <div className="xl:px-15px xl:w-375px flex justify-between sm:w-full w-auto sm:px-10px">
              <div className="inline-flex flex-col xl:mx-12px ">
                {/* mx-10px */}
                <div
                  onClick={candidatesModalHandler}
                  className="font-bold xl:text-30px sm:text-24px text-15px cursor-pointer"
                >
                  {candidate.nickname}
                </div>
                <div className="font-normal sm:text-base text-10px mt-0 sm:mt-2">
                  {candidate.school}
                </div>
              </div>
              <div
                className={`relative float-right top-0 inline-flex ${
                  candidate.school.length > 10
                    ? "mx-0 sm:mt-0"
                    : "mx-10px sm:mt-10px"
                } sm:mx-12px`}
              >
                <div className="font-bold">
                  <span
                    className={`xl:text-30px sm:text-24px ${
                      candidate.school.length > 10 ? "text-12px" : "text-base"
                    }`}
                  >
                    {candidate.voteCount}
                  </span>
                  <span className="xl:text-18px sm:text-base text-12px">
                    票
                  </span>
                </div>
              </div>
            </div>
            <div className="flex mt-4 justify-end sm:mt-0 sm:w-296px lg:pr-20px xl:pr-0px">
              <VoteButton voteHandler={() => voteHandler(candidate.playerId)} />
            </div>
          </div>
        ))}
        {candidates.length !== 0 &&
          showNumber < initPlayers.length / maxPlayerCount && (
            <div className="flex justify-center w-full pb-20px mt-m10px md:py-85px md:mt-0px">
              <SeeMoreButton
                onClick={() => {
                  setShowNumber(showNumber + 1);
                }}
              >
                <span>+ 看更多男神</span>
              </SeeMoreButton>
            </div>
          )}

        <Modal
          handleClose={() => setToggleMenuModal(false)}
          isOpen={toggleMenuModal}
        >
          {playerModalInfo && (
            <CandidatesModal
              backgroundImage={
                window.innerWidth < 1024
                  ? gameInfo && gameInfo.setPictures.popup_M
                  : gameInfo && gameInfo.setPictures.popup_D
              }
              className="z-20 mx-auto lg:flex lg:flex-wrap block sm:px-150px sm:pt-150px sm:pb-100px pt-280px px-30px title-top"
            >
              {/* <CandidatesModal backgroundImage={isMobile ? gameInfo && gameInfo.setPictures.popup_M : gameInfo && gameInfo.setPictures.popup_D } className="z-20 mx-auto sm:flex sm:flex-wrap block sm:px-150px sm:pt-150px sm:pb-100px pt-280px px-30px title-top"> */}
              {
                <div className="relative w-full lg:w-1/2 flex flex-col lg:justify-center justify-start items-center">
                  {window.innerWidth < 1024 && (
                    <div className="absolute right-0 top-N18px">
                      <CloseButton
                        size="40"
                        fill={"#282828"}
                        handleClose={() => setToggleMenuModal(false)}
                      />
                    </div>
                  )}
                  {window.innerWidth < 1024 && (
                    <div className="text-18px font-black text-blue-1 mb-4">
                      No. {modalData.entryNumber}
                    </div>
                  )}

                  <div
                    className="w-128px h-128px mb-20px xl:w-342px xl:h-342px lg:w-154px lg:h-154px sm:mb-36px 
                        cursor-pointer rounded-full bg-white"
                  >
                    <Slider {...slideSettings}>
                      {playerModalInfo.playerPhotos.map((item) => {
                        return (
                          item && (
                            <div key={item}>
                              <div
                                className="w-128px h-128px xl:w-342px xl:h-342px lg:w-154px lg:h-154px rounded-full overflow-hidden"
                                style={{
                                  backgroundImage: `url(${item})`,
                                  minHeight: "128px",
                                  minWidth: "128px",
                                  backgroundPosition: "center center",
                                  backgroundSize: "cover",
                                }}
                              ></div>
                            </div>
                          )
                        );
                      })}
                    </Slider>
                  </div>
                  <div
                    className="flex text-black-1 sm:w-auto sm:justify-center w-full justify-around 
                          mt-3
                        "
                  >
                    <div className="inline-flex flex-col mx-12px">
                      <div className="font-bold sm:text-36px text-20px text-base cursor-pointer">
                        {modalData.nickname}
                      </div>
                      <div className="font-normal sm:text-18px text-12px mt-0 sm:mt-2">
                        {modalData.school}
                      </div>
                    </div>
                    <div className="relative top-N15px inline-flex flex-col mx-12px">
                      <div className="font-bold mb-20px">
                        <span className="sm:text-36px text-20px text-blue-1 mr-1">
                          {modalData.voteCount}
                        </span>
                        <span className="sm:text-20px text-12px">票</span>
                      </div>
                    </div>
                  </div>
                  {window.innerWidth >= 1024 && (
                    <div className="flex sm:w-4/6 w-full justify-end mt-4 sm:mt-0 pr-15px sm:pr-15px">
                      <VoteButton
                        voteHandler={() => voteHandler(modalData.playerId)}
                      />
                    </div>
                  )}
                </div>
              }
              {
                <div className="relative w-full lg:w-1/2 flex flex-col sm:justify-center justify-start sm:px-0 px-55px text-24px">
                  {window.innerWidth >= 1024 && (
                    <div className="relative right-25px top-N80px">
                      <CloseButton
                        size="60"
                        fill={"#282828"}
                        handleClose={() => setToggleMenuModal(false)}
                      />
                    </div>
                  )}
                  {window.innerWidth >= 1024 && (
                    <div className="inline-flex justify-between items-center pr-100px hidden sm:block sm:text-33px text-18px font-black text-blue-1">
                      <div className="inline-flex">
                        {" "}
                        No. {modalData.entryNumber}
                      </div>
                      <div className="inline-flex float-right pt-10px">
                        {playerModalInfo.socialLinks.instagram && (
                          <a
                            href={`https://www.instagram.com/${playerModalInfo.socialLinks.instagram}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IgSvg />
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className="flex flex-wrap sm:pl-0 sm:mt-24px sm:text-20px text-12px sm:leading-50px font-semibold text-black-1"
                    style={{ fonteight: "600" }}
                  >
                    <div className="lg:w-full flex-item w-1/2">
                      身高：{playerModalInfo.info["身高"]}
                    </div>
                    <div className="lg:w-full flex-item w-1/2">
                      體重：{playerModalInfo.info["體重"]}
                    </div>
                    <div className="lg:w-full flex-item w-1/2 sm:pl-0 pl-12px">
                      興趣專長：{playerModalInfo.info["興趣專長"]}
                    </div>
                    <div className="lg:w-full flex-item w-1/2 sm:pl-0 pl-12px">
                      感情狀況：{playerModalInfo.info["感情狀況"]}
                    </div>
                  </div>
                  <ArrowLineR width={isMobile ? "100%" : "450"} height="36" />
                  <div className="sm:text-20px text-12px text-black-1">
                    <p className="font-semibold">自我介紹：</p>
                    <p className="sm:mt-20px mt-8px sm:pr-100px sm:leading-normal leading-20px font-extralight text-black-2">
                      {playerModalInfo.intro}
                    </p>
                  </div>
                  {window.innerWidth < 1024 && (
                    <div className="flex justify-between items-center mt-24px">
                      {playerModalInfo.socialLinks.instagram && (
                        <a
                          href={`https://www.instagram.com/${playerModalInfo.socialLinks.instagram}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IgSvg />
                        </a>
                      )}
                      <VoteButton
                        voteHandler={() => voteHandler(modalData.playerId)}
                      />
                    </div>
                  )}
                </div>
              }
            </CandidatesModal>
          )}
        </Modal>
      </div>
    </CandidatesStyle>
  );
};
