const Welcome = () => {
  return (
    <div className="flex justify-center mx-auto p-10">
      <header>
        <a
          className="cursor-pointer"
          href="https://meteor.today/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Welcome to Meteor!
        </a>
      </header>
    </div>
  );
};

export default Welcome;
