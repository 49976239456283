import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { GameInfoContext } from "store/contex";
import styled from "styled-components";
import Accordion from "components/vote/Accordion";

const NoticeStyle = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  max-width: 2880px;
  min-height: 500px;
  background-image: url(${(props) => props.backgroundImage});
  padding: 3.125rem 2.5rem 15rem 2.8125rem;

  .red {
    color: red;
  }
  .arrow img {
    width: 60px;
  }
  .accordion-item {
    ul {
      list-style-type: disc;
      li:last-child {
        margin-bottom: 20px;
      }
      li:not(:last-child) {
        border-bottom: 0.5px solid #fff;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }
    .accordion-title {
      font-weight: 900;
      font-size: 1rem;
    }
    .accordion-content {
      font-size: 0.875rem;
    }
  }
  @media (min-width: 768px) {
    padding: 6.1875rem 16.4375rem 30rem 16.4375rem;

    .accordion-item {
      ul {
        li:first-child {
          margin-top: 2rem;
        }
        li:not(:last-child) {
          margin-bottom: 2rem;
          padding-bottom: 2rem;
        }
      }
      .accordion-title {
        font-size: 1.675rem;
      }
      .accordion-content {
        font-size: 1.375rem;
      }
    }
  }
`;
export const Notice = () => {
  const { gameInfo } = useContext(GameInfoContext);

  const accordionData = [
    {
      index: 1,
      title: "投票規範",
      content: (
        <ul>
          <li>登入通過審核的Meteor帳號(會員)每日可投3票</li>
          <li>使用FB登入(未通過審核的帳號)每日可投1票</li>
          <li>
            於活動頁右上方分享活動到Line或是FB單日可增加一票，分享到兩個社群可得2票
          </li>
          <li>可重複投給同一位參賽者</li>
        </ul>
      ),
    },
    {
      index: 2,
      title: "注意事項",
      content: (
        <ul>
          <li>
            參加者於參加本活動同時，即同意接受本活動之活動辦法與注意事項之規範，如有違反者得取消其參加或得獎者資格。對於個人資料，僅做為本活動聯絡用，活動執行單位將盡保密之責，不做其他用途亦不會對外洩漏。
          </li>
          <li>
            主辦單位保有審核參加資格之權利，若使用不適當圖片，主辦單位有權刪除其內容並取消獲選或參加資格，恕不另行通知。
          </li>
          <li>參賽者需在活動期間具備高校生身分以及米特會員身分。</li>
          <li>
            參賽者於活動網站上所上傳之照片內容，必須為原創及參賽者個人擁有並保證未侵犯第三者之權利，若有違反，參賽者自行負擔相關之法律責任。
          </li>
          <li>
            參與本活動之參賽者，若為未滿20歲之未成年，應於您的法定代理人或是監護人閱讀並同意本活動之所有內容，方得參加本活動。當您參加本活動時，即視為您的法定代理人或是監護人已閱讀並同意接受本活動之所有內容規範。
          </li>
          <li>主辦單位擁有所有報名者於本活動上傳之照片之版權及肖像使用權。</li>
          <li>主辦單位有權使用報名者於本活動上傳之照片進行行銷、宣傳用途。</li>
          <li>
            活動期間為維護比賽公平性，禁止網友發表不當言論內容、從事任何非法程式、假借不真實身分參加本活動，以及任何影響活動公平性或破壞本活動進行之規則，一旦經查證或告發，將取消其參加活動資格，並追究法律責任。
          </li>
          <li>得獎者有協助冠名及贊助廠商以及Meteor官方宣傳之義務。</li>
          <li>
            主辦單位保留擴大、修改、取消、終止該活動以及更改活動截止日期的權利，參賽者及投票者不得對主辦單位主張任何權利。
          </li>
        </ul>
      ),
    },
    {
      index: 3,
      title: "領獎相關",
      content: (
        <ul>
          <li>得獎者獲獎後須配合主辦單位與冠名廠商合作影像拍攝事宜。</li>
          <li>
            得獎者必須在Instagram限時動態上分享米特三篇圖文，如未配合官方推廣，即得獎者未盡得獎義務，將無法獲得獎金。
          </li>
          <li>
            截止投票後，在活動首頁公布得獎名單資訊並使用Meteor社群之站內信通知獲獎人，票數最高前三名者，且完成上述條件後可獲得萬元獎金，並有機會洽談進一步的合作機會。
          </li>
          <li>
            活動得獎獎金寄送(匯款)範圍僅限台、澎、金、馬地區。得獎者須填妥領據後，寄回Meteor主辦單位確認簽收，逾期視同放棄得獎資格，主辦單位不再通知。
          </li>
          <li>
            比賽得獎名單公佈後，若參賽者未回覆主辦單位通知，將取消其資格，主辦單位將以候補名額替補。若因資料錯誤，導致無法聯繫者時，主辦單位將不再另行補寄通知。
          </li>
        </ul>
      ),
    },
  ];

  return (
    <NoticeStyle
      backgroundImage={
        isMobile
          ? gameInfo && gameInfo.setPictures.background_3_M
          : gameInfo && gameInfo.setPictures.background_3_D
      }
    >
      {accordionData.map(({ title, content, index }) => (
        <Accordion key={title} index={index} title={title} content={content} />
      ))}
    </NoticeStyle>
  );
};
