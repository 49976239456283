const LinkToNew = () => {
    return (
        <>
        <a
            href="https://game.meteor.today/vote/beautyCampaign2023"
            target="_blank"
            rel="noreferrer"
            className="fixed z-30 sm:top-[71px] sm:right-[38px] top-[65px] right-[11px]">
                <img
                   className="sm:w-[155px] w-[110px]"
                   alt="2023 校園女神選拔賽"
                   src="https://storage.meteor.today/image/6544614cb0038808ad29a37a.png"/>
        </a>
        </>
    )
}

export default LinkToNew