import { useContext } from "react";
import Marquee from "react-fast-marquee";
import { UNIFORM_GAME_ID } from "config/CONSTANTS";
import { UniformGameInfoContext } from "store/contex";
import { BrowserView, MobileView } from "react-device-detect";
import process_title_pic_D from "assets/vote/2022uniform/DESKTOP/title_2_D.png";
import process_title_pic_M from "assets/vote/2022uniform/MOBILE/title_2_M.png";
import process_content_pic_D from "assets/vote/2022uniform/DESKTOP/STEPS_D.png";
import process_content_pic_M from "assets/vote/2022uniform/MOBILE/STEPS_M.png";
import process_button_pic_D from "assets/vote/2022uniform/DESKTOP/BUTTON_2_D.png";
import process_button_pic_M from "assets/vote/2022uniform/MOBILE/BUTTON_2_M.png";
import styled from "styled-components";
import gtag from "ga-gtag";
const ProcessContainerStyle = styled.div`
  background-image: url(${(props) => props.img});
  padding-bottom: 60px;
  background-size: 50%;
`;
const AbsoluteCenterD = styled.div`
  left: 50%;
  bottom: 60px;
  transform: translate(-50%, -50%);
`;
const AbsoluteCenterM = styled.div`
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const Process = (props) => {
  const { setToggleSignUpModal, userSession, registerDateOver } = props;
  const { gameInfo } = useContext(UniformGameInfoContext);

  const singUpClickHandler = () => {
    if (userSession) {
      // ga4 api
      gtag("event", "點擊報名-跑馬燈", {
        gameId: UNIFORM_GAME_ID,
        userSession: userSession,
        send_to: "G-SR7XCS1GYX",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-跑馬燈",
        gameId: UNIFORM_GAME_ID,
        userSession: userSession,
      });

      const data = {
        userId: userSession,
        gameId: UNIFORM_GAME_ID,
      };
      fetch("https://meteor.today/gameelection/get_my_player_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            alert("您已經報名囉");
            return;
          } else {
            setToggleSignUpModal(true);
          }
        });
    } else if (!userSession) {
      // ga4 api
      gtag("event", "點擊報名-跑馬燈", {
        gameId: UNIFORM_GAME_ID,
        userSession: "未登入",
        send_to: "G-SR7XCS1GYX",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-跑馬燈",
        gameId: UNIFORM_GAME_ID,
        userSession: "未登入",
      });

      alert("請先登入");
      window.location.href =
        "https://meteor.today/login?next=https://game.meteor.today/vote/2022highschooluniform";
      return;
    } else {
      setToggleSignUpModal(true);
    }
  };

  return (
    <ProcessContainerStyle
      img={
        gameInfo &&
        (window.innerWidth >= 640
          ? gameInfo.setPictures.background_2_D
          : gameInfo.setPictures.background_2_M)
      }
    >
      {/* <ProcessContainerStyle
      img={
        gameInfo && window.innerWidth >=640
          ? gameInfo.setPictures.background_2_D
          : gameInfo.setPictures.background_2_M
      }
    > */}
      <div className="sm:w-[75%] m-auto w-full">
        <picture className="flex flex-col items-center">
          <img
            src={
              window.innerWidth >= 640
                ? process_title_pic_D
                : process_title_pic_M
            }
            alt="2022高校制服大賞報名流程標題"
            width={"78.2%"}
            className="sm:mb-[60px] mb-[35px] sm:w-[78.2%] w-full"
          />
          <img
            src={
              window.innerWidth >= 640
                ? process_content_pic_D
                : process_content_pic_M
            }
            alt="2022高校制服大賞報名流程"
            width={"64.375%"}
            className="sm:w-[64.375%] w-full"
          />
        </picture>
        <div className="flex justify-center sm:mt-[30px] mt-[26px]">
          <button
            onClick={singUpClickHandler}
            className="max-w-[298px] md:w-[20.69%] w-[140px]"
          >
            <img
              src={
                window.innerWidth >= 640
                  ? process_button_pic_D
                  : process_button_pic_M
              }
              alt="報名按鈕"
            />
          </button>
        </div>
      </div>

      {/* <BrowserView className="relative flex justify-center">
        <picture>
          <img
            src={gameInfo && gameInfo.setPictures.signUpIntro_D}
            alt="2022高校制服大賞報名流程"
          />
        </picture>

        <AbsoluteCenterD className="absolute bottom-0">
          {registerDateOver && (
            <button
              onClick={singUpClickHandler}
              className="inline rounded-[37px] bg-orange-2 text-white text-[26px] px-[50px] py-[18px] shadow-[6px_9px_0_0_rgba(213,213,213,1)]"
            >
              我要報名
            </button>
          )}
        </AbsoluteCenterD>
      </BrowserView>
      <MobileView className="relative flex justify-center">
        <picture>
          {window.innerWidth >= 751 ? (
            <img
              src={gameInfo && gameInfo.setPictures.signUpIntro_D}
              alt="2022高校制服大賞報名流程"
            />
          ) : (
            <img
              src={gameInfo && gameInfo.setPictures.signUpIntro_M}
              alt="2022高校制服大賞報名流程"
            />
          )}
        </picture>
        {registerDateOver && (
          <AbsoluteCenterM className="absolute bottom-0">
            <button
              onClick={singUpClickHandler}
              className="inline rounded-15px bg-orange-2 text-white text-16px px-15px py-6px"
            >
              我要報名
              
            </button>
          </AbsoluteCenterM>
        )}
      </MobileView> */}
    </ProcessContainerStyle>
  );
};
