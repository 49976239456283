import React from "react";
import logoImg from "assets/vote/METEOR_BRAND_icon.png";

const Logo = () => {
  return (
    <div className="pl-3 flex items-center">
      <a className="text-black" href="https://meteor.today/board/all">
        <img src={logoImg} alt="" width="50px" />
      </a>
    </div>
  );
};

export default Logo;
