import { useState } from "react";
import ARROW_UP from "assets/vote/ARROW_UP.png";
import ARROW_DOWN from "assets/vote/ARROW_DOWN.png";

const Accordion = ({ title, content, index }) => {
  const [isActive, setIsActive] = useState(false);
  const clickAccordionHandler = () => {
    setIsActive((prevState) => !prevState);
  };

  return (
    <div className="accordion-item text-white">
      <div
        className="flex justify-center items-center pl-30px accordion-title arrow"
        onClick={clickAccordionHandler}
      >
        {title}{" "}
        {isActive ? (
          <img src={ARROW_UP} alt="" />
        ) : (
          <img src={ARROW_DOWN} alt="" />
        )}
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
      {+index === 3 ? (
        ""
      ) : +index % 2 !== 0 ? (
        <svg
          width="100%"
          height="38"
          viewBox="0 0 949 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M947.768 20.7678C948.744 19.7915 948.744 18.2085 947.768 17.2322L931.858 1.32233C930.882 0.34602 929.299 0.34602 928.322 1.32233C927.346 2.29864 927.346 3.88155 928.322 4.85786L942.464 19L928.322 33.1421C927.346 34.1184 927.346 35.7014 928.322 36.6777C929.299 37.654 930.882 37.654 931.858 36.6777L947.768 20.7678ZM0 21.5H946V16.5H0V21.5Z"
            fill="#FFE000"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="38"
          viewBox="0 0 952 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.23224 17.2322C0.25592 18.2085 0.25592 19.7915 1.23224 20.7678L17.1422 36.6777C18.1185 37.654 19.7014 37.654 20.6777 36.6777C21.654 35.7014 21.654 34.1184 20.6777 33.1421L6.53552 19L20.6777 4.85786C21.654 3.88155 21.654 2.29864 20.6777 1.32233C19.7014 0.34602 18.1185 0.34602 17.1422 1.32233L1.23224 17.2322ZM952 16.5H3V21.5H952V16.5Z"
            fill="#FFE000"
          />
        </svg>
      )}
    </div>
  );
};
export default Accordion;
