import { SYSTEM_ERROR, GAME_ID } from "../../config/CONSTANTS";
import axios from "axios";
import { GET_GAME_INFO } from "./CONSTANTS";

/**
 * Function to vote.
 */
export const getGameInfo = (userId, playerId, shareOn) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(GET_GAME_INFO(), {
          gameId: GAME_ID,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getGameInfo > axios err=", err);
          reject("Error in getGameInfo axios!");
        });
    } catch (error) {
      console.error("in userServices > getGameInfo, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
