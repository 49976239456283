import { useContext } from "react";
import { SuperpowersGameInfoContext } from "store/contex";
import styled from "styled-components";

const BackgroundStyle = styled.div`
  background-image: ${(props) => `url(${props.img})`};
  /* margin-top: 60px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  &::before {
    content: "";
    display: block;
    padding-bottom: 60.8vw;
  }
  @media (max-width: 640px) {
    &::before {
      content: "";
      display: block;
      padding-bottom: 185vw;
    }
  }
`;

export const Hero = () => {
  const { gameInfo } = useContext(SuperpowersGameInfoContext);
  return (
    <>
      <picture>
        <BackgroundStyle
          img={
            gameInfo &&
            (window.innerWidth >= 640
              ? gameInfo.setPictures.background_1_D
              : gameInfo.setPictures.background_1_M)
          }
        >
          {/* <BackgroundPicture
              src={gameInfo && gameInfo.setPictures.background_1_D}
              alt="2023 Meteor超級影音大賽"
            /> */}
          {/* <div className="relative w-[100%] m-auto sm:pb-[48.28%] pb-[121.3%]"> */}
          {/* <BackgroundStyle
              className=" relative "
              img={gameInfo && gameInfo.setPictures.background_1_D}
            ></BackgroundStyle> */}
          {/* <img
              src={window.innerWidth >= 640 ? hero_bg_2_D : hero_bg_2_M}
              alt="2023 Meteor超級影音大賽背景黃線"
              className="absolute top-[0] right-[0]  sm:h-full h-[63%] translate-x-[40%]"
            /> */}
          {/* <img
              src={hero_yellow_D}
              alt="2023 Meteor超級影音大賽黃色"
              className="absolute sm:top-[47.44%] sm:right-[7%] right-[0] top-[30%] sm:w-[30.8%] w-[45.99%] animate-[zoomIn_2s_cubic-bezier(0.68,-0.55,0.27,1.55)_infinite]"
            /> */}
          {/* [zoomIn_2s_cubic-bezier(0.68,-0.55,0.27,1.55)_infinite] */}
          {/* <img
              src={hero_blue_1_D}
              alt="2023 Meteor超級影音大賽藍色一"
              className="absolute sm:top-[11.5%] sm:right-[1%] top-[4%] -right-[11%] sm:w-[28.5%] w-[42.557%] animate-[zoomIn_2s_cubic-bezier(0.68,-0.55,0.27,1.55)_infinite]"
            />
            <img
              src={hero_red_1_D}
              alt="2023 Meteor超級影音大賽紅色一"
              className="absolute sm:top-[14.1%] sm:right-[21.1%] top-[6%] right-[18%] sm:w-[36.25%] w-[54.13%] animate-[zoomIn_2s_cubic-bezier(0.68,-0.55,0.27,1.55)_infinite]"
            />
            <img
              src={hero_red_2_D}
              alt="2023 Meteor超級影音大賽紅色二"
              className="absolute sm:bottom-[0] sm:left-[0] sm:w-[35.2%] w-[56%] bottom-[0] left-[0] translate-y-[59%] -translate-x-[50%] animate-[zoomInToLeftBottom_2s_cubic-bezier(0.68,-0.55,0.27,1.55)_infinite]"
            /> */}
          {/* animate-[zoomInToLeftBottom_2s_cubic-bezier(0.68,-0.55,0.27,1.55)_infinite] */}
          {/* <img
              src={hero_star_D}
              alt="2023 Meteor超級影音大賽星星一"
              className="absolute sm:top-[15.5%] sm:right-[10%] sm:w-[4%] w-[5.97%]  top-[8%] right-[4%] animate-[rightAndLeft-1_2s_cubic-bezier(0,0,0,0)_infinite]"
            />
            <img
              src={hero_star_D}
              alt="2023 Meteor超級影音大賽星星二"
              className="absolute sm:bottom-[-1%] sm:left-[8%] bottom-[-2.5%] sm:w-[2.57%] w-[6%] left-[13%] rotate-[40deg] animate-[rightAndLeft-2_1s_cubic-bezier(0,0,0,0)_infinite]"
            /> */}
          {/* animate-[rightAndLeft-2_1s_cubic-bezier(0,0,0,0)_infinite] */}
          {/* 桌機板 */}
          {/* <img
              src={hero_title_D}
              alt="2023 Meteor超級影音大賽標題"
              className="absolute top-[33.8%] left-[11.5%] w-[26.9%] sm:block hidden"
            /> */}
          {/* 手機板 */}
          {/* <img
              src={hero_title_M_1}
              alt="2023 Meteor超級影音大賽標題"
              className="absolute top-[65%] left-[11.5%] w-[40.8%] sm:hidden "
            />
            <img
              src={hero_title_M_2}
              alt="2023 Meteor超級影音大賽日期"
              className="absolute bottom-[1.5%] right-[11.5%] w-[38.4%] sm:hidden"
            /> */}
          {/* <img
              src={hero_yellow_D}
              alt="2023 Meteor超級影音大賽黃色"
              className="absolute top-[10%] right-[0] w-[30.8%]"
            /> */}
          {/* </div> */}
        </BackgroundStyle>
      </picture>
    </>
  );
};
