/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to 
 * keep your app working for UI changes and 
 * make it independent of network requirements.
 * 
 * They need to be categorised and grouped together as:
 *  - Actual endpoints url.
 *  - Local data .json file path.
 * At a moment only one group should be uncommented.
 * 
 * Other way to deal with this is to name every json file as per your service endpoint and use a basepath variable.
 * Toggle this basePath variable between "actual-domain.com/" or "/data/".
 */

// Get game info
export const GET_GAME_INFO = () => `https://meteor.today/gameelection/get_game_info`;
// 候選人
export const GET_ALL_CANDIDATES = () => `https://meteor.today/gameelection/get_players_and_voted_list`;
export const GET_CANDIDATES_DETAILS = () => `https://meteor.today/gameelection/get_my_player_info`;
export const GET_CANDIDATES_MODAL = () => `https://meteor.today/gameelection/get_player_modal_info`;
// 上傳圖片
export const UPLOAD_IMAGE = () => `https://meteor.today/upload/image`;
// 報名和更新
export const SING_UP_FORM = () => `https://meteor.today/gameelection/register_or_update`;
// 投票
export const VOTE = () => `https://meteor.today/gameelection/add_vote`;
// 社群分享記錄
export const LOG_SHARE = () => `https://meteor.today/gameelection/log_share`;

