// import { HomePage } from "pages";
import { Routes, Route, Navigate } from "react-router-dom";
import { PlaygroundRoutes, VoteRoutes } from "./routes";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/vote/beautyCampaign2023"/>} />
        <Route
          path="2022handsomeboy"
          element={<Navigate to="/vote/2022handsomeboy" replace={true} />}
        />
        <Route path="vote/*" element={<VoteRoutes />} />
        <Route path="playground/*" element={<PlaygroundRoutes />} />
      </Routes>
    </>
  );
}

export default App;
