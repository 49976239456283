import { GAME_ID } from "config/CONSTANTS";
import pingIcon from "assets/vote/meteor_campaign_PIN-03.png";
import styled from "styled-components";
import gtag from "ga-gtag";

const SignUpStyle = styled.div`
  position: fixed;
  right: 0;
  top: 60%;
  cursor: pointer;
  z-index: 999;
  width: 180px;
  @media (max-width: 510px) {
    width: 140px;
  }
`;
const SignUpButton = (props) => {
  const { singUpModalHandler, userSession } = props;

  const singUpClickHandler = () => {
    if (userSession) {
      // ga4 api
      gtag("event", "點擊報名-浮動icon", {
        gameId: GAME_ID,
        userSession: userSession,
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-浮動icon",
        gameId: GAME_ID,
        userSession: userSession,
      });

      const data = {
        userId: userSession,
        gameId: GAME_ID,
      };
      fetch("https://meteor.today/gameelection/get_my_player_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            alert("您已經報名囉");
            return;
          } else {
            singUpModalHandler(true);
          }
        });
    } else if (!userSession) {
      // ga4 api
      gtag("event", "點擊報名-浮動icon", {
        gameId: GAME_ID,
        userSession: "未登入",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-浮動icon",
        gameId: GAME_ID,
        userSession: "未登入",
      });

      alert("請先登入");
      window.location.href =
        "https://meteor.today/login?next=https://game.meteor.today/2022handsomeboy";
      return;
    } else {
      singUpModalHandler(true);
    }
  };

  return (
    <SignUpStyle>
      <img onClick={singUpClickHandler} src={pingIcon} alt="2022Meteor" />
    </SignUpStyle>
  );
};

export default SignUpButton;
