import { useContext } from "react";
import { Girl2023InfoContext } from "store/contex";
import styled from "styled-components";
import { pump } from "assets/styles/animation/pump"
import eggB from "assets/vote/2023girl/DESKTOP/eggB.png"
import eggO from "assets/vote/2023girl/DESKTOP/eggO.png"
import eggDB from "assets/vote/2023girl/DESKTOP/eggDB.png"
import eggY from "assets/vote/2023girl/DESKTOP/eggY.png"
import eggG from "assets/vote/2023girl/DESKTOP/eggG.png"

const BackgroundStyle = styled.div`
  display: block;
  background-image: ${(props) => `url(${props.img})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  height: 53vw;
  @media (max-width: 640px) {
    height: 177.6vw;
  }
`;
const EggBlue = styled.img`
  top: 14.6%;
  left: 46%;
  width: 8.7vw;
  ${pump(50, 1.5, 0.8, 'blue', 'rotate(14.254deg)')}
  @media (max-width: 640px) {
    width:19vw;
    left: 72vw;
    top: 12%;
  }
`;
const EggDeepBlue = styled.img`
  bottom: 25.1%;
  right: -1.5%;
  width: 8vw;
  ${pump(30, 1.2, 1.1, 'deepblue')}
  @media (max-width: 640px) {
    display: none;
  }
`;

const EggOrange = styled.img`
  top: 13.3%;
  right: 0%;
  width: 14vw;
  ${pump(70, 1, 0.85, 'orange')}
  @media (max-width: 640px) {
    width: 31.5vw;
    right: -4%;
    top: 40%;
  }
`;

const EggYellow = styled.img`
  bottom: 14%;
  right: 25.5%;
  width: 10vw;
  ${pump(80, 1.1, 0.85, 'yellow', 'rotate(0deg)')}
  @media (max-width: 640px) {
    bottom: 3%;
    right: 38.5%;
    width: 20.8vw;
  }
`;
const EggGreen = styled.img`
  display: none;
  ${pump(80, 1.1, 0.85, 'green')}
  @media (max-width: 640px) {
    display: block;
    bottom: 30%;
    left: 0%;
    width: 30vw;
  }
`;

export const Hero = () => {
  const { gameInfo } = useContext(Girl2023InfoContext);
  return (
    <>
      <picture>
        <BackgroundStyle
          img={
            gameInfo &&
            (window.innerWidth >= 640
              ? gameInfo.setPictures.background_1_D
              : gameInfo.setPictures.background_1_M)
          }
          className="relative"
        >
          <EggBlue
              src={eggB}
              alt="2023 Meteor校園女神選拔賽背景藍蛋"
            />
          <EggOrange
              src={eggO}
              alt="2023 Meteor校園女神選拔賽背景橘蛋"
            />
          <EggDeepBlue
              src={eggDB}
              alt="2023 Meteor校園女神選拔賽背景深藍蛋"
            />
          <EggYellow
            src={eggY}
            alt="2023 Meteor校園女神選拔賽背景黃蛋"
          />
          <EggGreen
            src={eggG}
            alt="2023 Meteor校園女神選拔賽背景綠蛋"
          />
        </BackgroundStyle>
      </picture>
    </>
  );
};
