import { Helmet } from "react-helmet-async";

const Meta = ({ title, description, meta = [] }) => {
  return (
    <Helmet>
      <title>2023 Meteor校園女神選拔賽</title>
      <meta
        name="description"
        content="惹不起的高校女神第三屆,Meteor,米特論壇,學生社群,米特學生社群,高中生,高職生,高校生,大學生,國中生,學校,校園,JK,制服,全部看板,熱門,惹不起的,高校女神,惹不起的校園女神,校園女神"
      />
      <meta
        name="Meteor"
        content="Meteor,米特論壇,學生社群,米特學生社群,高中生,高職生,高校生,大學生,國中生,學校,校園,JK,制服,全部看板,熱門"
      />
      <meta
        name="twitter:card"
        content="https://storage.meteor.today/image/650bfc301cbaa86a6f78446f.png"
      />
      <meta name="twitter:site" content="https://game.meteor.today/" />
      <meta name="twitter:creator" content="meteor" />

      <meta name="twitter:title" content="2023 Meteor校園女神選拔賽" />
      <meta name="twitter:label1" content="" />
      <meta name="twitter:data1" content="" />
      <meta name="twitter:image" content="https://storage.meteor.today/image/650bfc301cbaa86a6f78446f.png"
    />
      <meta
        name="og:description"
        content="惹不起的高校女神第三屆,Meteor,米特論壇,學生社群,米特學生社群,高中生,高職生,高校生,大學生,國中生,學校,校園,JK,制服,全部看板,熱門,惹不起的,高校女神,惹不起的校園女神,校園女神"
      />
      <meta property="og:title" content="2023 Meteor校園女神選拔賽" />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="hhttps://game.meteor.today/vote/beautyCampaign2023"
      />
      <meta
        property="og:image"
        content="https://storage.meteor.today/image/650bfc301cbaa86a6f78446f.png"
      />
      <meta
        property="og:site_name"
        content="Meteor米特 [https://game.meteor.today/vote/beautyCampaign2023]"
      />
      <meta property="fb:app_id" content="1717255128492433" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes" />
      <meta name="HandheldFriendly" content="true" />
    </Helmet>
  );
};

export default Meta;
