import { useContext } from "react";
import { UniformGameInfoContext } from "store/contex";
import prizeDesktop1 from "assets/vote/2022uniform/DESKTOP/1st_D.png";
import prizeDesktop2 from "assets/vote/2022uniform/DESKTOP/2nd_D.png";
import prizeDesktop3 from "assets/vote/2022uniform/DESKTOP/third_D.png";
import prizeMobile1 from "assets/vote/2022uniform/MOBILE/1st_M.png";
import prizeMobile2 from "assets/vote/2022uniform/MOBILE/2nd_M.png";
import prizeMobile3 from "assets/vote/2022uniform/MOBILE/third_M.png";

const WinPrize = ({
  winnerPlayers,
  userId,
  candidatesModalHandler,
  setModalData,
  candidate,
  clickModalHandler,
}) => {
  const { gameInfo } = useContext(UniformGameInfoContext);
  const winPrizeIndex = winnerPlayers
    .map(({ playerId }) => playerId)
    .indexOf(userId);
  return (
    <>
      {winPrizeIndex >= 0 && (
        <>
          <div
            onClick={() => {
              candidatesModalHandler();
              setModalData(candidate);
              clickModalHandler(candidate.playerId);
            }}
            className="absolute md:w-[43.155%] cursor-pointer md:-bottom-[4.46%] md:-left-[11.31%] w-[54.225%] -left-[13.38%] -bottom-[3.52%]"
          >
            {winPrizeIndex + 1 === 1 && (
              <img
                src={window.innerWidth > 600 ? prizeDesktop1 : prizeMobile1}
                alt={winPrizeIndex + 1}
              />
            )}
            {winPrizeIndex + 1 === 2 && (
              <img
                src={window.innerWidth > 600 ? prizeDesktop2 : prizeMobile2}
                alt={winPrizeIndex + 1}
              />
            )}
            {winPrizeIndex + 1 === 3 && (
              <img
                src={window.innerWidth > 600 ? prizeDesktop3 : prizeMobile3}
                alt={winPrizeIndex + 1}
              />
            )}
          </div>
          {/* <div className="absolute bottom-50px left-10px text-black-1 font-bold text-18px">
        第{winPrizeIndex + 1}名
      </div> */}
          {/* {window.innerWidth > 600 ? (
            <BrowserWinPrize
              winPrizeIndex={winPrizeIndex}
              gameInfo={gameInfo}
              candidatesModalHandler={candidatesModalHandler}
              setModalData={setModalData}
              candidate={candidate}
              clickModalHandler={clickModalHandler}
            />
          ) : (
            <MobileWinPrize winPrizeIndex={winPrizeIndex}
            gameInfo={gameInfo}
            candidatesModalHandler={candidatesModalHandler}
            setModalData={setModalData}
            candidate={candidate}
            clickModalHandler={clickModalHandler} />
          )} */}
        </>
      )}
    </>
  );
};

export default WinPrize;
