import closeIcon from "assets/vote/2023girl/DESKTOP/close.png";

const CloseButton = ({ handleClose, fill, size }) => {
  return (
    <>
      <button
        onClick={handleClose}
        className="z-30 w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] absolute right-[8px] top-[8px] sm:right-[12px] sm:top-[12px]"
      >
        <img src={closeIcon} alt="closeIcon"/>
      </button>
    </>
  );
};
export default CloseButton;
