const VoteButton = (props) => {
    const {voteHandler} = props
    return (
        <>
        <button onClick={voteHandler} className='flex font-normal text-base py-6px px-12px bg-blue-1 rounded-22px'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.1598 4.61006C19.0981 3.54806 17.6905 2.90238 16.193 2.79044C14.6955 2.67849 13.2076 3.10772 11.9998 4.00006C10.7274 3.0537 9.14378 2.62457 7.56771 2.7991C5.99164 2.97362 4.54023 3.73884 3.50576 4.94064C2.47129 6.14245 1.93061 7.69158 1.99259 9.27607C2.05457 10.8606 2.71461 12.3627 3.83979 13.4801L11.2898 20.9301C11.3828 21.0238 11.4934 21.0982 11.6152 21.1489C11.7371 21.1997 11.8678 21.2259 11.9998 21.2259C12.1318 21.2259 12.2625 21.1997 12.3844 21.1489C12.5062 21.0982 12.6168 21.0238 12.7098 20.9301L20.1598 13.4801C20.7424 12.8978 21.2047 12.2064 21.52 11.4454C21.8354 10.6844 21.9977 9.86879 21.9977 9.04506C21.9977 8.22133 21.8354 7.40567 21.52 6.64469C21.2047 5.88371 20.7424 5.19233 20.1598 4.61006ZM18.7498 12.0701L11.9998 18.8101L5.24979 12.0701C4.65496 11.4728 4.24976 10.7131 4.08502 9.8864C3.92027 9.05969 4.00332 8.20274 4.32374 7.42305C4.64416 6.64336 5.18768 5.97565 5.88613 5.50368C6.58458 5.03171 7.40685 4.7765 8.24979 4.77006C9.3759 4.77282 10.4549 5.22239 11.2498 6.02006C11.3428 6.11379 11.4534 6.18818 11.5752 6.23895C11.6971 6.28972 11.8278 6.31586 11.9598 6.31586C12.0918 6.31586 12.2225 6.28972 12.3444 6.23895C12.4662 6.18818 12.5768 6.11379 12.6698 6.02006C13.4881 5.31094 14.5453 4.9392 15.6273 4.98008C16.7094 5.02096 17.7355 5.47141 18.498 6.24025C19.2605 7.00909 19.7024 8.0389 19.7343 9.12125C19.7662 10.2036 19.3857 11.2576 18.6698 12.0701H18.7498Z" fill="white"/>
            </svg>
            <span className='ml-1 md:text-16px text-14px text-white'>投他一票</span>
        </button>
        </>
    )
}

export default VoteButton