import { Route, Routes, Navigate } from "react-router-dom";
import {
  Handsomeboy2022Page,
  Highschooluniform2022Page,
  Superpowers,
  Girl2023
} from "../pages";

const VoteRoutes = () => {
  return (
    <Routes>
      <Route
        index element=
        {<Navigate to="beautyCampaign2023"/>}
      />
      <Route
        path="2022handsomeboy"
        element=
        { <Handsomeboy2022Page /> }
      />
      <Route
        path="2022highschooluniform"
        element=
        { <Highschooluniform2022Page /> }
      />
      <Route
        path="2023superpowers"
        element=
        { <Superpowers /> }
      />
      <Route
        path="beautyCampaign2023"
        element=
        { <Girl2023 /> }
      />
    </Routes>
  );
};
export default VoteRoutes;
