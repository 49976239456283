import React from "react";
import logoImg from "assets/vote/2023girl/DESKTOP/Meteor_LOGO.png";
import { METEORURL } from "config/CONSTANTS";

const Logo = () => {
  return (
    <div className="flex">
      <a className="text-black" href={`${METEORURL}/board/all?utm_source=beautyCampaign2023`}>
        <img 
          src={logoImg} 
          alt="米特logo" 
          className="w-[32px] sm:w-[36px]" />
      </a>
    </div>
  );
};

export default Logo;
