import styled from "styled-components";

const NavBarTab = styled.li`
  margin-left: 24px;
  color: #383EFF;
  font-size: 24px;
  font-weight: 800;
  cursor: pointer;
  &:hover, &:active {
    color: #FF55B3;
    transition: color 0.2s ease-out;
  }
  @media (max-width: 640px) {
    margin-left: 0px;
    color: #FFF;
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 12px;
    &:hover, &:active {
      color: #E9F800;
      transition: color 0.2s ease-out;
    }
  }
`;

export default NavBarTab;
