import { SYSTEM_ERROR, GAME_ID } from "../../config/CONSTANTS";
import axios from "axios";
import { GET_CANDIDATES_DETAILS } from "./CONSTANTS";

/**
 * Function to fetch all the Candidates Detail.
 */
export const getCandidatesDetails = (userId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(GET_CANDIDATES_DETAILS(), {
          userId: userId,
          gameId: GAME_ID,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getCandidatesDetails > axios err=", err);
          reject(err.response.data);
          // reject("Error in getCandidatesDetails axios!");
        });
    } catch (error) {
      console.error("in userServices > getCandidatesDetails, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
