import { useState, useEffect } from "react";
import { GAME_ID } from "config/CONSTANTS";
import { getCandidatesDetails } from "services/vote";
import { uploadImage, singUpForm } from "services/vote";
import { useForm } from "react-hook-form";
import Modal from "components/vote/Modal";
import Alert from "components/vote/Alert";
import CloseButton from "components/vote/CloseButton";

const MyProfile = (props) => {
  const { userSession, setToggleMyProfileModal } = props;

  const [profile, setProfile] = useState(null);
  const [judgeStatusText, setJudgeStatusText] = useState([]);
  const [uploadImageSize, setUploadImageSize] = useState(false);
  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);
  const [image3, setImage3] = useState([]);
  const [image1Url, setImage1Url] = useState("");
  const [image2Url, setImage2Url] = useState("");
  const [image3Url, setImage3Url] = useState("");
  const [singUpErrorMessage, setSingUpErrorMessage] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    getCandidatesDetails(userSession)
      .then((res) => {
        const { player } = res;

        setProfile(player);

        switch (player.judgeStatus) {
          case "accepted":
            setJudgeStatusText(["通過審核", true]);
            break;
          case "judging":
            setJudgeStatusText(["等待審核", true]);
            break;
          case "rejected":
            setJudgeStatusText(["請重新提交報名資料", false]);
            break;
          case "ban":
            setJudgeStatusText(["禁止報名", true]);
            break;
          default:
            break;
        }
        setValue("nickname", player.nickname);
        setValue("email", player.email);
        setValue("weight", player.info["體重"]);
        setValue("height", player.info["身高"]);
        setValue("hobby", player.info["興趣專長"]);
        setValue("phone", player.phone);
        setValue("intro", player.intro);
        setValue("relation", player.info["感情狀況"]);
        setValue("socialLinks", player.socialLinks.socialLinks);
        setValue("picture", player.playerPhotos[0]);
        setValue("picture2", player.playerPhotos[1]);
        setValue("picture3", player.playerPhotos[2]);

        setImage1Url(player.playerPhotos[0]);
        setImage2Url(player.playerPhotos[1]);
        setImage3Url(player.playerPhotos[2]);
      })
      .catch((err) => {
        if (err) {
          alert(err.error);
          setToggleMyProfileModal(false);
          return;
        }
      });
    return () => {
      console.log("axios cleanup.");
    };
  }, [userSession, setValue, setToggleMyProfileModal]);

  const uploadImageModalHandler = () => {
    setUploadImageSize((preState) => !preState);
  };

  const uploadImg = async (e, type) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    if (file) {
      if (file.size > 3000000) {
        setUploadImageSize(true);
        setSingUpErrorMessage([
          "上傳圖片檔案過大",
          "抱歉上傳圖片不可超過 3MB 喔，請重新上傳檔案，謝謝!",
          false,
        ]);
        return false;
      }
      switch (type) {
        case "image1":
          // for 縮圖顯示
          const image1 = await readFileAsync(file);
          setImage1([image1]);
          // for 縮圖顯示 end
          uploadImage(formData)
            .then((res) => {
              setImage1Url(res.result);
            })
            .catch((err) => {
              console.log("axios err=", err);
            });
          break;
        case "image2":
          const image2 = await readFileAsync(file);
          setImage2([image2]);

          uploadImage(formData)
            .then((res) => {
              setImage2Url(res.result);
            })
            .catch((err) => {
              console.log("axios err=", err);
            });
          break;
        case "image3":
          const image3 = await readFileAsync(file);
          setImage3([image3]);

          uploadImage(formData)
            .then((res) => {
              setImage3Url(res.result);
            })
            .catch((err) => {
              console.log("axios err=", err);
            });
          break;
        default:
          break;
      }
    }
  };
  const readFileAsync = (e) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        generateFromImage(reader.result, 300, 300, 1, (imgdata) => {
          resolve({
            id: performance.now(),
            url: imgdata,
            type: "image",
          });
        });
      };
      reader.onerror = reject;
      reader.readAsDataURL(e);
    });
  };

  const generateFromImage = (
    img,
    MAX_WIDTH = 300,
    MAX_HEIGHT = 300,
    quality = 1,
    callback
  ) => {
    let canvas = document.createElement("canvas");
    let image = new Image();

    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL("image/jpeg", quality);
      callback(dataUrl);
    };
    image.src = img;
  };

  const onSubmit = (data) => {
    const {
      email,
      nickname,
      weight,
      height,
      hobby,
      phone,
      intro,
      relation,
      socialLinks,
    } = data;
    const gameId = GAME_ID;
    const playerPhotos = [image1Url, image2Url, image3Url];

    const userId = userSession;

    const singUpFormData = {
      gameId,
      userId,
      nickname,
      email,
      phone,
      intro,
      playerPhotos,
      socialLinks: { instagram: socialLinks },
      info: {
        身高: height,
        體重: weight,
        興趣專長: hobby,
        感情狀況: relation,
      },
    };

    singUpForm(singUpFormData)
      .then((res) => {
        if (res.playerId) {
          alert("更新成功", "請等候米特管理員審核喔，謝謝!");
          setToggleMyProfileModal(false);
          return;
        }
      })
      .catch((err) => {
        if (err) {
          setUploadImageSize(true);
          setSingUpErrorMessage([
            "更新失敗",
            err.error + "，若有任何疑問請聯絡官方人員，謝謝!",
            false,
          ]);
          return;
        }
      });
  };

  return (
    <>
      {profile && (
        <div className="relative bg-white max-w-xl flex flex-col items-center justify-center rounded-lg py-12 px-4 sm:px-6 lg:px-8 sm:m-0 m-4">
          <svg
            className="absolute top-N18px"
            width="195"
            height="52"
            viewBox="0 0 195 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="2.37598"
              width="192.602"
              height="40.4439"
              transform="rotate(3.36818 2.37598 0)"
              fill="#FFE000"
              fillOpacity="0.86"
            />
          </svg>
          <CloseButton
            size="50"
            fill={"#282828"}
            handleClose={() => setToggleMyProfileModal(false)}
          />

          <div className="w-full overflow-y-auto h-600px">
            <div className="flex flex-col justify-center mb-10">
              <div className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Meteor2022 校園男神選拔
              </div>
              <div className="mt-4 text-center text-base font-extrabold">
                報名狀態 -{">"} {profile && judgeStatusText[0]}
              </div>
            </div>
            <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <label
                  htmlFor="nickname"
                  className="block text-gray-700 text-base font-bold mb-2"
                >
                  <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                    暱稱/英文名 (票選活動使用)
                  </span>
                </label>
                <input
                  id="nickname"
                  name="nickname"
                  type="text"
                  autoComplete="nickname"
                  className={`appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base ${
                          judgeStatusText[1] ? "bg-gray-2" : ""
                        }`}
                  placeholder="暱稱最長8個字"
                  {...register("nickname", {
                    disabled: judgeStatusText[1],
                    required: true,
                    maxLength: 8,
                  })}
                />
                {errors.nickname && (
                  <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                    請輸入暱稱
                  </small>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email-address"
                  className="block text-gray-700 text-base font-bold mb-2"
                >
                  <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                    信箱
                  </span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className={`appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base ${
                          judgeStatusText[1] ? "bg-gray-2" : ""
                        }`}
                  placeholder="請輸入電子信箱"
                  {...register("email", {
                    disabled: judgeStatusText[1],
                    required: true,
                  })}
                />
                {errors.email && (
                  <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                    請輸入電子信箱
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="height"
                  className="block text-gray-700 text-base font-bold mb-2"
                >
                  <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                    身高
                  </span>
                </label>
                <input
                  id="height"
                  name="height"
                  type="number"
                  autoComplete="height"
                  className={`appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base ${
                          judgeStatusText[1] ? "bg-gray-2" : ""
                        }`}
                  placeholder="180"
                  {...register("height", {
                    disabled: judgeStatusText[1],
                    required: true,
                    min: 1,
                  })}
                />
                {errors.height && (
                  <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                    請輸入身高
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="weight"
                  className="block text-gray-700 text-base font-bold mb-2"
                >
                  <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                    體重
                  </span>
                </label>
                <input
                  id="weight"
                  name="weight"
                  type="number"
                  autoComplete="weight"
                  className={`appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base ${
                          judgeStatusText[1] ? "bg-gray-2" : ""
                        }`}
                  placeholder="50"
                  {...register("weight", {
                    disabled: judgeStatusText[1],
                    required: true,
                    min: 1,
                  })}
                />
                {errors.height && (
                  <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                    請輸入體重
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="hobby"
                  className="block text-gray-700 text-base font-bold mb-2"
                >
                  <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                    興趣
                  </span>
                </label>
                <input
                  id="hobby"
                  name="hobby"
                  type="text"
                  autoComplete="hobby"
                  className={`appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base ${
                          judgeStatusText[1] ? "bg-gray-2" : ""
                        }`}
                  placeholder="唱歌/旅遊/拍照"
                  {...register("hobby", {
                    disabled: judgeStatusText[1],
                    required: true,
                  })}
                />
                {errors.hobby && (
                  <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                    請輸入興趣
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="relation"
                  className="block text-gray-700 text-base font-bold mb-2"
                >
                  <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                    感情狀況
                  </span>
                </label>
                <input
                  id="relation"
                  name="relation"
                  type="text"
                  autoComplete="relation"
                  className={`appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base ${
                          judgeStatusText[1] ? "bg-gray-2" : ""
                        }`}
                  placeholder="單身"
                  {...register("relation", {
                    disabled: judgeStatusText[1],
                    required: true,
                  })}
                />
                {errors.relation && (
                  <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                    請輸入感情狀況
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-gray-700 text-base font-bold mb-2"
                >
                  <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                    連絡電話(僅作活動得獎聯繫之用)
                  </span>
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="number"
                  autoComplete="phone"
                  className={`appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base ${
                          judgeStatusText[1] ? "bg-gray-2" : ""
                        }`}
                  {...register("phone", {
                    disabled: judgeStatusText[1],
                    required: true,
                    min: 1,
                  })}
                />
                {errors.phone && (
                  <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                    請輸入聯絡電話
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="socialLinks"
                  className="block text-gray-700 text-base font-bold mb-2"
                >
                  Instagram 帳號 (選填)
                </label>
                <input
                  id="socialLinks"
                  name="socialLinks"
                  type="text"
                  autoComplete="socialLinks"
                  className={`appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base ${
                          judgeStatusText[1] ? "bg-gray-2" : ""
                        }`}
                  placeholder="範例: meteor.tw"
                  {...register("socialLinks")}
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="intro"
                  className="block text-gray-700 text-base font-bold mb-2"
                >
                  <span className="before:content-['*'] before:ml-0.5 before:text-red-500">
                    自我介紹
                  </span>
                </label>
                <textarea
                  id="intro"
                  name="intro"
                  type="text"
                  autoComplete="intro"
                  className={`appearance-none relative block
                        w-full px-3 py-2 border border-gray-300
                        placeholder-gray-500 text-gray-900 rounded-30px
                        focus:outline-none focus:ring-indigo-500
                        focus:border-indigo-500 focus:z-10 text-base ${
                          judgeStatusText[1] ? "bg-gray-2" : ""
                        }`}
                  rows="4"
                  placeholder="字數限制100字"
                  {...register("intro", {
                    disabled: judgeStatusText[1],
                    required: true,
                    maxLength: 100,
                  })}
                ></textarea>
                {errors.intro && (
                  <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                    請輸入自我介紹 or 不可超過字數限制
                  </small>
                )}
              </div>

              <div className="flex justify-center bg-grey-lighter mb-4">
                <label
                  className={`w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-1 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer ${
                    judgeStatusText[1] ? "hover:bg-gray-2" : "hover:bg-blue-2"
                  } hover:text-white`}
                >
                  <svg
                    className="w-8 h-8"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                  </svg>
                  <span className="mt-2 text-base leading-normal">
                    {image1 ? "重新上傳" : "上傳正面半身照片"}
                  </span>
                  <span className="block text-xs text-red-500">
                    請上傳正方形(1:1)照片
                  </span>
                  <input
                    {...register("picture", { disabled: judgeStatusText[1] })}
                    className="hidden block w-full text-sm text-slate-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-sm file:font-semibold
                                file:bg-violet-50 file:text-violet-700
                                hover:file:bg-violet-100"
                    type="file"
                    name="picture"
                    onChange={(e) => uploadImg(e, "image1")}
                  />
                </label>
              </div>
              {errors.picture && (
                <div className="flex justify-center relative left-12px top-N18px text-red-500 text-xs italic">
                  請上傳正面半身照片
                </div>
              )}
              {image1Url && (
                <img className="mx-auto w-auto h-full" src={image1Url} alt="" />
              )}

              <div className="flex justify-center bg-grey-lighter mb-4">
                <label
                  className={`w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-1 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer ${
                    judgeStatusText[1] ? "hover:bg-gray-2" : "hover:bg-blue-2"
                  } hover:text-white`}
                >
                  <svg
                    className="w-8 h-8"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                  </svg>
                  <span className="mt-2 text-base leading-normal">
                    {image2 ? "重新上傳生活照1" : "上傳生活照1"}
                  </span>
                  <span className="block text-xs text-red-500">
                    請上傳正方形(1:1)照片
                  </span>
                  <input
                    {...register("picture1", { disabled: judgeStatusText[1] })}
                    className="hidden block w-full text-sm text-slate-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-sm file:font-semibold
                                file:bg-violet-50 file:text-violet-700
                                hover:file:bg-violet-100"
                    type="file"
                    name="picture1"
                    onChange={(e) => uploadImg(e, "image2")}
                  />
                </label>
              </div>
              {image2Url && (
                <img className="mx-auto w-auto h-full" src={image2Url} alt="" />
              )}

              <div className="flex justify-center bg-grey-lighter mb-4">
                <label
                  className={`w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-1 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer ${
                    judgeStatusText[1] ? "hover:bg-gray-2" : "hover:bg-blue-2"
                  } hover:text-white`}
                >
                  <svg
                    className="w-8 h-8"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                  </svg>
                  <span className="mt-2 text-base leading-normal">
                    {image3 ? "重新上傳" : "上傳生活照2"}
                  </span>
                  <span className="block text-xs text-red-500">
                    請上傳正方形(1:1)照片
                  </span>
                  <input
                    {...register("picture2", { disabled: judgeStatusText[1] })}
                    className="hidden block w-full text-sm text-slate-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-sm file:font-semibold
                                file:bg-violet-50 file:text-violet-700
                                hover:file:bg-violet-100"
                    type="file"
                    name="picture2"
                    onChange={(e) => uploadImg(e, "image3")}
                  />
                </label>
              </div>
              {image3Url && (
                <img className="mx-auto w-auto h-full" src={image3Url} alt="" />
              )}

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="agree"
                    name="agree"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500
                            border-gray-300 rounded"
                    defaultChecked
                    {...register("agree", { required: true })}
                  />
                  <label
                    className="ml-2 block text-sm text-gray-900"
                    htmlFor="agree"
                  >
                    我已閱讀
                    <a
                      className="text-orange-1"
                      target="_blank"
                      rel="noreferrer"
                      href="https://game.meteor.today/2022handsomeboy"
                    >
                      活動注意事項
                    </a>
                    ，並同意上傳照片後主辦單位擁有無償重製及公開播送之相關權利
                  </label>
                </div>
                {errors.agree && (
                  <div className="flex justify-center relative left-12px top-N18px text-red-500 text-xs italic">
                    請勾選注意事項
                  </div>
                )}
              </div>

              {!judgeStatusText[1] && (
                <div className="flex w-full justify-center">
                  <button
                    type="submit"
                    className="group relative w-3/5
                            py-2 px-4 border border-transparent text-sm font-medium
                            rounded-30px text-white bg-blue-1 hover:bg-indigo-700
                            focus:outline-none focus:ring-2 focus:ring-offset-2
                            focus:ring-indigo-500"
                  >
                    更新報名
                  </button>
                </div>
              )}
            </form>
            <div className="w-full text-sm mt-20px text-left">
              報名時遇到問題嗎？
              <a
                href="https://m.me/MeteorApp"
                className="text-orange-1"
                target="_blank"
                rel="noreferrer"
              >
                請聯絡粉絲專頁！
              </a>
            </div>
          </div>
        </div>
      )}

      <Modal
        handleClose={() => uploadImageModalHandler(false)}
        isOpen={uploadImageSize}
      >
        <Alert
          title={singUpErrorMessage[0]}
          content={singUpErrorMessage[1]}
          success={setSingUpErrorMessage[2]}
          uploadImageModalHandler={uploadImageModalHandler}
        />
      </Modal>
    </>
  );
};

export default MyProfile;
