// 各種定義常數
export const SYSTEM_ERROR = "系統錯誤，請稍候再嘗試！";
// 2022男神選舉 GAME_ID
export const GAME_ID = "62319a58b0ae7bcdd9619d1b";
// 2022制服選拔活動 GAME_ID
export const UNIFORM_GAME_ID = "63034a33cf48b6ea401a97bd";
// 2023影音活動 GAME_ID
export const SUPERPOWERS_GAME_ID = "6417fc477ec5dcdfebe507aa";
export const Girl_2023_ID = "6502bdc59779b5dbc8017d86";

// 本機測試Iframe vote
// export const METEORURL = 'http://localhost:1337';

export const METEORURL =
  window.location.origin ===
  "https://campaign-frontend-staging.batmobile.com.tw" || window.location.origin.includes('localhost:')
    ? "https://meteor-test.batmobile.com.tw"
    : "https://meteor.today";
