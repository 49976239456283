import { Route, Routes, Navigate } from "react-router-dom";
import { RoulettePage } from "pages";
const PlaygroundRouters = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="roulette" />} />
      <Route path="roulette" element={<RoulettePage />} />
    </Routes>
  );
};
export default PlaygroundRouters;
