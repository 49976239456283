import { Helmet } from "react-helmet-async";

import coverImage from "assets/vote/DESKTOP/METEOR_campaign_1_D.png";

const Meta = ({ title, description, meta = [] }) => {
  return (
    <Helmet>
      ‍<title>Meteor2022 高校男神選拔賽</title>‍
      <meta
        name="description"
        content="Meteor,米特論壇,學生社群,高中生,高職生,高校生,大學生,國中生,學校,校園,JK,JD,JC,全部看板,熱門"
      />
      <meta name="twitter:card" content={coverImage} />
      <meta name="twitter:site" content="https://game.meteor.today/" />
      <meta name="twitter:creator" content="meteor" />
      <meta name="twitter:title" content="Meteor2022 高校男神選拔賽" />
      <meta name="twitter:description" content="Best Products for your pet" />
      <meta name="twitter:image" content={coverImage} />
      <meta property="og:title" content="Meteor2022 高校男神選拔賽" />
      <meta
        property="og:description"
        content="Meteor,米特論壇,學生社群,高中生,高職生,高校生,大學生,國中生,學校,校園,JK,JD,JC,全部看板,熱門"
      />
      <meta property="og:image" content={coverImage} />
      <meta property="og:url" content="https://game.meteor.today/" />
      <meta property="og:site_name" content="Meteor2022 高校男神選拔賽" />
      <meta property="og:locale" content="zh_TW" />
      <meta property="og:type" content="site" />
      <meta property="fb:app_id" content="1717255128492433" />
    </Helmet>
  );
};

export default Meta;
