export const pump = (mid, sec, size, index, rotate = '') => {
  return `
  position:absolute;
  animation: pump${index} ${sec}s infinite linear;
  @keyframes pump${index} {
    0% {
      transform: scale(${size}) ${rotate}
    }
    ${mid}% {
      transform: scale(1) ${rotate}
    }
    100% {
      transform: scale(${size}) ${rotate}
    }
  }
  `
}
