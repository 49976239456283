import { useContext } from "react";
import Marquee from "react-fast-marquee";
import { GAME_ID } from "config/CONSTANTS";
import { GameInfoContext } from "store/contex";
import { BrowserView, MobileView } from "react-device-detect";
import styled from "styled-components";
import gtag from "ga-gtag";

const AbsoluteCenterD = styled.div`
  left: 50%;
  bottom: 60px;
  transform: translate(-50%, -50%);
`;
const AbsoluteCenterM = styled.div`
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const Process = (props) => {
  const { setToggleSignUpModal, userSession, registerDateOver } = props;
  const { gameInfo } = useContext(GameInfoContext);

  const singUpClickHandler = () => {
    if (userSession) {
      // ga4 api
      gtag("event", "點擊報名-跑馬燈", {
        gameId: GAME_ID,
        userSession: userSession,
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-跑馬燈",
        gameId: GAME_ID,
        userSession: userSession,
      });

      const data = {
        userId: userSession,
        gameId: GAME_ID,
      };
      fetch("https://meteor.today/gameelection/get_my_player_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            alert("您已經報名囉");
            return;
          } else {
            setToggleSignUpModal(true);
          }
        });
    } else if (!userSession) {
      // ga4 api
      gtag("event", "點擊報名-跑馬燈", {
        gameId: GAME_ID,
        userSession: "未登入",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-跑馬燈",
        gameId: GAME_ID,
        userSession: "未登入",
      });

      alert("請先登入");
      window.location.href =
        "https://meteor.today/login?next=https://game.meteor.today/2022handsomeboy";
      return;
    } else {
      setToggleSignUpModal(true);
    }
  };

  return (
    <>
      <BrowserView className="relative flex justify-center">
        <picture>
          <img
            src={gameInfo && gameInfo.setPictures.signUpIntro_D}
            alt="2022男神選拔報名流程"
          />
        </picture>

        <AbsoluteCenterD className="absolute bottom-0">
          {registerDateOver && (
            <button
              onClick={singUpClickHandler}
              className="inline rounded-30px bg-blue-1 text-white text-30px px-48px py-17px"
            >
              <Marquee
                style={{ width: "200px" }}
                speed={70}
                gradient={false}
                direction={"right"}
              >
                馬上報名
                <svg
                  className="ml-4 inline"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32.5333 15.4709C32.4044 15.1385 32.2112 14.8348 31.9646 14.5772L18.4229 1.03551C18.1704 0.782991 17.8706 0.582681 17.5407 0.446018C17.2107 0.309354 16.8571 0.239014 16.5 0.239014C15.7788 0.239014 15.0871 0.525523 14.5771 1.03551C14.3246 1.28803 14.1243 1.58782 13.9876 1.91775C13.8509 2.24769 13.7806 2.60131 13.7806 2.95843C13.7806 3.67966 14.0671 4.37136 14.5771 4.88135L23.5146 13.7918H2.95833C2.24004 13.7918 1.55116 14.0771 1.04325 14.585C0.535342 15.0929 0.25 15.7818 0.25 16.5001C0.25 17.2184 0.535342 17.9073 1.04325 18.4152C1.55116 18.9231 2.24004 19.2084 2.95833 19.2084H23.5146L14.5771 28.1188C14.3232 28.3706 14.1218 28.6702 13.9843 29.0002C13.8468 29.3302 13.776 29.6842 13.776 30.0418C13.776 30.3993 13.8468 30.7533 13.9843 31.0833C14.1218 31.4134 14.3232 31.7129 14.5771 31.9647C14.8289 32.2185 15.1284 32.42 15.4584 32.5575C15.7885 32.695 16.1425 32.7658 16.5 32.7658C16.8575 32.7658 17.2115 32.695 17.5416 32.5575C17.8716 32.42 18.1711 32.2185 18.4229 31.9647L31.9646 18.423C32.2112 18.1654 32.4044 17.8617 32.5333 17.5293C32.8042 16.8699 32.8042 16.1303 32.5333 15.4709Z"
                    fill="white"
                  />
                </svg>
              </Marquee>
            </button>
          )}
        </AbsoluteCenterD>
      </BrowserView>
      <MobileView className="relative flex justify-center">
        <picture>
          {window.innerWidth >= 751 ? (
            <img
              src={gameInfo && gameInfo.setPictures.signUpIntro_D}
              alt="2022男神選拔報名流程"
            />
          ) : (
            <img
              src={gameInfo && gameInfo.setPictures.signUpIntro_M}
              alt="2022男神選拔報名流程"
            />
          )}
        </picture>
        {registerDateOver && (
          <AbsoluteCenterM className="absolute bottom-0">
            <button
              onClick={singUpClickHandler}
              className="inline rounded-15px bg-blue-1 text-white text-16px px-15px py-6px"
            >
              <Marquee
                style={{ width: "100px" }}
                speed={60}
                gradient={false}
                direction={"right"}
              >
                馬上報名
                <svg
                  className="ml-4 inline"
                  width="15"
                  height="15"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32.5333 15.4709C32.4044 15.1385 32.2112 14.8348 31.9646 14.5772L18.4229 1.03551C18.1704 0.782991 17.8706 0.582681 17.5407 0.446018C17.2107 0.309354 16.8571 0.239014 16.5 0.239014C15.7788 0.239014 15.0871 0.525523 14.5771 1.03551C14.3246 1.28803 14.1243 1.58782 13.9876 1.91775C13.8509 2.24769 13.7806 2.60131 13.7806 2.95843C13.7806 3.67966 14.0671 4.37136 14.5771 4.88135L23.5146 13.7918H2.95833C2.24004 13.7918 1.55116 14.0771 1.04325 14.585C0.535342 15.0929 0.25 15.7818 0.25 16.5001C0.25 17.2184 0.535342 17.9073 1.04325 18.4152C1.55116 18.9231 2.24004 19.2084 2.95833 19.2084H23.5146L14.5771 28.1188C14.3232 28.3706 14.1218 28.6702 13.9843 29.0002C13.8468 29.3302 13.776 29.6842 13.776 30.0418C13.776 30.3993 13.8468 30.7533 13.9843 31.0833C14.1218 31.4134 14.3232 31.7129 14.5771 31.9647C14.8289 32.2185 15.1284 32.42 15.4584 32.5575C15.7885 32.695 16.1425 32.7658 16.5 32.7658C16.8575 32.7658 17.2115 32.695 17.5416 32.5575C17.8716 32.42 18.1711 32.2185 18.4229 31.9647L31.9646 18.423C32.2112 18.1654 32.4044 17.8617 32.5333 17.5293C32.8042 16.8699 32.8042 16.1303 32.5333 15.4709Z"
                    fill="white"
                  />
                </svg>
              </Marquee>
            </button>
          </AbsoluteCenterM>
        )}
      </MobileView>
    </>
  );
};
