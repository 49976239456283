import styled from "styled-components";
import { dotAnimation, rotateAnimation } from "./animation";
import { MEDIA_QUERY_MD } from "assets/styles/responsive";

import loadingPicture from "assets/playground/roulette/Desktop/Meteor_Roulette_13_D.png";
import ErrorPopupLayout from "./ErrorPopupLayout";

const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #51a49a;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;
const LoadingContext = styled.p`
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  color: #f4da41;
`;
const LoadingContextSpan1 = styled.span`
  display: inline-block;
  animation: ${dotAnimation} 2s linear infinite;
`;
const LoadingContextSpan2 = styled(LoadingContextSpan1)`
  animation: ${dotAnimation} 2s 0.5s linear infinite;
`;
const LoadingContextSpan3 = styled(LoadingContextSpan1)`
  animation: ${dotAnimation} 2s 0.8s linear infinite;
`;
const LoadingCircle = styled.div`
  position: absolute;
  width: 283px;
  height: 283px;
  background-image: url(${loadingPicture});
  background-size: cover;
  animation: ${rotateAnimation} 3s linear infinite;
`;
const ErrorPopupContext = styled.p`
  font-size: 26px;
  color: #ec6e67;
  line-height: 40px;
  text-align: center;
  font-weight: 900;
  white-space: pre-line;
  letter-spacing: 0.1em;
  ${MEDIA_QUERY_MD} {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.08em;
  }
`;
const ErrorPopupPreviousPageNotice = styled(ErrorPopupContext)``;
const Loading = ({ popupShow, errorMessage }) => {
  return (
    <LoadingWrapper>
      {popupShow === "loadingError" ? (
        <ErrorPopupLayout>
          <ErrorPopupContext>{errorMessage}</ErrorPopupContext>
          <ErrorPopupPreviousPageNotice>
            請返回上一頁
          </ErrorPopupPreviousPageNotice>
        </ErrorPopupLayout>
      ) : (
        <>
          <LoadingContext>
            Loading<LoadingContextSpan1>.</LoadingContextSpan1>
            <LoadingContextSpan2>.</LoadingContextSpan2>
            <LoadingContextSpan3>.</LoadingContextSpan3>
          </LoadingContext>
          <LoadingCircle></LoadingCircle>
        </>
      )}
    </LoadingWrapper>
  );
};
export default Loading;
