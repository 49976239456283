import { useState, useEffect } from "react";
import { logShare } from "services/vote/2022highschooluniform";
import { UNIFORM_GAME_ID } from "config/CONSTANTS";
import useScreenSize from "hooks/useScreenSize";
import { isMobile } from "react-device-detect";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faShare } from "@fortawesome/free-solid-svg-icons";
import faShare from "assets/vote/2022uniform/icon_SHARE.png";
import gtag from "ga-gtag";

import Modal from "components/vote/Modal";
import CloseButton from "components/vote/CloseButton";
import Logo from "components/vote/2022uniform/Logo";

import igIcon from "assets/vote/2022uniform/icon_INS.png";
import arrowLineR from "assets/vote/MOBILE/METEOR_campaign_short_arrowline_M_R.png";
import arrowLineL from "assets/vote/MOBILE/METEOR_campaign_short_arrowline_M_L.png";
import userIcon from "assets/vote/2022uniform/user-free-icon-font.png";

import styled from "styled-components";

const shareMessage = "Meteor高校制服大賞快來投出全台最好看的高校制服!";

const ShareGroup = styled.ul`
  position: fixed;
  top: 61px;
  right: 0;
  background: #1236b8;
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* font-weight: 600; */
  font-size: 16px;
  font-family: GenSenRoundedTW-M;
`;

export const Navbar = (props) => {
  const { userSession, myProfileModalHandler } = props;
  const windowDimenion = useScreenSize();

  const shareData = {
    userId: userSession,
    gameId: UNIFORM_GAME_ID,
    shareOn: "",
  };
  const [showShare, setShowShare] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    const location = window.location.href;
    setCurrentUrl(location);
  }, []);

  const [toggleMenuModal, setToggleMenuModal] = useState(false);
  const [navbarBg, setNavbarBg] = useState(false);
  const [desktopMenu, setDesktopMenu] = useState(false);

  const changeBackground = () => {
    window.scrollY >= 66 ? setNavbarBg(true) : setNavbarBg(false);
  };
  useEffect(() => {
    if (isMobile) {
      setDesktopMenu(false);
    } else {
      setDesktopMenu(true);
    }
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  }, []);

  const toggleMenuModalHandler = () => {
    setToggleMenuModal((preState) => !preState);
  };

  const igShare = () => {
    window.open(`https://www.instagram.com/meteor.tw/`);
  };
  const lineShare = () => {
    shareData.shareOn = "line";
    logShare(shareData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });

    // ga4 api
    gtag("event", "點擊分享Line", {
      gameId: UNIFORM_GAME_ID,
      userSession: userSession,
      send_to: "G-SR7XCS1GYX",
    });

    // bat api
    window.bat.track("share", {
      method: "line",
      gameId: UNIFORM_GAME_ID,
      userSession: userSession,
    });

    window.open(
      `https://lineit.line.me/share/ui?url=${currentUrl}&text=%23${shareMessage}`
    );
  };
  const fbShare = (width, height) => {
    shareData.shareOn = "facebook";

    // api
    logShare(shareData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });

    // ga4 api
    gtag("event", "點擊分享Facebook", {
      gameId: UNIFORM_GAME_ID,
      userSession: userSession,
      send_to: "G-SR7XCS1GYX",
    });

    // bat api
    window.bat.track("share", {
      method: "facebook",
      gameId: UNIFORM_GAME_ID,
      userSession: userSession,
    });

    const leftPosition = window.screen.width / 2 - (width / 2 + 10);
    const topPosition = window.screen.height / 2 - (height / 2 + 50);
    const windowFeatures =
      "status=no,height=" +
      height +
      ",width=" +
      width +
      ",resizable=yes,left=" +
      leftPosition +
      ",top=" +
      topPosition +
      ",screenX=" +
      leftPosition +
      ",screenY=" +
      topPosition +
      ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
    window.open(
      "http://www.facebook.com/sharer.php?u=" +
        encodeURIComponent(currentUrl) +
        "&hashtag=%23" +
        encodeURIComponent(shareMessage),
      "sharer",
      windowFeatures
    );
    return false;
  };
  const urlCopy = () => {
    navigator.clipboard.writeText(currentUrl);
    alert("複製成功");
  };
  return (
    <nav
      id="header"
      className={`bg-blue-3 md:mt-0" fixed w-full z-30 top-0 sm:px-[44px] sm:py-[13px] py-[7px] px-[14px]`}
    >
      <div className="w-full mx-auto flex flex-wrap items-center justify-between">
        <Logo />
        <div className="flex sm:hidden ">
          {userSession && (
            <div
              onClick={myProfileModalHandler}
              className="mr-33px cursor-pointer text-white  rounded-md pr-8px"
            >
              <img src={userIcon} alt="userIcon" className="h-[18px] "></img>
            </div>
          )}
          <button
            id="nav-toggle"
            onClick={toggleMenuModalHandler}
            className="flex items-center  text-black-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out pl-[8px]"
          >
            <svg
              className="fill-white h-[18px] w-[18px]"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <div
          className="w-full flex-grow sm:flex sm:items-center sm:w-auto  sm:mt-0 bg-white sm:bg-transparent text-black  lg:p-0  z-20 text-14px hidden sm:block"
          id="nav-content"
        >
          <ul className="list-reset text-base sm:flex justify-end flex-1 items-center">
            <li onClick={igShare} className=" cursor-pointer mx-[18px]">
              <img src={igIcon} alt="IGlogo" className="h-[29px]" />
            </li>
            <li
              className="cursor-pointer"
              onClick={() => {
                setShowShare(!showShare);
                // setToggleMenuModal(true);
              }}
            >
              {/* <i class="fa-regular fa-share"></i> */}
              <img
                src={faShare}
                alt="分享按鈕"
                className={`${
                  showShare ? "text-yellow-3" : "text-white"
                } h-[29px] ml-[18px]`}
              />
            </li>

            {userSession && (
              <li
                onClick={myProfileModalHandler}
                className="ml-[36px]  cursor-pointer text-white rounded-md "
              >
                <img src={userIcon} alt="userIcon" className="h-[28px]"></img>
              </li>
            )}
          </ul>
        </div>

        {showShare && (
          <ShareGroup navbarBg={navbarBg}>
            <li
              onClick={lineShare}
              className={`mr-33px cursor-pointer text-white py-15px  w-full text-center hover:text-yellow-3`}
            >
              LINE
            </li>
            <img
              src={arrowLineR}
              alt=""
              width={"50%"}
              className="absolute left-1/2 -translate-x-[50%] top-[103px]"
            />
            <li
              onClick={() => fbShare(400, 300)}
              className={`mr-33px cursor-pointer text-white py-15px w-full text-center hover:text-yellow-3`}
            >
              FACEBOOK
            </li>
            <img
              src={arrowLineL}
              alt=""
              width={"50%"}
              className="absolute left-1/2 -translate-x-[50%] top-[168px]"
            />
            <li
              onClick={urlCopy}
              className={`mr-33px cursor-pointer text-white py-15px w-full text-center hover:text-yellow-3`}
            >
              複製連結
            </li>
          </ShareGroup>
        )}
        {/* <Modal handleClose={() => setShowShare(false)} isOpen={showShare}>
          <CloseButton
            size="30"
            fill={"#fff"}
            handleClose={() => setShowShare(false)}
          />
          <div className="w-screen h-screen bg-blue-1 text-white z-20 text-base font-bold">
            <div className="w-screen h-screen flex flex-col pb-50px px-85px mt-50px items-center justify-center text-center bg-blue-2">
              <ul>
                <li onClick={lineShare} className="mr-33px cursor-pointer">
                  LINE
                </li>
                <img src={arrowLineR} alt="" />
                <li
                  onClick={() => fbShare(400, 300)}
                  className="mr-33px cursor-pointer"
                >
                  FACEBOOK
                </li>
                <img src={arrowLineL} alt="" />
                <li onClick={urlCopy} className="mr-33px cursor-pointer">
                  複 製 連 結
                </li>
              </ul>
            </div>
          </div>
        </Modal> */}
        <Modal
          handleClose={() => setToggleMenuModal(false)}
          isOpen={toggleMenuModal}
        >
          <CloseButton
            size="30"
            fill={"#fff"}
            handleClose={() => setToggleMenuModal(false)}
          />
          <div className="w-screen h-screen bg-blue-1 text-white z-20 text-base font-bold">
            <div className="w-screen h-screen flex flex-col pb-50px px-85px mt-50px items-center justify-center text-center bg-blue-2">
              <ul>
                <li onClick={igShare} className="mr-33px">
                  INSTAGRAM
                </li>
                <img src={arrowLineR} alt="" />
                <li onClick={fbShare} className="mr-33px">
                  FACEBOOK
                </li>
                <img src={arrowLineL} alt="" />
                <li onClick={lineShare} className="mr-33px">
                  LINE
                </li>
              </ul>
            </div>
          </div>
        </Modal>
      </div>
    </nav>
  );
};
