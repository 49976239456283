import { SYSTEM_ERROR } from "config/CONSTANTS";
import axios from "axios";
import { UPLOAD_IMAGE } from "./CONSTANTS";

/**
 * Function to uploadImage.
 */
export const uploadImage = (formData) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(UPLOAD_IMAGE(), formData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("uploadImage > axios err=", err);
          reject("Error in uploadImage axios!");
        });
    } catch (error) {
      console.error("in userServices > uploadImage, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
