const VoteButton = (props) => {
  const { voteHandler } = props;
  return (
    <>
      <button
        onClick={voteHandler}
        className="md:w-[129px] md:h-[45.4px] w-[61px] h-[22px] flex justify-center items-center font-normal text-base bg-orange-2 rounded-[30px] md:text-[18px]  text-12px  text-white"
      >
        <span className="sm:scale-[1] scale-[83%]">投他 1 票</span>
      </button>
    </>
  );
};

export default VoteButton;
