import { SYSTEM_ERROR } from "config/CONSTANTS";
import axios from "axios";
import { SING_UP_FORM } from "./CONSTANTS";

/**
 * Function to singUpForm.
 */
export const singUpForm = (singUpFormData) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(SING_UP_FORM(), singUpFormData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("singUpForm > axios err=", err);
          reject(err.response.data);
        });
    } catch (error) {
      console.error("in userServices > singUpForm, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
