const VoteButton = (props) => {
  const { voteHandler } = props;
  return (
    <>
      <button
        onClick={voteHandler}
        className="sm:w-[94px] sm:h-[47px] w-[60px] h-[28px] flex justify-center items-center  text-base bg-blue-11 sm:rounded-[12px] rounded-[8px] sm:text-[24px]  font-black sm:text-[24px] text-[14px]  text-white-1 shadow-superButton"
      >
        <span className="">投票</span>
      </button>
    </>
  );
};

export default VoteButton;
