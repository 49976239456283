import { useState, useEffect } from "react";
import { logShare } from "services/vote/2023girl";
import { Girl_2023_ID } from "config/CONSTANTS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gtag from "ga-gtag";
import Logo from "components/vote/2023girl/Logo";
import {
  NavBarTab,
  NavBarMenuHeader,
  NavBarMenu
}  from "components/vote/2023girl/Navbar";

import IGSvg from "assets/icon/IGSvg.js";
import FBSvg from "assets/icon/FBSvg.js";
import LineSvg from "assets/icon/LineSvg.js";
import TTSvg from "assets/icon/TTSvg.js";
import YTSvg from "assets/icon/YTSvg.js";
import userIcon from "assets/vote/2023girl/DESKTOP/user.png";

import styled from "styled-components";
import { useRef } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { showMenuStore } from "stores/showMenu.js"
import { showSignUpStore } from "stores/showSignUp"

const shareMessage = "Meteor學生社群校園女神由你決定";
const ShareGroup = styled.div`
  position: fixed;
  top: 60px;
  right: 0;
  width: 232px;
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-weight: 800;
  font-size: 20px;
  font-family: Noto Sans TC;
  @media (max-width: 640px) {
    top: 48px;
    width: 184px;
    padding: 24px;
    font-size: 16px;
    font-weight: 900;
  }
`;
const SubMenu = styled.div`
  margin-top: 8px;
`;

export const Navbar = (props) => {
  const { userSession, myProfileModalHandler, navbarColor } = props;
  const shareData = {
    userId: userSession,
    gameId: Girl_2023_ID,
    shareOn: "",
  };
  const navRef = useRef(null);
  const { showMenu, setShowMenu } = showMenuStore()
  const { isShowSignUp } = showSignUpStore();
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    const location = window.location.href;
    setCurrentUrl(location);
  }, []);

  // const igShare = () => {
  //   window.open(`https://www.instagram.com/meteor.tw/`);
  // };

  const lineShare = () => {
    shareData.shareOn = "line";
    logShare(shareData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });

    // ga4 api
    gtag("event", "點擊分享Line", {
      gameId: Girl_2023_ID,
      userSession: userSession,
      send_to: "G-NQTWZ5RW0Y",
    });

    // bat api
    window.bat.track("share", {
      method: "line",
      gameId: Girl_2023_ID,
      userSession: userSession,
    });

    window.open(
      `https://lineit.line.me/share/ui?url=${currentUrl}&text=%23${shareMessage}`
    );
  };
  const fbShare = (width, height) => {
    shareData.shareOn = "facebook";

    // api
    logShare(shareData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });

    // ga4 api
    gtag("event", "點擊分享Facebook", {
      gameId: Girl_2023_ID,
      userSession: userSession,
      send_to: "G-NQTWZ5RW0Y",
    });

    // bat api
    window.bat.track("share", {
      method: "facebook",
      gameId: Girl_2023_ID,
      userSession: userSession,
    });

    const leftPosition = window.screen.width / 2 - (width / 2 + 10);
    const topPosition = window.screen.height / 2 - (height / 2 + 50);
    const windowFeatures =
      "status=no,height=" +
      height +
      ",width=" +
      width +
      ",resizable=yes,left=" +
      leftPosition +
      ",top=" +
      topPosition +
      ",screenX=" +
      leftPosition +
      ",screenY=" +
      topPosition +
      ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
    window.open(
      "http://www.facebook.com/sharer.php?u=" +
        encodeURIComponent(currentUrl) +
        "&hashtag=%23" +
        encodeURIComponent(shareMessage),
      "sharer",
      windowFeatures
    );
    return false;
  };
  const scrollToHandler = (id) => {
     const violation = document.getElementById(id); 
     window.scrollTo({
       top:violation.offsetTop,
       behavior:"smooth"
     });
  }

  useEffect(() => {
    if (navbarColor) {
      navRef.current.style.background = "#E6E8EDCC";
    } else {
      navRef.current.style.background = "transparent";
    }
  }, [navbarColor]);
  return (
    <nav
      id="header"
      className={`md:mt-0" fixed w-full z-30 top-0 h-[48px] sm:h-[60px] sm:px-[37px] sm:py-[13px] py-[8px] px-[10px] font-NotoSansTC`}
      ref={navRef}
    >
      <div className="w-full h-[32px] mx-auto flex flex-wrap sm:items-center justify-between">
        <Logo />
        <div
          className="w-auto bg-transparent z-10"
          id="nav-content">
          <ul className="list-reset text-base flex justify-end items-center">
            <NavBarTab
              className="hidden sm:block"
              onClick={() => {
                  scrollToHandler('activities');
                }}>活動辦法</NavBarTab>
            <NavBarTab
              className="hidden sm:block"
              onClick={() => {
                  scrollToHandler('processes');
                }}>報名事項</NavBarTab>
            <NavBarTab
              className="hidden sm:block"
              onClick={() => {
                  scrollToHandler('notices');
                }}>注意事項</NavBarTab>
            <NavBarTab
              className="items-center sm:p-0 pr-[10px]"
              onClick={() => {
                  setShowMenu(!showMenu);
                }}>
              <FontAwesomeIcon
                icon={faBars}
                className={`${
                  showMenu ? "text-pink-11" : "text-blue-11"
                } sm:pl-[10px] text-[24px] pt-[5px] sm:pt-0`}/>
            </NavBarTab>
          </ul>
        </div>

        {showMenu && (
          <ShareGroup
           className="bg-blue-11">
            <NavBarTab
              className="sm:hidden block"
              onClick={() => {
                scrollToHandler('activities');
              }}>
              活動辦法
            </NavBarTab>
            <NavBarTab
              className="sm:hidden block"
              onClick={() => {
                scrollToHandler('processes');
              }}>
              報名事項
            </NavBarTab>
            <NavBarTab
              className="sm:hidden block"
              onClick={() => {
                scrollToHandler('notices');
              }}>
              注意事項
            </NavBarTab>

            {isShowSignUp && <div
                onClick={myProfileModalHandler}
                className="flex cursor-pointer text-blue-11 bg-yellow-11 rounded-md sm:p-1 px-1 sm:mb-[22px] mb-[36px] sm:mt-[0px] mt-[4px]"
              >
                <img
                  src={userIcon}
                  alt="userIcon"
                  className="h-[32px]"></img>
                <span
                  className="flex items-center sm:px-2 pl-1 pr-[10px]">
                  報名資料
                </span>
            </div>}
            <NavBarMenuHeader>
              分享投票
            </NavBarMenuHeader>
            <SubMenu>
              {/* <NavBarMenu
                onClick={igShare}>
                <IGSvg />
                  Instagram
              </NavBarMenu> */}
              <NavBarMenu
                onClick={() => fbShare(400, 300)}>
                <FBSvg />
                Facebook
              </NavBarMenu>
              <NavBarMenu
                onClick={lineShare}>
                <LineSvg />
                LINE
              </NavBarMenu>
            </SubMenu>
            
            <NavBarMenuHeader
              className="mt-20px">
              加入我們
            </NavBarMenuHeader>
            <SubMenu>
              <NavBarMenu
                onClick={igLink}>
                <IGSvg />
                  Instagram
              </NavBarMenu>
              <NavBarMenu
                onClick={tiktokLink}>
                <TTSvg />
                TikTok
              </NavBarMenu>
              <NavBarMenu
                onClick={youtubeLink}>
                <YTSvg />
                Youtube
              </NavBarMenu>
              <NavBarMenu
                onClick={fbLink}>
                <FBSvg />
                Facebook
              </NavBarMenu>
            </SubMenu>
          </ShareGroup>
        )}
      </div>
    </nav>
  );
};

  const igLink = () => {
    window.open(`https://www.instagram.com/meteor.tw/?utm_source=beautyCampaign2023`);
  };
  const tiktokLink = () => {
    window.open(`https://www.tiktok.com/@meteor.tw?utm_source=beautyCampaign2023`);
  };
  const youtubeLink = () => {
    window.open(`https://www.youtube.com/@MeteorTW?utm_source=beautyCampaign2023`);
  };
  const fbLink = () => {
    window.open(`https://www.facebook.com/MeteorApp?utm_source=beautyCampaign2023`);
  };
