import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { getAllCandidates } from "services/vote/2022highschooluniform";
import { Navbar } from "./components/Navbar";
import { Hero } from "./components/Hero";
import { CandidatesList } from "./components/CandidatesList";
import { Activities } from "./components/Activities";
import { Process } from "./components/Process";
import { Notice } from "./components/Notice";
import SignUpButton from "./components/SignUpButton";
import Modal from "components/vote/Modal";
import SignUp from "./components/SignUp";
import MyProfile from "./components/MyProfile";
import Meta from "./components/Meta";
import { UniformGameInfoContext } from "store/contex";
import gtag from "ga-gtag";
import LinkToNew from "components/vote/LinkToNew"

const Highschooluniform2022 = () => {
  const { gameInfo } = useContext(UniformGameInfoContext);
  const [vote, setVote] = useState([]);
  const [registerDateOver, setRegisterDateOver] = useState(false);
  const [announceTime, setAnnounceTime] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [initPlayers, setInitPlayers] = useState([]);
  const [winnerPlayers, setWinnerPlayers] = useState([]);
  const [userSession, setUserSession] = useState(null);
  const [toggleSignUpModal, setToggleSignUpModal] = useState(false);
  const [toggleMyProfileModal, setToggleMyProfileModal] = useState(false);
  const [signUpButtonVisible, setSignUpButtonVisible] = useState(false);
  const myRef = useRef(null);
  const refIFrame = useRef(null);
  useEffect(() => {
    gtag("event", "page_view", {
      page_title: "Meteor2022 高校制服大賞",
      page_location: "https://game.meteor.today",
      page_path: "/vote/2022highschooluniform",
      send_to: "G-SR7XCS1GYX",
    });
  }, []);
  useEffect(() => {
    if (gameInfo) {
      const registerStart = gameInfo.registerStart;
      const registerEnd = gameInfo.registerEnd;
      const announceTime = gameInfo.announceTime;
      const announceDate = new Date(announceTime);

      const date_now = new Date().getTime();
      const dateStart = new Date(registerStart).getTime();
      const dateEnd = new Date(registerEnd).getTime();

      if (date_now > dateStart && date_now < dateEnd) {
        setRegisterDateOver(true);
      } else {
        setRegisterDateOver(false);
      }

      if (announceDate < date_now) {
        setAnnounceTime(true);
      } else {
        setAnnounceTime(false);
      }
    }
  }, [gameInfo]);

  const listenToScroll = useCallback(() => {
    let heightToHideFrom = myRef.current.getBoundingClientRect().y;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll < heightToHideFrom) {
      signUpButtonVisible && setSignUpButtonVisible(false);
    } else {
      setSignUpButtonVisible(true);
    }
  }, [signUpButtonVisible]);
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [listenToScroll]);

  const myProfileModalHandler = () => {
    setToggleMyProfileModal((preState) => !preState);
  };

  useEffect(() => {
    getAllCandidates()
      .then((res) => {
        setCandidates(
          res.players.sort((a, b) => {
            if (b.voteCount === a.voteCount) {
              return a.entryNumber - b.entryNumber;
            }
            return b.voteCount - a.voteCount;
          })
        );
        setInitPlayers(
          res.players.sort((a, b) => {
            if (b.voteCount === a.voteCount) {
              return a.entryNumber - b.entryNumber;
            }
            return b.voteCount - a.voteCount;
          })
        );
        setWinnerPlayers(
          res.players.sort((a, b) => b.voteCount - a.voteCount).slice(0, 3)
        );
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
    return () => {
      console.log("axios cleanup.");
    };
  }, [vote]);

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        xfbml: true,
        version: "v12.0",
        appId: "1717255128492433",
      });
      window.FB.XFBML.parse();
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/zh_TW/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    return () => {
      if (window.FB) {
        window.FB.XFBML.parse();
      }
    };
  }, []);

  const getLogin = () => {
    const iframeItem = refIFrame.current;
    iframeItem.contentWindow.postMessage(
      JSON.stringify({ method: "get_login" }),
      "*"
    );
  };

  const onLoad = () => {
    getLogin();
  };

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.origin !== "https://meteor.today") {
        return;
      }
      const { data } = e;
      setUserSession(data.data.userId);
      // setUserSession('6229bead0063c7eb0edc7e45')

      switch (data.method) {
        case "add_vote":
          if (data.status !== 200) {
            if (data.data === "請先登入") {
              window.location.href =
                "https://meteor.today/login?next=https://game.meteor.today/vote/2022highschooluniform";
            }
            alert(data.data);
            getLogin();
            return;
          } else {
            setVote([...vote, 1]);
            alert("投票成功");
            getLogin();
          }

          break;
        default:
          break;
      }
    });
  }, []);

  const singUpModalHandler = () => {
    if (!userSession) {
      alert("請先登入");
      window.location.href =
        "https://meteor.today/login?next=https://game.meteor.today/vote/2022highschooluniform";
      return;
    }
    setToggleSignUpModal((preState) => !preState);
  };
  return (
    <div className="font-GenYoGothic-B overflow-hidden">
      <Meta />
      <Navbar
        myProfileModalHandler={myProfileModalHandler}
        refIFrame={refIFrame}
        userSession={userSession}
      />
      <section>
        <Hero />
      </section>
      <section ref={myRef}>
        <CandidatesList
          userSession={userSession}
          refIFrame={refIFrame}
          candidates={candidates}
          setCandidates={setCandidates}
          initPlayers={initPlayers}
          setInitPlayers={setInitPlayers}
          winnerPlayers={winnerPlayers}
          announceTime={announceTime}
        />
      </section>
      <section>
        <Activities />
      </section>
      <section>
        <Process
          registerDateOver={registerDateOver}
          userSession={userSession}
          setToggleSignUpModal={setToggleSignUpModal}
        />
      </section>
      <section>
        <Notice />
      </section>
      <LinkToNew />
      {signUpButtonVisible && registerDateOver && (
        <SignUpButton
          userSession={userSession && userSession}
          singUpModalHandler={singUpModalHandler}
        />
      )}
      <iframe
        ref={refIFrame}
        title="meteor"
        onLoad={onLoad}
        id="iframe_dom_box"
        style={{ width: "0", height: "0" }}
        src="https://meteor.today/iframe/handsome2022"
        frameBorder="0"
      ></iframe>
      <Modal
        handleClose={() => setToggleSignUpModal(false)}
        isOpen={toggleSignUpModal}
      >
        <SignUp
          userSession={userSession}
          setToggleSignUpModal={setToggleSignUpModal}
        />
      </Modal>
      {userSession && (
        <Modal
          handleClose={() => setToggleMyProfileModal(false)}
          isOpen={toggleMyProfileModal}
        >
          <MyProfile
            userSession={userSession}
            myProfileModalHandler={myProfileModalHandler}
            setToggleMyProfileModal={setToggleMyProfileModal}
          />
        </Modal>
      )}
    </div>
  );
};

export default Highschooluniform2022;
