import styled from "styled-components/macro";
import { MEDIA_QUERY_MD } from "assets/styles/responsive";
import { centerShow } from "./animation";
import forkIcon from "assets/playground/roulette/Desktop/Meteor_component_10_D.png";
import popUpNoTimesPicture from "assets/playground/roulette/Desktop/Meteor_component_16_D.png";

const PopUpsWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 442px;
  height: 304px;
  top: 50%;
  left: 50%;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);
  border-radius: 18px;
  padding: 48px 0 0 0;
  animation: ${centerShow} 0.3s ease-in-out;
  letter-spacing: 0.1em;
  ${MEDIA_QUERY_MD} {
    width: 300px;
    height: 209px;
    padding: 32px 0 0 0;
  }
`;
const PopUpsTitle = styled.h2`
  padding-bottom: 32px;
  font-size: 36px;
  font-weight: 900;
  color: #ec6e67;
  line-height: 1;
  ${MEDIA_QUERY_MD} {
    font-size: 24px;
    padding-bottom: 20px;
  }
`;

const PopUpsButton = styled.button`
  background-image: url(${forkIcon});
  background-size: cover;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  ${MEDIA_QUERY_MD} {
    width: 27.15px;
    height: 27.15px;
    top: 5.43px;
    right: 5.43px;
  }
`;

const PopUpsNoTimesTitle = styled(PopUpsTitle)`
  /* font-size: 36px; */
  font-weight: 900;
  color: #666666;
  line-height: 1;
  padding-bottom: 20px;
  ${MEDIA_QUERY_MD} {
    padding-bottom: 12px;
  }
`;
const PopUpsNoTimeImg = styled.div`
  background-image: url(${popUpNoTimesPicture});
  background-size: cover;
  width: 111px;
  height: 172px;
  ${MEDIA_QUERY_MD} {
    width: 77px;
    height: 120px;
  }
`;

const NoTimesPopup = ({ handlePopupsRemove }) => {
  return (
    <PopUpsWrapper>
      <PopUpsButton onClick={handlePopupsRemove}></PopUpsButton>
      <>
        <PopUpsNoTimesTitle>明日再來</PopUpsNoTimesTitle>
        <PopUpsNoTimeImg></PopUpsNoTimeImg>
      </>
    </PopUpsWrapper>
  );
};

export default NoTimesPopup;
