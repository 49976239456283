import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { getAllCandidates } from "services/vote/2023girl";
import { Navbar } from "./components/Navbar";
import { Hero } from "./components/Hero";
import { CandidatesList } from "./components/CandidatesList";
import { Activities } from "./components/Activities";
import { Process } from "./components/Process";
import { Notice } from "./components/Notice";
import SignUpButton from "./components/SignUpButton";
import Modal from "components/vote/Modal";
import MyProfile from "./components/MyProfile";
import Meta from "./components/Meta";
import { Girl2023InfoContext } from "store/contex";
import gtag from "ga-gtag";
import "assets/styles/scroll.css";
import "assets/styles/checkbox.css";
import { METEORURL, Girl_2023_ID } from "config/CONSTANTS";
import ArrowDSvg from "assets/icon/ArrowDSvg.js";
import styled from "styled-components";
import { showSignUpStore } from "stores/showSignUp"

const ArrowUpStyle = styled.div`
  transform: rotate(180deg);
  position: fixed;
  bottom: 32px;
  background: #FF55B3;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  right: calc(6.1vw - 23px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 15;
  svg {
    width: 26px;
    height: 16px;
  }
  @media (max-width: 640px) {
    right: 14px;
    width: 40px;
    height: 40px;
    bottom: 40px;
    svg {
    width: 20px;
    height: 12px;
  }
}
`

const Girl2023 = () => {
  const { gameInfo } = useContext(Girl2023InfoContext);
  const [vote, setVote] = useState([]);
  const [navbarColor, setNavbarColor] = useState(false);
  const [registerDateOver, setRegisterDateOver] = useState(false);
  const [isAnnounced, setAnnounced] = useState(false);
  const [initCandidates, setInitCandidates] = useState([]);
  const [userSession, setUserSession] = useState(null);
  const [toggleMyProfileModal, setToggleMyProfileModal] = useState(false);
  const [signUpButtonVisible, setSignUpButtonVisible] = useState(false);
  const { setShowSignUp } = showSignUpStore();
  const [profile, setProfile] = useState(null);
  const myRef = useRef(null);
  const refIFrame = useRef(null);
  useEffect(() => {
    gtag("event", "page_view", {
      page_title: "Meteor2023 | 校園女神選拔賽",
      page_location: "https://game.meteor.today/vote/beautyCampaign2023",
      send_to: "G-NQTWZ5RW0Y",
    });

    window.fbAsyncInit = function () {
      window.FB.init({
        xfbml: true,
        version: "v12.0",
        appId: "1717255128492433",
      });
      window.FB.XFBML.parse();
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/zh_TW/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    return () => {
      if (window.FB) {
        window.FB.XFBML.parse();
      }
    };
  }, []);

  useEffect(() => {
    if (gameInfo) {
      const registerStart = gameInfo.registerStart;
      const registerEnd = gameInfo.registerEnd;
      const announceDate = new Date(gameInfo.announceTime).getTime();
      const date_now = new Date().getTime();
      const dateStart = new Date(registerStart).getTime();
      const dateEnd = new Date(registerEnd).getTime();
      if (date_now > dateStart && date_now < dateEnd) {
        setRegisterDateOver(true);
      } else {
        setRegisterDateOver(false);
      }
      if (announceDate < date_now) {
        setAnnounced(true);
      } else {
        setAnnounced(false);
      }
    }
  }, [gameInfo]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const listenToScroll = useCallback(() => {
    let heightToHideFrom = myRef.current.getBoundingClientRect().y;
    // const winScroll =
      // document.body.scrollTop || document.documentElement.scrollTop;
    // if (winScroll < heightToHideFrom) {
    if (heightToHideFrom >= 0) {
      setNavbarColor(false);
    } else {
      setNavbarColor(true);
    }
    if (heightToHideFrom >= 600) {
      signUpButtonVisible && setSignUpButtonVisible(false);
    } else {
      setSignUpButtonVisible(true);
    }

  }, [signUpButtonVisible]);
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [listenToScroll]);

  const myProfileModalHandler = () => {
    setToggleMyProfileModal((preState) => !preState);
  };
  useEffect(() => {
    getAllCandidates()
      .then((res) => {
        setInitCandidates([...res.players]);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
    return () => {
      console.log("axios cleanup.");
    };
  }, [vote]);

  

  const getLogin = () => {
    const iframeItem = refIFrame.current;
    iframeItem.contentWindow.postMessage(
      JSON.stringify({ method: "get_login" }),
      "*"
    );
  };

  const onLoad = () => {
    getLogin();
  };

useEffect(() => {
  const handleMessage = (e) => {
    if (e.origin !== METEORURL) {
      return;
    }

    const { data } = e;
    switch (data.method) {
      case "get_login":
        console.log('get login:', userSession)
        if (data.data.userId) {
          setUserSession(data.data.userId)
        } else {
          setUserSession(null)
        }
        break;
      case "add_vote":
        if (data.status !== 200) {
          if (data.data === "請先登入") {
            window.location.href = `${METEORURL}/login?next=${window.location.href}`;
          }
          alert(data.data);
          getLogin();
        } else {
          setVote([...vote, 1]);
          alert("投票成功");
        }
        break;
      case "get_my_player_info":
        if (data.status !== 200) {
          console.log('get_my_player_info error:', data.data)
        } else {
          console.log('get_my_player_info success:', data.data)
          setProfile(data.data.player)
          setShowSignUp(true)
        }
        break;
      default:
        break;
    }
  };

  window.addEventListener("message", handleMessage);

  // setUserSession('61120c0419a9f28268865b53')
  // console.log('userSession', userSession)

  return () => {
    // 在解除效果時，清除事件監聽
    window.removeEventListener("message", handleMessage);
  };
}, []);

  // 用戶報名資訊
  useEffect(() => {
    // 1. 活動未開始前關閉報名按鈕
    if(!gameInfo || new Date().getTime() < new Date(gameInfo.registerStart).getTime() || !userSession) {
      setShowSignUp(false)
      return
    }

    setProfile({
      socialLinks: {},
      info: {},
      playerPhotos: []
    })

    if (userSession) {
      const iframeItem = refIFrame.current;
      iframeItem.contentWindow.postMessage(
      JSON.stringify({
        method: "get_my_player_info",
        data: {
          gameId: Girl_2023_ID
        },
      }),
      "*");
    }

    // 2. 報名期間，顯示按鈕
    if (new Date().getTime() < new Date(gameInfo.registerEnd).getTime()) {
      setShowSignUp(true)
    }
    return () => {
      console.log("axios cleanup.");
    };
  }, [gameInfo, userSession]);

  // 第一階段結束通知
  useEffect(() => {
    const onPageLoad = () => {
      if (new Date().getTime() > new Date('2023-11-14T04:01:00.000Z').getTime() && new Date().getTime() < new Date('2023-11-15T03:59:00.000Z').getTime()) {
        alert('第一階段初選結束，恭喜票數前20名校園女神進行決選\n決選投票時間：11/15 12:00-11/21 12:00')
      }
    }
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, [])



  return (
    <div className="font-NotoSansTC overflow-hidden bg-grey-11">
      <Meta />
      <Navbar
        myProfileModalHandler={myProfileModalHandler}
        refIFrame={refIFrame}
        userSession={userSession}
        navbarColor={navbarColor}
      />
      <div className="flex flex-col">
        <section>
          <Hero />
        </section>
        {/* <section>
          <VideoList />
        </section> */}
        <section
          className="sm:mt-[-3vw] mt-[-2px]"
          ref={myRef}>
          <CandidatesList
            userSession={userSession}
            refIFrame={refIFrame}
            initCandidates={initCandidates}
            isAnnounced={isAnnounced}
          />
        </section>
        <section>
          <Activities />
        </section>
        <section>
          <Process
            registerDateOver={registerDateOver}
            userSession={userSession}
            setToggleMyProfileModal={setToggleMyProfileModal}
          />
        </section>
        <section>
          <Notice />
        </section>
      </div>
      { signUpButtonVisible && (
      <ArrowUpStyle
        onClick={scrollToTop}>
        <ArrowDSvg />
      </ArrowUpStyle> )}
      {signUpButtonVisible && registerDateOver && (
        <SignUpButton
          userSession={userSession}
          setToggleMyProfileModal={setToggleMyProfileModal}
        />
      )}
      <iframe
        ref={refIFrame}
        title="meteor"
        onLoad={onLoad}
        id="iframe_dom_box"
        style={{ width: "0", height: "0" }}
        src={`${METEORURL}/iframe/handsome2022`}
        frameBorder="0"
      ></iframe>
      {userSession && (
        <Modal
          handleClose={() => setToggleMyProfileModal(false)}
          isOpen={toggleMyProfileModal}
        >
          <MyProfile
            userSession={userSession}
            myProfileModalHandler={myProfileModalHandler}
            setToggleMyProfileModal={setToggleMyProfileModal}
            profile={profile}
            setProfile={setProfile}
            refIFrame={refIFrame}
          />
        </Modal>
      )}
    </div>
  );
};

export default Girl2023;
