import { useState, useMemo, useEffect } from "react";
import { SuperpowersGameInfoContext } from "../store/contex";
import { getUniformGameInfo } from "../services/vote/2023superpowers";

const SuperpowersGameInfoProvider = (props) => {
  const [gameInfo, setGameInfo] = useState(null);

  useEffect(() => {
    getUniformGameInfo()
      .then((res) => {
        setGameInfo(res.game);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
    return () => {
      console.log("axios cleanup.");
    };
  }, []);

  const value = useMemo(() => ({ gameInfo, setGameInfo }), [gameInfo]);
  return (
    <SuperpowersGameInfoContext.Provider value={value}>
      {props.children}
    </SuperpowersGameInfoContext.Provider>
  );
};

export default SuperpowersGameInfoProvider;
