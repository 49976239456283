import { useCallback } from "react";
import { useRef } from "react";
import { useEffect, useState } from "react";
// import {
//   InitgameInfo as InitgameInfos,
//   rotateResult,
//   rouletteRecords,
// } from "data/gameInfo";
import {
  playMiniGameFetch,
  getMiniGameRecordsFetch,
} from "services/playground/rouletteFetch";
import { format } from "date-fns";
const useRoulette = () => {
  const [rouletteInfo, setRouletteInfo] = useState(null);
  const [prizes, setPrizes] = useState([]);
  const [canPlayTimes, setCanPlayTimes] = useState(null);
  const [lotteryFreeTimes, setLotteryFreeTimes] = useState(null);
  const [userCoin, setUserCoin] = useState(0);
  const [rouletteRotate, setRouletteRotate] = useState(0);
  const [lottery, setLottery] = useState("");
  const [preAngle, setPreAngle] = useState(null);
  const [rouletteRotateLoading, setRouletteRotateLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  //"",result,record,loading,loadingError,error,noTimes
  const [popupShow, setPopupShow] = useState("loading");
  const [lotteryRecord, setLotteryRecord] = useState([]);
  const [postEnd, setPostEnd] = useState(false);
  const [parentURL, setParentURL] = useState(null);
  const postEndRef = useRef(postEnd);
  postEndRef.current = postEnd;

  const onMessageEventHandler = useCallback((e) => {
    if (
      !(
        e.origin.includes("https://meteor.today") ||
        e.origin.includes("https://meteor-test.batmobile.com.tw")
      )
    ) {
      // if (
      //   !(
      //     e.origin.includes("http://localhost:3001") ||
      //     e.origin.includes("http://localhost:3002")
      //   )
      // ) {
      return;
    }
    setParentURL(e.origin);
    const { data } = e;
    const PostMessageError = JSON.parse(data)?.error;
    if (PostMessageError) {
      console.log(PostMessageError);
      setErrorMessage(PostMessageError);
      setPopupShow("loadingError");
      return;
    }

    const InitgameInfo = JSON.parse(data)?.data;
    const roulettegameInfo = InitgameInfo?.game;
    setRouletteInfo(InitgameInfo);
    const initInfo = {
      gameType: roulettegameInfo?.gameType,
      miniGameId: roulettegameInfo?.miniGameId,
      title: roulettegameInfo?.title,
      instruction: roulettegameInfo?.instruction,
      icon: roulettegameInfo?.icon,
      userId: InitgameInfo?.userId,
    };

    setRouletteInfo(initInfo);
    setUserCoin(InitgameInfo?.userCoin);
    setPrizes(roulettegameInfo?.prizes);
    setCanPlayTimes(InitgameInfo?.canPlayTimes);
    setLotteryFreeTimes(InitgameInfo?.leftFreeTimes);
    setPreAngle(360 / roulettegameInfo?.prizes?.length);
    setPostEnd(true);
    setPopupShow("");
  }, []);

  // //假資料
  // useEffect(() => {
  //   if (window.parent === window) {
  //     console.log("假資料", InitgameInfos);
  //     const InitgameInfo = InitgameInfos?.data;
  //     const roulettegameInfo = InitgameInfo?.game;
  //     setRouletteInfo(InitgameInfo);
  //     const initInfo = {
  //       gameType: roulettegameInfo?.gameType,
  //       miniGameId: roulettegameInfo?.miniGameId,
  //       title: roulettegameInfo?.title,
  //       instruction: roulettegameInfo?.instruction,
  //       icon: roulettegameInfo?.icon,
  //       userId: InitgameInfo?.userId,
  //     };
  //     setRouletteInfo(initInfo);
  //     setUserCoin(InitgameInfo?.userCoin);
  //     setPrizes(roulettegameInfo?.prizes);
  //     setCanPlayTimes(InitgameInfo?.canPlayTimes);
  //     setLotteryFreeTimes(InitgameInfo?.leftFreeTimes);
  //     setPreAngle(360 / roulettegameInfo?.prizes?.length);
  //     setPostEnd(true);
  //     setPopupShow("");
  //   }
  // }, []);
  useEffect(() => {
    window.addEventListener("message", onMessageEventHandler);

    // cleanup this component
    return () => {
      window.removeEventListener("message", onMessageEventHandler);
    };
  }, [onMessageEventHandler]);
  useEffect(() => {
    setTimeout(() => {
      if (postEndRef.current) {
        return;
      }
      console.log("資料未讀取成功");
      // setErrorMessage("資料未讀取成功");
      setPostEnd(true);
      // setPopupShow("loadingError");
    }, 9000);
  }, []);
  useEffect(() => {
    if (window.parent === window) {
      console.log("Iframe 目前沒被嵌入，所以沒畫面");
      return;
    }
    if (postEnd) return;
    setPopupShow("loading");
    let interval = setInterval(() => {
      window.parent.postMessage(
        JSON.stringify({ method: "get_miniGame" }),
        "*"
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [postEnd]);

  const handleMouseClick = () => {
    window.parent.postMessage(JSON.stringify({ method: "mouseClick" }), "*");
  };
  const dotToNewline = (message) => {
    return message.replace("。", "。\u000A");
  };
  const handleChangeRouletteRotate = () => {
    if (!rouletteRotateLoading) {
      if (canPlayTimes === 0) {
        setErrorMessage("今日遊玩結束");
        setPopupShow("noTimes");
        return;
      }
      setRouletteRotateLoading(true);

      playMiniGameFetch(rouletteInfo.miniGameId, rouletteInfo.userId, parentURL)
        .then((rotateResult) => {
          if (rotateResult.error) {
            console.log("ERROR", rotateResult.error);
            setErrorMessage(dotToNewline(rotateResult.error));
            if (rotateResult.error === "M幣不足") {
              setPopupShow("result");
            } else {
              setPopupShow("error");
            }
            setRouletteRotateLoading(false);
            return;
          }
          let prizesAllId = prizes.map(
            ({ miniGamePrizeId }) => miniGamePrizeId
          );
          let finish = prizesAllId.indexOf(rotateResult.winPrizeId);
          setRouletteRotate(
            rouletteRotate -
              (rouletteRotate % 360) +
              360 * 5 +
              preAngle * finish
          );
          setLottery(prizes[finish]);
          setTimeout(() => {
            setPopupShow("result");
            setRouletteRotateLoading(false);
            setCanPlayTimes(rotateResult?.canPlayTimes);
            setLotteryFreeTimes(rotateResult?.leftFreeTimes);
            setUserCoin(rotateResult?.userCoin);
          }, 4000);
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    }
  };

  const handleRecord = async () => {
    getMiniGameRecordsFetch(
      rouletteInfo.miniGameId,
      rouletteInfo.userId,
      parentURL
    )
      .then((rouletteRecords) => {
        if (rouletteRecords?.error) {
          console.log("ERROR", rouletteRecords.error);
          setErrorMessage(dotToNewline(rouletteRecords.error));
          setPopupShow("error");
          return;
        }
        let sameDate = "";
        const RecordResult = rouletteRecords.result.map(
          ({ prizeId, createdAt }, i) => {
            const resultFilter = prizes.filter(
              (prize) => prize.miniGamePrizeId === prizeId
            );
            const originDates = format(new Date(createdAt), "yyyy/MM/dd");
            const dateArray = originDates.split("/");
            dateArray[0] = dateArray[0] - 1911;
            let dates = dateArray.join("/");
            if (dates !== sameDate) {
              sameDate = dates;
            } else {
              dates = "";
            }
            return { ...resultFilter[0], createdAt: dates };
          }
        );
        setLotteryRecord(RecordResult);
        setPopupShow("record");
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
  const handlePopupsRemove = () => {
    setErrorMessage(null);
    setPopupShow("");
  };

  return {
    rouletteInfo,
    prizes,
    lotteryFreeTimes,
    userCoin,
    preAngle,
    rouletteRotate,
    popupShow,
    setPopupShow,
    handleChangeRouletteRotate,
    handlePopupsRemove,
    lottery,
    lotteryRecord,
    handleRecord,
    errorMessage,
    handleMouseClick,
    canPlayTimes,
  };
};

export default useRoulette;
