import { memo } from "react";
import styled from "styled-components";
import { MEDIA_QUERY_MD } from "assets/styles/responsive";

const BackgroundBottomWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 300px;
  z-index: -1;
  ${MEDIA_QUERY_MD} {
    top: 390px;
  }
`;
const BackgroundBottomPicture = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.8%;
  background: url("https://storage.googleapis.com/bat-meteor-game/Meteor_Roulette_2_D.png")
    no-repeat top center/1440px;
  ${MEDIA_QUERY_MD} {
    background: url("https://storage.googleapis.com/bat-meteor-game/Meteor_Roulette_2_M%403x.png")
      no-repeat top center/contain;
    padding-bottom: 60.5%;
  }
`;
const BackgroundBottomColor = styled.div`
  height: 100%;
  background-color: #ffffff;
  margin: -3px 0 0 0;
`;
const BackgroundBottom = () => {
  return (
    <BackgroundBottomWrapper>
      <BackgroundBottomPicture></BackgroundBottomPicture>
      <BackgroundBottomColor></BackgroundBottomColor>
    </BackgroundBottomWrapper>
  );
};

export default memo(BackgroundBottom);
