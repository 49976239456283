import { keyframes } from "styled-components";

import rouletteWrapperFlickerPicture1 from "assets/playground/roulette/Desktop/Meteor_Roulette_10_D.png";
import rouletteWrapperFlickerPicture2 from "assets/playground/roulette/Desktop/Meteor_Roulette_11_D.png";
//Loading
export const dotAnimation = keyframes`
   0%   {transform: translateY(0px);} 
  50%  {transform: translateY(-5px);}
  100% {transform: translateY(0px)}
`;
export const rotateAnimation = keyframes`
  from {
    transform:rotate(0deg) ;
  }
  to {
    transform:rotate(360deg) ;
  }
`;
//popup
export const centerShow = keyframes`
	0% {
		transform: translate(-50%,-50%) scale(0,0);
		opacity: 0;
	}
	/* 20% {transform: scale(1.1,1.1);} */
	100% {
		transform: translate(-50%,-50%) scale(1,1);
		opacity: 1;
	}
`;
//roulette
export const flicker = keyframes`
  0% { background-image: url(${rouletteWrapperFlickerPicture1}); }
  50% { background-image: url(${rouletteWrapperFlickerPicture2}); }
  100% { background-image: url(${rouletteWrapperFlickerPicture1}); }
`;
