import styled from "styled-components";
import { MEDIA_QUERY_MD } from "assets/styles/responsive";
import { isMobile } from "react-device-detect";
import Roulette from "./Roulette";
import LotteryPopUps from "./LotteryPopUps";
import RouletteRecord from "./RouletteRecord";
import lotteryFreeButtonPicture from "assets/playground/roulette/Desktop/Meteor_component_7_D.png";
import lotteryRecordButtonPicture from "assets/playground/roulette/Desktop/Meteor_component_8_D.png";
import lotteryNoTimesButtonPicture from "assets/playground/roulette/Desktop/Meteor_component_15_D.png";
import lotteryHintIcon from "assets/playground/roulette/Desktop/Meteor_component_6_D.png";
import bottomBackground from "assets/playground/roulette/Desktop/Meteor_Roulette_2_D-1.png";
import LotteryDirection from "./LotteryDirection";
import BackgroundBottom from "./BackgroundBottom";
import ErrorPopup from "./ErrorPopup";
import NoTimesPopup from "./NoTimesPopup";

const HomeWrapper = styled.div`
  padding: 25px 0 0 0;
  background: url("https://storage.googleapis.com/bat-meteor-game/Meteor_Roulette_1_D.png")
    no-repeat 100% 85px / contain;
  z-index: 1;
  position: relative;
  ${MEDIA_QUERY_MD} {
    padding: 16px 0 0 0;
    background: url("https://storage.googleapis.com/bat-meteor-game/Meteor_Roulette_1_M%403x.png")
      no-repeat 100% 72px / contain;
  }
`;

const Title = styled.h1`
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  background: url("https://storage.googleapis.com/bat-meteor-game/Meteor_Roulette_14_D.png");
  height: 85px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  ${MEDIA_QUERY_MD} {
    height: 51px;
    font-size: 26px;
    margin-bottom: 16px;
  }
`;
const RemainingTimes = styled.p`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding: 20px 0;
  color: #ec6e67;
  ${MEDIA_QUERY_MD} {
    font-size: 16px;
    padding: 16px 0;
  }
`;
const NowCoin = styled(RemainingTimes)`
  color: #666666;
`;
const DirectionBackgroundWrapper = styled.div`
  background-image: url("https://storage.googleapis.com/bat-meteor-game/Meteor_Roulette_4_D.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 1440px;
  ${MEDIA_QUERY_MD} {
    background: none;
  }
`;
const LotteryButtonFlex = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 21px;
  ${MEDIA_QUERY_MD} {
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
  }
`;
const LotteryNoTimesButton = styled.button`
  cursor: default;
  background-image: url(${lotteryNoTimesButtonPicture});
  background-size: cover;
  width: 156px;
  height: 60px;
  ${MEDIA_QUERY_MD} {
    width: 117px;
    height: 45px;
  }
`;
const LotteryFreeButton = styled.button`
  cursor: pointer;
  background-image: url(${lotteryFreeButtonPicture});
  background-size: cover;
  width: 156px;
  height: 60px;
  ${(props) =>
    props.isMobile
      ? `&:active{transform: translate(2px, 4px);}`
      : `&:hover {
    transform: translate(-2px, -4px);
  }`}

  ${MEDIA_QUERY_MD} {
    width: 117px;
    height: 45px;
  }
`;
const LotteryCostButton = styled.button`
  cursor: pointer;
  width: 156px;
  height: 60px;
  background: #ec6e67;
  border-radius: 30px;
  font-weight: 900;
  color: #fff;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.isMobile
      ? `&:active{transform: translate(2px, 4px);}`
      : `&:hover {
    transform: translate(-2px, -4px);
  }`}
  ${MEDIA_QUERY_MD} {
    width: 117px;
    height: 45px;
  }
`;
const LotteryCostButtonCost = styled.p`
  font-size: 16px;
  padding-bottom: 4px;
  text-align-last: justify;
  width: 80px;
  ${MEDIA_QUERY_MD} {
    font-size: 12px;
    width: 64px;
    padding-bottom: 3px;
  }
`;
const LotteryCostButtonSpan = styled.p`
  font-size: 20px;
  ${MEDIA_QUERY_MD} {
    font-size: 16px;
  }
`;
const LotteryRecordButton = styled(LotteryFreeButton)`
  background-image: url(${lotteryRecordButtonPicture});
  margin-left: 31px;
  ${MEDIA_QUERY_MD} {
    margin-left: 0px;
    margin-top: 10px;
  }
`;

const LotteryHint = styled.div`
  margin: 0 auto;
  background-image: url(${lotteryHintIcon});
  background-size: cover;
  width: 18px;
  height: 10px;
`;
const PopUpsMark = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.25);
`;
const RouletteHome = ({ data }) => {
  const {
    rouletteInfo,
    prizes,
    lotteryFreeTimes,
    userCoin,
    preAngle,
    rouletteRotate,
    handleChangeRouletteRotate,
    popupShow,
    handlePopupsRemove,
    lottery,
    handleRecord,
    lotteryRecord,
    errorMessage,
    canPlayTimes,
  } = data;
  return (
    <HomeWrapper>
      <BackgroundBottom />
      <Title>每日登入抽好禮</Title>
      <Roulette
        data={{
          preAngle,
          rouletteRotate,
          handleChangeRouletteRotate,
          rouletteInfo,
          prizes,
          lotteryFreeTimes,
        }}
      />

      {(lotteryFreeTimes || lotteryFreeTimes === 0) && (
        <>
          {lotteryFreeTimes > 0 ? (
            <RemainingTimes>免費抽獎: {lotteryFreeTimes}次</RemainingTimes>
          ) : (
            <NowCoin>目前擁有：${userCoin}M幣</NowCoin>
          )}
        </>
      )}
      <DirectionBackgroundWrapper>
        {preAngle && (
          <>
            <LotteryButtonFlex>
              {canPlayTimes <= 0 ? (
                <LotteryNoTimesButton
                  onClick={handleChangeRouletteRotate}
                  isMobile={isMobile}
                ></LotteryNoTimesButton>
              ) : (
                <>
                  {lotteryFreeTimes > 0 ? (
                    <LotteryFreeButton
                      onClick={handleChangeRouletteRotate}
                      isMobile={isMobile}
                    ></LotteryFreeButton>
                  ) : (
                    <LotteryCostButton
                      onClick={handleChangeRouletteRotate}
                      isMobile={isMobile}
                    >
                      <LotteryCostButtonCost>花300M幣</LotteryCostButtonCost>
                      <LotteryCostButtonSpan>再抽一次</LotteryCostButtonSpan>
                    </LotteryCostButton>
                  )}
                </>
              )}

              <LotteryRecordButton
                onClick={handleRecord}
                isMobile={isMobile}
              ></LotteryRecordButton>
            </LotteryButtonFlex>
            <LotteryHint></LotteryHint>
            <LotteryDirection descriptions={rouletteInfo?.instruction} />
          </>
        )}
      </DirectionBackgroundWrapper>
      <div className="">
        <div style={{ background: `url(${bottomBackground})` }}></div>
        <span style={{ background: "#fff" }}></span>
      </div>
      {popupShow === "result" && (
        <>
          <PopUpsMark onClick={handlePopupsRemove} />
          <LotteryPopUps
            handlePopupsRemove={handlePopupsRemove}
            item={lottery}
            errorMessage={errorMessage}
          />
        </>
      )}
      {popupShow === "noTimes" && (
        <>
          <PopUpsMark onClick={handlePopupsRemove} />
          <NoTimesPopup handlePopupsRemove={handlePopupsRemove} />
        </>
      )}
      {popupShow === "error" && (
        <>
          <PopUpsMark onClick={handlePopupsRemove} />
          <ErrorPopup
            handlePopupsRemove={handlePopupsRemove}
            errorMessage={errorMessage}
          />
        </>
      )}
      {popupShow === "record" && (
        <>
          <PopUpsMark onClick={handlePopupsRemove} />
          <RouletteRecord
            lotteryRecord={lotteryRecord}
            handlePopupsRemove={handlePopupsRemove}
          />
        </>
      )}
    </HomeWrapper>
  );
};

export default RouletteHome;
