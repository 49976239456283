import { useContext } from "react";
// import { isMobile } from "react-device-detect";
import { Girl2023InfoContext } from "store/contex";
import styled from "styled-components";
import Accordion from "components/vote/2023girl/Accordion";

const NoticeStyle = styled.div`
  position: relative;
  background-color: #FF55B3;
  padding-bottom: 144px;
  color: #FFF;
  .accordion-item {
    color: #FFF;
    font-family: "Noto Sans TC";
    ul {
      list-style-type: decimal;
      font-size: 22px;
      letter-spacing: 0.05em;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      li:first-child {
        padding-top: 32px;
      }
      li:last-child {
      }
      li:not(:first-child) {
      }
      li:not(:last-child) {
        padding-bottom: 20px;
      }
    }
    li {
      width: 626.59px;
    }
    .accordion-content {
      font-size: 0.875rem;
    }
  }
  @media (max-width: 640px) {
    padding-top: 64px;
    padding-bottom: 64px;
    .accordion-item {
      ul {
        font-size: 12px;
        li:first-child {
          padding-top: 20px;
        }
        li:not(:first-child) {
        }
        li:not(:last-child) {
          padding-bottom: 12px;
        }
        li:not(:last-child) {
        }
        li {
          width: 260px;
        }
        li:nth-child(n > 10) {
        }
      }
      .accordion-content {
        font-size: 12px;
      }
    }
  }
`;
export const Notice = () => {
  const { gameInfo } = useContext(Girl2023InfoContext);
  const accordionData = [
    {
      index: 1,
      title: "投票規範",
      content: (
        <ul className="">
          <li>登入通過審核的Meteor帳號(會員)每日可投3票</li>
          <li>使用FB登入(未通過審核的帳號)每日可投1票</li>
          <li>
            於活動頁右上方分享活動到Line或是FB，單日可增加一票，分享到兩個社群可得2票
          </li>
          <li>可重複投給同一位參賽者</li>
        </ul>
      ),
    },

    {
      index: 2,
      title: "注意事項",
      content: (
        
        <ul className=" ">
          <div
            className="sm:pt-[32px] sm:pb-[20px] pt-[20px] pb-[12px] sm:w-[645px] w-[280px]">
            本活動主辦單位係為Meteor學生社群（下稱主辦方），委託協辦單位烏龜移動科技股份有限公司辦理（下稱協辦單位）
          </div>
          <li>
            當您參加本活動時，即表示您已充分閱讀、瞭解並同意接受本活動辦法之所有內容。包含活動方法、參賽內容授權、免責聲明、個人資料之蒐集辦法、領獎規範等。如有違反本注意事項之內容者，主辦方得取消其參加或得獎者資格。
          </li>
          <li>
            參賽者若為未滿18歲之未成年人，應取得您的法定代理人或監護人之同意。法定代理人及監護人須充分瞭解本活動內容、活動條款等，參賽者方得參與本活動。當您參加本活動時，即視為您的法定代理人或監護人已同意並接受本活動之所有內容規範。
          </li>
          <li>
            對於個人資料，僅做為本活動使用，主辦方及協辦單位將盡保密之責，不做其他用途亦不會對外洩漏。詳細個人資料之規範詳閱此連結內容
            <a className="underline" href="https://wooo.tw/ys57Ppe">
              https://wooo.tw/ys57Ppe
            </a>
          </li>
          <li>
            主辦方保有審核參加資格之權利，若參賽者報名資格身分不符、使用不適當、違反公共秩序或社會善良風俗之照片，主辦方得逕自刪除其內容並取消參加或獲選資格，不另行通知。
          </li>
          <li>
            參賽者有權擔保於活動網站所上傳之所有內容（包括但不限於活動影片等），須為原創內容及參賽者個人所擁有，且該內容不得有抄襲、節錄、侵害第三人智慧財產權或其他任何權利（包括但不限於智慧財產權等）。
          </li>
          <li>
            參賽者若為違反其相關法令，除需自行向第三者負損害賠償責任，並與主辦方無涉。惟，若主辦方因此受有損害者，參賽者應對主辦方負損害賠償責任。
          </li>
          <li>
            參賽者同意上傳之所有內容授予一切必要權利予主辦方使用，且保證參與本活動期間時，其授權內容（如照片）不得再提供其他商業活動使用，且主辦方（即被授權方）得於本活動宣傳範圍內再轉授權予第三人利用，參賽者同意對主辦方或再授權之人不再行使著作人格權及肖像權。
          </li>
          <li>
            主辦方得於使用期限結束後，除參賽者以書面要求下架外，得免除下架。
          </li>
          <li>主辦方得於授權範圍內，得以著作財產權人之地位行使權利。</li>
          <li>
            主辦方有權使用參賽者於本活動上傳之照片進行行銷、宣傳用途。
          </li>
          <li>
            主辦方活動期間為維護比賽公平性，禁止網友發表不當言論內容、從事任何非法程式、假借不真實身分參加本活動，以及任何影響活動公平性或破壞本活動進行之規則，一旦經查證或告發，將取消其參加活動資格，並追究法律責任。
          </li>
          <li>
            主辦方保留擴大、修改、取消、終止該活動以及更改活動截止日期的權利，參賽者及投票者不得對主辦方主張任何權利。
          </li>
        </ul>
      ),
    },
    {
      index: 3,
      title: "領獎相關",
      content: (
        <ul className="">
          <li>得獎者有協助本活動冠名贊助廠商以及Meteor官方宣傳之義務。</li>
          <li>
            得獎者獲獎後須配合參與主辦方與冠名贊助廠商合作之行銷活動及宣傳影片拍攝事宜。
          </li>
          <li>
            得獎者獲獎後必須依Meteor官方指示在個人Instagram限時動態上分享官方指定三篇圖文。註:如得獎者未配合主辦方推廣，視為得獎者未盡得獎義務，將無法獲得該獎金，得獎者不得藉此向主辦方提出任何請求。
          </li>
          <li>
            截止投票後，將於本活動首頁公布得獎名單資訊並使用Meteor社群之站內信通知得獎者，票數最高前三名者，完成上述條件後可獲得獎金，並有機會洽談進一步的合作機會。
          </li>
          <li>
            比賽得獎名單公佈後，若得獎者未於指定之期限內回覆主辦方，將取消其資格，主辦方將以候補名額遞補。
          </li>
          <li>
            參加本活動即視為同意主辦方分享參加者之個人資料予協辦單位，作為本次活動聯繫、後續處理、及紀錄之用途。
          </li>
          <li>
            依中華民國稅法規定，中獎獎項價值超過NT$20,000者，當中獎人為中華民國境內居住之個人，須自行負擔贈品價值之10%稅金，並於領取贈品時以現金繳納，主辦方 / 協辦單位將於翌年依規定開立扣繳憑單予中獎人。主辦方 / 協辦單位對同一納稅義務人全年給付在NT$1,000以上未達NT$20,000者，依稅法規定需開立免扣繳憑單，並列單申報主管稽徵機關，須由中獎人自行申報繳納所得稅。當中獎人為非中華民國境內居住之個人，無論金額多寡，須自行負擔贈品價值之20%稅金，並於領取贈品時以現金繳納，主辦方 / 協辦單位將依規定開立扣繳憑單予中獎人。上述中獎人若未能依法繳納應繳稅額，即視為自動放棄中獎資格。參加本活動者須遵守所有中華民國稅法規定之法律稅捐義務，其義務概與主辦方 / 協辦單位無涉。前述稅捐法規如有更新或變動者，依修正後規定辦理。
          </li>
          <li>
            活動獎金匯款範圍僅限台、澎、金、馬地區。得獎者須於指定之期限內，填妥領據後寄回（寄回時間以郵戳為憑），並經由主辦方 / 協辦單位確認簽收。得獎人若逾指定之期限寄回，視為放棄得獎資格，主辦方 / 協辦單位不另行通知，得獎者不得藉此向主辦方 / 協辦單位提出任何請求。
          </li>
          <li>
            得獎者需確認其聯絡方式之正確性。若因資料錯誤，導致無法聯繫時，主辦方 / 協辦單位將不再另行補寄通知，該得獎者直接取消其資格，主辦方 / 協辦單位將以後補名額遞補，得獎者不得藉此向主辦方 / 協辦單位提出任何請求。
          </li>
        </ul>
      ),
    },
  ];

  return (
    <NoticeStyle
      id="notices" 
      className="relative sm:pt-[90px] pt-[45px]">
      <img
          src={
            gameInfo &&
            (gameInfo.setPictures.divider_D)
          }
           alt="divider"
           className="absolute z-10 sm:top-[-9vw] top-[-18vw] w-screen left-[50%] -translate-x-[50%]"
        />
      <div className="sm:w-[656px] w-[280px] z-20 relative m-auto">
        {accordionData.map(({ title, content, index }) => (
          <Accordion
            key={title}
            index={index}
            title={title}
            content={content}
          />
        ))}
      </div>
    </NoticeStyle>
  );
};
