import { METEORURL, SUPERPOWERS_GAME_ID } from "config/CONSTANTS";
import styled from "styled-components";
import gtag from "ga-gtag";

const SignUpStyle = styled.div`
  background: #f3d353;
  border: 2px solid #1d1742;
  width: 90px;
  height: 90px;
  font-size: 22px;
  color: #1d1742;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 2.778%;
  top: 60%;
  border-radius: 100%;
  box-shadow: 0 0 10px 0 rgba(243, 211, 83, 0.4);
  cursor: pointer;
  z-index: 999;
  @media (max-width: 640px) {
    width: 70px;
    height: 70px;
    border: 1.56px solid #1d1742;
    font-size: 17px;
    font-weight: 900;
    box-shadow: 0 0 7.78px 0 rgba(243, 211, 83, 0.4);
  }
`;
const SignUpButton = (props) => {
  const { singUpModalHandler, userSession } = props;

  const singUpClickHandler = () => {
    if (userSession) {
      // ga4 api
      gtag("event", "點擊報名-浮動icon", {
        gameId: SUPERPOWERS_GAME_ID,
        userSession: userSession,
        send_to: "G-SR7XCS1GYX",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-浮動icon",
        gameId: SUPERPOWERS_GAME_ID,
        userSession: userSession,
      });

      const data = {
        userId: userSession,
        gameId: SUPERPOWERS_GAME_ID,
      };
      fetch(`${METEORURL}/gameelection/get_my_player_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            alert("您已經報名囉");
            return;
          } else {
            singUpModalHandler(true);
          }
        });
    } else if (!userSession) {
      // ga4 api
      gtag("event", "點擊報名-浮動icon", {
        gameId: SUPERPOWERS_GAME_ID,
        userSession: "未登入",
        send_to: "G-SR7XCS1GYX",
      });

      // bat api
      window.bat.track("buttonClick", {
        buttonNm: "點擊報名-浮動icon",
        gameId: SUPERPOWERS_GAME_ID,
        userSession: "未登入",
      });

      alert("請先登入");
      window.location.href = `${METEORURL}/login?next=${window.location.href}`;
      return;
    } else {
      singUpModalHandler(true);
    }
  };

  return (
    <SignUpStyle onClick={singUpClickHandler}>
      <span>點我</span>
      <span>報名</span>
    </SignUpStyle>
  );
};

export default SignUpButton;
