import { memo } from "react";
import styled from "styled-components/macro";
import { flicker } from "./animation";
import { MEDIA_QUERY_MD } from "assets/styles/responsive";
import rouletteWrapperPicture from "assets/playground/roulette/Desktop/Meteor_Roulette_8_D.png";

import rouletteCenterWrapper from "assets/playground/roulette/Desktop/Meteor_Roulette_12_D.png";

const Wrapper = styled.div`
  overflow: hidden;
  z-index: 5;
`;
const RouletteWrapper = styled.div`
  background-image: url(${rouletteWrapperPicture});
  background-size: cover;
  width: 428px;
  height: 428px;
  z-index: -2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  ${MEDIA_QUERY_MD} {
    width: 315px;
    height: 315px;
  }
  &:before {
    content: "";
    animation: ${flicker} 0.4s linear infinite;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
`;
const RouletteBodyWrapper = styled.div`
  transform: rotate(-${(props) => props.removeAngle}deg);
  width: 352px;
  height: 352px;
  z-index: -2;
  border-radius: 50%;
  ${MEDIA_QUERY_MD} {
    width: 259.07px;
    height: 259.07px;
  }
`;
const RouletteBody = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 4px #e2c438;
  transform: rotate(-${(props) => props.angle}deg);
  transition: all 4000ms cubic-bezier(0, 0, 0.2, 1);
  &:before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    background: #f4da41;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 50%;
  }
`;
const Fan = ({ wrapperAngle, innerAngle, dark }) => {
  return (
    <FanWrapper angle={wrapperAngle}>
      <Inner angle={innerAngle} dark={dark} />
    </FanWrapper>
  );
};
const FanWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
  transform: rotate(${(props) => props.angle}deg);
  z-index: -1;
`;
const Inner = styled.div`
  width: 50%;
  height: 100%;
  transform-origin: right center;
  border-right: 4px solid #e2c438;
  z-index: 5;
  transform: rotate(${(props) => props.angle}deg);
  display: flex;
  justify-content: flex-end;
`;
const LotteryContentCon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 100%;
`;

const LotteryContent = ({ angle, item }) => {
  return (
    <LotteryContentWrapper angle={angle}>
      <LotteryName>{item.name}</LotteryName>
      <LotteryImg img={item.imgurUrl} />
    </LotteryContentWrapper>
  );
};
const LotteryContentWrapper = styled.div`
  transform: rotate(${(props) => props.angle}deg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const LotteryName = styled.p`
  margin: 12px 0 8px 0;
  font-size: 18px;
  width: 30%;
  text-align: center;
  color: #333333;
  font-weight: 700;
  line-height: 1.2;
  ${MEDIA_QUERY_MD} {
    width: 30%;
    font-size: 13px;
    margin: 8px 0 5px 0;
  }
`;
const LotteryImg = styled.div`
  background: ${(props) => (props.img ? `url(${props.img})` : "gray")};
  width: 35px;
  height: 35px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  ${(props) => !props.img && "border-radius: 50%;"}
  ${MEDIA_QUERY_MD} {
    width: 23px;
    height: 23px;
  }
`;
const RouletteCenter = ({
  handleChangeRouletteRotate,
  lotteryTimes,
  rouletteInfo,
}) => {
  return (
    <RouletteCenterWrapper>
      <RouletteCenterLogo
        img={rouletteInfo?.icon}
        dark={lotteryTimes <= 0}
      ></RouletteCenterLogo>
    </RouletteCenterWrapper>
  );
};
const RouletteCenterWrapper = styled.div`
  width: 120px;
  height: 141px;
  background-image: url(${rouletteCenterWrapper});

  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(-1.56px 1.56px 3.12px rgba(0, 0, 0, 0.3));
  ${MEDIA_QUERY_MD} {
    width: 88.32px;
    height: 103.77px;
  }
`;
const RouletteCenterLogo = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 50%;
  ${(props) => props.img && `background-image:url(${props.img})`};
  background-size: cover;
  background-repeat: none;
  background-position: center;
  background-repeat: no-repeat;
`;

const Roulette = ({ data }) => {
  const {
    preAngle,
    rouletteRotate,
    handleChangeRouletteRotate,
    prizes,
    lotteryFreeTimes,
    rouletteInfo,
  } = data;

  return (
    <>
      {preAngle && (
        <Wrapper>
          <RouletteWrapper>
            <RouletteBodyWrapper removeAngle={preAngle / 2}>
              <RouletteBody angle={rouletteRotate}>
                {prizes.map((item, i) => (
                  <Fan
                    wrapperAngle={preAngle * i}
                    innerAngle={preAngle}
                    key={i}
                    dark={
                      item.limit !== "unlimited" && item.limit <= item.obtion
                    }
                  />
                ))}
                <LotteryContentCon>
                  {prizes.map((item, i) => (
                    <LotteryContent
                      angle={preAngle * i + preAngle / 2}
                      item={item}
                      key={i}
                    />
                  ))}
                </LotteryContentCon>
              </RouletteBody>
            </RouletteBodyWrapper>
            <RouletteCenter
              handleChangeRouletteRotate={handleChangeRouletteRotate}
              lotteryTimes={lotteryFreeTimes}
              rouletteInfo={rouletteInfo}
            />
          </RouletteWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default memo(Roulette);
