import { useState, useContext, useEffect } from "react";
// import { useState, useContext, useEffect, useRef } from "react";
import { getCandidatesModal } from "services/vote/2023superpowers";
import { Girl_2023_ID } from "config/CONSTANTS";
import { Girl2023InfoContext } from "store/contex";
import { isMobile } from "react-device-detect";
import Slider from "react-slick";
import styled from "styled-components";
import Modal from "components/vote/Modal";
import CloseButton from "components/vote/2023girl/CloseButton";
import VoteButton from "components/vote/2023girl/VoteButton";
import IGSvg from "assets/icon/IGSvg.js";
import TTSvg from "assets/icon/TTSvg.js";
import avatarLine from "assets/vote/2023girl/DESKTOP/avatarLine.png"
import avatarLineUp from "assets/vote/2023girl/DESKTOP/avatarLineUp.png"
import avatarLineDown from "assets/vote/2023girl/DESKTOP/avatarLineDown.png"
import gtag from "ga-gtag";
import ArrowDSvg from "assets/icon/ArrowDSvg.js";
import ClickSvg from "assets/icon/ClickSvg.js";

const maxPlayerCount = 12;

const cssStyles = {
  roundedBorder: 'border-black-11 rounded-full sm:border-[2px] border-[1px] sm:h-[50px] h-[28px] sm:leading-[36px] leading-normal pl-[12px] sm:pl-[18px] sm:font-bold font-black',
  filterFont: ''
}

const ArrowWrapper = styled.div`
  position: absolute;
  right: 10px;
  svg {
    height: 12px;
    width: 20px;
    @media (max-width: 640px) {
      height: 8px;
      width: 14px;
    }
  }
`;
const ClickWrapper = styled.div`
  position: absolute;
  display: none;
  width: auto;
  right: 0px;
  top: 8px;
  @media (max-width: 640px) {
    display: block;
  }
`;
const SortOption = styled.div`
  position: absolute;
  width: 176px;
  border: 2px solid #000;
  border-radius: 26px;
  bottom: -158px;
  left: 0px;
  background: #FFF;
  padding: 4px;
  z-index: 20;
  line-height: normal;
  &>div {
    width: 100%;
    padding: 5px 16px;
    background-color: none;
    border-radius: 25.5px;
    @media (min-width: 640px) {
      &:hover {
        background-color: #FF55B3;
        color: #FFF;
      }
    }
  }
  @media (max-width: 640px) {
    height: 79px;
    width: 116px;
    bottom: -86px;
    border-width: 1px;
    border-radius: 14px;
    padding: 3px 12px;
    .activeSort {
      color: #FF55B3;
    }
    &>div {
      padding: 3px 0px;
    }
  }
  .none {
    display: none;
  }
`;

const slideSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const CandidatesStyle = styled.div`
  position: relative;
  overflow-anchor: none;
  background-repeat: repeat-y;
  background-size: 100%;
  width: 100vw;
  padding-bottom: 160px;
  background-image: url(${(props) => props.backgroundImage});
`;

const CandidatesModal = styled.div`
  -webkit-overflow-scrolling: auto;
  position: relative;
  min-height: 627px;
  width: 73.6vw;
  border-radius: 40px;
  background: #FFF;
  border: 6px solid #FF55B3;
  padding: 5.5vw 6.1vw;
  margin: 20px;
  @media (max-width: 1200px) {
    height: auto;
    max-height: 90vh;
    width: 90vw;
  }
  @media (max-width: 640px) {
    min-height: ${isMobile ? ' 80vh;' : '627px;' }
    width: 83.7vw;
    border-width: 4px;
    border-radius: 20px;
    padding: 4vh 13vw 60px; 
    margin: 10px;
    max-height: 95vh;
    overflow-y: auto;
  }
  // 照片按鈕顏色
  li.slick-active button {
    background-color: #383EFF;
  }
  #voteBtn:hover {
    background-color: #E9F800;
    transition: background-color 0.3s ease-out;
  }
  .slick-next::before,
  .slick-prev::before {
    color: #FFFFFF;
    font-size: 30px;
  }
  .slick-next {
    margin-right: 45px;
  }
  .slick-prev {
    margin-left: 40px;
    z-index: 1
  }
  @media (max-width: 640px) {
    .slick-next {
    margin-right: 35px;
  }
  .slick-prev {
    margin-left: 30px;
  }
  }
`;

const InfoLink = styled.div`
  svg {
    width: 50px;
    height: 50px;
  }
  svg path {
    fill: #383EFF;
  }
  cursor: pointer;
  &:hover > svg path {
    fill: #E9F800;
    transition: fill 0.3s ease-out;
  }
  @media (max-width: 640px) {
    svg {
      width: 36px;
      height: 36px;
    }
  }
`
const styles = {
  headerStyle: 'text-[16px] sm:text-[24px] leading-normal font-black',
}

const ZoomIcon = styled.svg`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 13px;
  right: 10px;
  paths {
    fill: #000;
  }
  @media (max-width: 640px) {
    width: 13px;
    height: 13px;
    top: 8px;
    right: 6px;
  }
`
const TopBGImg = styled.img`
  position: absolute;
  width: 8.5vw;
  right: 0;
  top: 32px;
  @media (max-width: 640px) {
    width: 15.7vw;
    top: 0;
  }
`
const BottomBGImg = styled.img`
  position: absolute;
  width: 8.5vw;
  left: 0;
  bottom: 200px;
  @media (max-width: 640px) {
    width: 15.7vw;
    bottom: 160px;
  }
`

export const CandidatesList = (props) => {
  const { gameInfo } = useContext(Girl2023InfoContext);
  const {
    refIFrame,
    initCandidates,
    isAnnounced,
    userSession,
  } = props;

  const [showedPlayers, setShowedPlayers] = useState([]);
  const [sortedPlayers, setSortedPlayers] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [isProfileOpen, setOpenProfileModal] = useState(false);
  const [playerModalInfo, setPlayerModalInfo] = useState(null);
  const [sortValue, setSortValue] = useState(localStorage.getItem("localSortValue"))
  const [isOpenSortBox, setIsOpenSort] = useState(false)
  const [query, setQuery] = useState("");
  const [searchParam] = useState(["entryNumber", "nickname", "school"]);
  const [showNumber, setShowNumber] = useState(1);
  const [filteredPlayers, setFilteredPlayers]  = useState([]);
  const [isCompositionEnd, setIsCompositionEnd] = useState(false);
  const clickModalHandler = (playerId) => {
    getCandidatesModal(playerId)
      .then((res) => {
        setPlayerModalInfo(res.player);
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
  };

  useEffect(() => {
    setShowedPlayers(
      sortedPlayers
        .slice(0, showNumber * maxPlayerCount))
  }, [showNumber, sortedPlayers]);
  useEffect(() => {
    // 偵測選手是否載入
    if(!initCandidates || !initCandidates.length) return
    // when reload initCandidates
    // 找到前三名 放入參數
    if (isAnnounced) {
      initCandidates.sort((a, b) => b.voteCount - a.voteCount)
      for (let i = 0; i < 3; i++ ) {
        initCandidates[i].rank = i +1
      }
    }

    setFilteredPlayers(query?
      candidatesFilter(initCandidates, query) : [...initCandidates]
      )
    setIsCompositionEnd(true);
    // 若選手資訊彈窗開啟，則更新票數
    if (isProfileOpen) {
      const player = initCandidates.find(player => player.playerId === modalData.playerId)
      modalData.voteCount = player? player.voteCount : modalData.voteCount
    }
  }, [initCandidates, isAnnounced]);

  useEffect(() => {
    if (!filteredPlayers || !filteredPlayers.length) return
    if (!sortValue) {
      setSortValue('id')
    }
    localStorage.setItem("localSortValue", sortValue)
    switch (sortValue) {
      case 'id':
        setSortedPlayers(filteredPlayers.sort((a, b) => {
            return a.entryNumber - b.entryNumber
          }))
        break
      case 'voteDes':
        setSortedPlayers(
          filteredPlayers.sort((a, b) => {
            return b.voteCount === a.voteCount ? a.entryNumber - b.entryNumber : b.voteCount - a.voteCount;
          })
        );
        break
      case 'votAsc':
        setSortedPlayers(
          filteredPlayers.sort((a, b) => {
            return b.voteCount === a.voteCount ? a.entryNumber - b.entryNumber : a.voteCount - b.voteCount;
          })
        );
        break
      default:
        setSortedPlayers(filteredPlayers.sort((a, b) => {
            return a.entryNumber - b.entryNumber
          })
        )
      break
    }
    setShowedPlayers(sortedPlayers.slice(0, showNumber * maxPlayerCount));

  },[sortValue, filteredPlayers])

  useEffect(() => {
    if(!initCandidates || !isCompositionEnd) return

    if (isCompositionEnd && query) {
      setFilteredPlayers(
        candidatesFilter(initCandidates, query)
      );
    } else {
      setFilteredPlayers([...initCandidates]);
    }
    setShowNumber(1);

  }, [query, isCompositionEnd]);

  const candidatesFilter = (candidates, text)=> {
    return candidates.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) > -1
        );
      });
    })
  }

  const handlingComposition = () => {
    setIsCompositionEnd(false);
  };
  const handleComposition = (e) => {
    setIsCompositionEnd(true);
  };
  const changeSortValueToString = (value) => {
    switch (value) {
      case 'id':
        return '參賽者號碼'
      case 'voteDes':
        return '票數由高至低'
      case 'votAsc':
        return '票數由低至高'
      default:
        return '參賽者號碼'
    }
  }

  const candidatesModalHandler = () => {
    setOpenProfileModal((preState) => !preState);
  };

  const voteHandler = (player) => {
    // ga4 api
    gtag("event", "點擊投票", {
      gameId: Girl_2023_ID,
      userSession: userSession,
      send_to: "G-NQTWZ5RW0Y",
    });

    // bat api
    window.bat.track("buttonClick", {
      buttonNm: "點擊投票",
      gameId: Girl_2023_ID,
      userSession: userSession,
    });

    // line tracking
    window._lt("send", "cv", { type: "vote" }, [
      "4255d428-e2bb-4c10-8a89-562815a6d9de",
    ]);
    gtag("event", "conversion", {
      send_to: "AW-862236629/Sv2uCKfJwdwDENXfkpsD",
    });

    const iframeItem = refIFrame.current;
    iframeItem.contentWindow.postMessage(
      JSON.stringify({
        method: "add_vote",
        data: {
          gameId: Girl_2023_ID,
          playerId: player.playerId,
        },
      }),
      "*")
  };

  return (
    <CandidatesStyle
      className="xl:px-[6.1vw] px-[3.7vw] relative">
      <picture>
        <TopBGImg
          alt=""
          src={gameInfo &&
            (window.innerWidth >= 640
            ? gameInfo.setPictures.background_2_D
            : gameInfo.setPictures.background_2_M)}
        />
        <BottomBGImg
          alt=""
          src={gameInfo &&
            (window.innerWidth >= 640
            ? gameInfo.setPictures.background_2_D
            : gameInfo.setPictures.background_2_M)}
        />
      </picture>
      <div className="w-[100%] m-auto lg:px-[116px] sm:px-[58px] px-[5.3vw] sm:py-[80px] py-[30px] bg-[#FFF] sm:border-[8px] border-[5px] border-pink-11 sm:rounded-[40px] rounded-[20px] z-10 relative">
        <div className="flex h-full justify-between text-12px sm:text-[18px]  sm:font-bold font-black text-black-11">
          {/* 上方 - 排序 */}
          <div className="flex items-center">
            <div className="md:mr-[12px] md:block hidden">排序</div>
            <div
              className={`selectStyle relative ${cssStyles.roundedBorder} sm:w-[176px] w-[70px] flex items-center`}
              onClick={() => {
                setIsOpenSort((preState) => !preState);
              }}>
                <span className="sm:block hidden">
                  {changeSortValueToString(sortValue)}
                </span>
                <span className="sm:hidden block">
                  排序
                </span>
                {/* 箭頭 */}
                {isOpenSortBox? (
                  <>
                    <ArrowWrapper
                      className="rotate-180">
                      <ArrowDSvg
                        fill={'#000'}/>
                    </ArrowWrapper>
                    <SortOption>
                      {['id', 'voteDes', 'votAsc'].map(
                        (sort) => {
                        return (
                          <div
                            key={sort}
                            className={`${sortValue === sort ? 'activeSort' : ''} relative`}
                            onClick={() => setSortValue(sort)}>
                            {changeSortValueToString(sort)}
                            <ClickWrapper
                              className={`${sortValue === sort ? '' : 'none'}`}>
                              <ClickSvg
                                fill={'#FF55B3'}/>
                            </ClickWrapper>
                          </div>
                        )
                      })}
                      {/* <div
                        onClick={() => setSortValue('id')}>
                        {changeSortValueToString('id')}
                      </div>
                      <div
                        onClick={() => setSortValue('voteDes')}>
                        {changeSortValueToString('voteDes')}
                      </div>
                      <div
                        onClick={() => setSortValue('votAsc')}>
                        {changeSortValueToString('votAsc')}
                      </div> */}
                    </SortOption>
                  </>
                ) : (
                  <>
                    <ArrowWrapper>
                      <ArrowDSvg
                        fill={'#000'}/>
                    </ArrowWrapper>
                  </>
                )}
            </div>
          </div>
          {/* 上方 - 搜尋 */}
          <div className="relative w-[214px] sm:w-[280px] z-10">
              <input
                className={`w-full bg-transparent placeholder:text-black-11 outline-none pr-[26px] sm:pr-[44px] ${cssStyles.roundedBorder}`}
                type="text"
                placeholder="輸入編號 / 暱稱 / 學校"
                value={query}
                id="search"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onCompositionStart={handlingComposition}
                onCompositionUpdate={handlingComposition}
                onCompositionEnd={() => {handleComposition()}}
              />
              <ZoomIcon
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <path
                  d="M21.7099 20.2899L17.9999 16.6099C19.44 14.8143 20.1374 12.5352 19.9487 10.2412C19.76 7.94721 18.6996 5.81269 16.9854 4.27655C15.2713 2.74041 13.0337 1.91941 10.7328 1.98237C8.43194 2.04534 6.24263 2.98747 4.61505 4.61505C2.98747 6.24263 2.04534 8.43194 1.98237 10.7328C1.91941 13.0337 2.74041 15.2713 4.27655 16.9854C5.81269 18.6996 7.94721 19.76 10.2412 19.9487C12.5352 20.1374 14.8143 19.44 16.6099 17.9999L20.2899 21.6799C20.3829 21.7736 20.4935 21.848 20.6153 21.8988C20.7372 21.9496 20.8679 21.9757 20.9999 21.9757C21.1319 21.9757 21.2626 21.9496 21.3845 21.8988C21.5063 21.848 21.6169 21.7736 21.7099 21.6799C21.8901 21.4934 21.9909 21.2442 21.9909 20.9849C21.9909 20.7256 21.8901 20.4764 21.7099 20.2899ZM10.9999 17.9999C9.61544 17.9999 8.26206 17.5894 7.11091 16.8202C5.95977 16.051 5.06256 14.9578 4.53275 13.6787C4.00293 12.3996 3.86431 10.9921 4.13441 9.63427C4.4045 8.27641 5.07119 7.02912 6.05016 6.05016C7.02912 5.07119 8.27641 4.4045 9.63427 4.13441C10.9921 3.86431 12.3996 4.00293 13.6787 4.53275C14.9578 5.06256 16.051 5.95977 16.8202 7.11091C17.5894 8.26206 17.9999 9.61544 17.9999 10.9999C17.9999 12.8564 17.2624 14.6369 15.9497 15.9497C14.6369 17.2624 12.8564 17.9999 10.9999 17.9999Z"
                />
              </ZoomIcon>
          </div>
        </div>
        <div className="grid md:grid-cols-3 grid-cols-2 gap-x-[6vh] sm:gap-y-[64px] gap-y-[32px] sm:pt-[40px] pt-[32px] sm:py-[64px] py-[32px]  grid-auto-columns min-h-[532px]">
          {showedPlayers.map((candidate) => (
            <div
              key={candidate.playerId}>
                <div
                  className={`${styles.headerStyle} pb-[4px] sm:pb-[8px]`}>
                  NO.{candidate.entryNumber}
                </div>
                <div
                  onClick={() => {
                    setOpenProfileModal((preState) => !preState);
                    setModalData(candidate);
                    clickModalHandler(candidate.playerId);
                  }}
                  className="w-full aspect-square relative sm:mb-24px mb-13px cursor-pointer group">
                  <img
                    className="w-full aspect-square"
                    src={`${candidate.playerPhotos[0] }`}
                    alt="avatar"/>
                  { candidate.rank ? (
                    <div
                      className="absolute z-10 sm:left-[-17px] left-[-10px] sm:bottom-[16px] bottom-[4px] sm:w-[127px] w-[74px] sm:h-[65px] h-[37px]">
                      <img
                        src={gameInfo.setPictures.crown_D}
                        alt="avatarLine"
                        className="w-full"/>
                      <span
                        className="absolute text-[#FFF] sm:text-[24px] text-[14px] font-black sm:top-[24px] sm:left-[15px] top-[15px] left-[10px] sm:tracking-[1.92px] tracking-[1.12px]">
                        第
                        {candidate.rank === 1 ? '一' : candidate.rank === 2 ? '二' : '三'}
                        名
                      </span>
                    </div>
                  ) : (
                    <img
                    src={avatarLine}
                    alt="avatarLine"
                    className="absolute z-10 bottom-[50%] left-[50%] max-w-[507px] w-[117%] translate-y-[50%] -translate-x-[50%]"/>
                  )
                }
                </div>
                <div className="flex justify-between">
                  <div
                    onClick={() => {
                      candidatesModalHandler();
                      setModalData(candidate);
                      clickModalHandler(candidate.playerId);
                    }}
                    className={`${styles.headerStyle} cursor-pointer`}>
                      {candidate.nickname}
                  </div>
                  <div className="text-pink-11">
                    <span className={`${styles.headerStyle}`}>
                      {String(candidate.voteCount).replace(
                        /(\d)(?=(\d{3})+$)/g,
                        "$1,"
                      )}
                    </span>
                    <span className="sm:ml-[8px] ml-[4px] text-[10px] sm:text-[18px] text-[#FF55B3] font-bold">票</span>
                  </div>
                </div>
                <div className={`flex justify-between mt-[10px] items-center`}>
                  <div className="whitespace-pre-line sm:text-[18px] text-[10px] font-bold">
                    {candidate.school.replace("(", " \u000A (")}
                  </div>
                  <VoteButton
                    voteHandler={() => voteHandler(candidate)}
                  />
                </div>
            </div>
          ))}
          <Modal
            handleClose={() => setOpenProfileModal(false)}
            isOpen={isProfileOpen}
          >
            {playerModalInfo && (
              <CandidatesModal>
                <CloseButton
                  handleClose={() => setOpenProfileModal(false)} />
                <div className="block sm:flex h-full sm:w-full w-full mx-auto relative sm:justify-center scroll font-NotoSansTC sm:pb-[16px] pb-[14px] sm:mt-0 mt-[6vh]">
                   {/* 個人頭貼 - 左 */}
                  <div
                    id="avatarName"
                    className="flex-none">
                    <div
                      className="w-[213px] aspect-square mb-[20px] sm:w-[250px] lg:w-[342px] mx-auto  cursor-pointer relative">
                      <img
                        src={avatarLineUp}
                        alt="avatarLineUp"
                        className="absolute z-20 w-[62px] top-[-11px] right-[-18px]"/>
                      <img
                        src={avatarLineDown}
                        alt="avatarLineDown"
                        className="absolute z-20 w-[71px] bottom-[-1px] left-[-18px]"/>
                      <Slider {...slideSettings}
                        className=" li.slick-active">
                        {/* {testPhoto.map((item) => { */}
                        {playerModalInfo.playerPhotos.map((item) => {
                          return (
                            item && (
                              <div key={item}>
                                {/* w-[213px] h-[213px]  */}
                                <div
                                  className="w-[213px] aspect-square
                                  sm:w-[250px] lg:w-[342px]  overflow-hidden"
                                  style={{
                                    backgroundImage: `url(${item})`,
                                    backgroundPosition: "center center",
                                    backgroundSize: "cover",
                                  }}
                                ></div>
                              </div>
                            )
                          );
                        })}
                      </Slider>
                    </div>
                    <div
                      className="flex justify-between sm:text-[32px] text-[20px] font-black">
                      <div>
                        {modalData.nickname} 
                      </div>
                      <div
                        className="text-pink-11">
                        {String(modalData.voteCount).replace(
                          /(\d)(?=(\d{3})+$)/g,
                          "$1,"
                        )}
                        <span
                          className="sm:text-[24px] text-[14px] font-bold sm:ml-[11px] ml-[4px]">
                          票
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* 個人資訊 - 右 */}
                  <div
                    id="infos"
                    className="flex-auto sm:pl-[7.2vw] pl-0 text-[#000] font-bold sm:text-[24px] text-[14px] leading-normal sm:min-h-[380px] h-auto">
                    <div
                      className="sm:contents absolute top-[-7%] left-[41%] text-blue-11 sm:text-[32px] text-[18px] font-black">
                      No.{modalData.entryNumber}
                    </div>
                    <div
                      className="mt-[9px] sm:mb-[12px] mb-[8px] sm:mt-[16px]">
                      學校：{" " + modalData.school.replace("(", " \u000A (")}
                    </div>
                    <div
                      className="sm:mb-[5px] mb-[0px]">
                      心目中的女神：{playerModalInfo?.info?.心目中的女神 || '楊秀蕙' }
                    </div>
                    <div className="flex items-center leading-loose">
                      <span>社群：</span>
                      {playerModalInfo.socialLinks.instagram ? (
                        <InfoLink
                          onClick={ () => openLink(`https://www.instagram.com/${playerModalInfo.socialLinks.instagram}`)}>
                          <IGSvg />
                        </InfoLink>
                      ) : ''}
                      {playerModalInfo.socialLinks.tikTok ? (
                        <InfoLink
                          onClick={ () => openLink(`https://www.tiktok.com/@${playerModalInfo.socialLinks.tikTok}`)}>
                          <TTSvg />
                        </InfoLink>
                      ) : ''}
                    </div>
                    <hr className="py-[1px] bg-blue-11 sm:mt-[24px] sm:mb-[32px] mt-[10px] mb-[16px]" />
                    <div className="sm:mb-[12px] mb-[4px]">
                      女神定義：
                    </div>
                    <div
                      className="sm:text-[20px] text-[12px] font-normal w-full">
                       {playerModalInfo.intro}
                    </div>
                  </div>
                </div>
                {/* 投票按鈕 */}
                <div>
                  <button
                    id="voteBtn"
                    onClick={() => voteHandler(modalData)}
                    className="sm:inline-block sm:w-[94px] sm:h-[47px] absolute w-[73px] h-[32px] sm:bottom-[58px] bottom-[24px] sm:text-center bg-blue-11 sm:rounded-[12px] rounded-[8px] sm:text-[24px] text-18px font-NotoSansTC font-black text-[#FFF] lg:ml-[210px] sm:ml-[145px]
                    left-[50%] translate-x-[-50%] sm:translate-x-[0] sm:left-0">
                    投票
                  </button>
                </div>
              </CandidatesModal>
            )}
          </Modal>
        </div>
        {filteredPlayers.length !== 0 &&
          showNumber < filteredPlayers.length / maxPlayerCount && (
          <div className="flex justify-center w-full">
            <button
              className="w-[98px] sm:w-[160px] h-[28px] sm:h-[47px] sm:rounded-[12px] rounded-[8px] text-[14px] sm:text-[24px] text-white-1 bg-pink-11 flex justify-center font-NotoSansTC font-black items-center"
              onClick={() => {
                setShowNumber(showNumber + 1);
              }}>
              看更多女神
            </button>
          </div>
        )}
      </div>
    </CandidatesStyle>
  );
};

const openLink = (link) => {
  window.open(link);
};
