import { useContext } from "react";
import { UniformGameInfoContext } from "store/contex";
import activities_title_pic_D from "assets/vote/2022uniform/DESKTOP/title_1_D.png";
import activities_title_pic_M from "assets/vote/2022uniform/MOBILE/title_1_M.png";
import activities_content_pic_D from "assets/vote/2022uniform/DESKTOP/RULES_D.png";
import activities_content_pic_M from "assets/vote/2022uniform/MOBILE/RULES_M.png";
import { BackgroundPicture } from "components/vote/BackgroundPicture";
import hero_blue_1_D from "assets/vote/2022uniform/DESKTOP/BLUE_UPSIDEDOWN_D.png";
import styled from "styled-components";

const ActiveContainerStyle = styled.div`
  position: relative;
  background-image: url(${(props) => props.image});
  background-size: 50%;
  padding: 70px 0 100px 0;
  @media (max-width: 640px) {
    padding: 40px 0 40px 0;
  }
`;

export const Activities = () => {
  const { gameInfo } = useContext(UniformGameInfoContext);
  return (
    // <ActiveContainerStyle
    //   image={gameInfo && gameInfo.setPictures.background_2_D}
    // >
    <ActiveContainerStyle
      image={
        gameInfo &&
        (window.innerWidth >= 640
          ? gameInfo.setPictures.background_2_D
          : gameInfo.setPictures.background_2_M)
      }
    >
      <img
        src={hero_blue_1_D}
        alt="Meteor2022 高校制服大賞藍色一"
        className="absolute top-[6.5%] right-[0] max-w-[333px] w-[23.125%] translate-x-[20%] md:block hidden"
      />
      <div className="sm:w-[75%] w-full m-auto relative">
        <picture className="flex flex-col items-center">
          <img
            src={
              window.innerWidth >= 640
                ? activities_title_pic_D
                : activities_title_pic_M
            }
            alt="2022高校制服大賞活動辦法標題"
            // width={"100%"}
            className="sm:w-[78.2%] w-full sm:mb-[120px] mb-[36px]"
          />
          <img
            src={
              window.innerWidth >= 640
                ? activities_content_pic_D
                : activities_content_pic_M
            }
            alt="2022高校制服大賞活動辦法"
            // width={window.innerWidth >= "640px" ? "85%" : "100%"}
            className="sm:w-[85%] w-100%"
          />
        </picture>
      </div>
      {/* {window.innerWidth >= 751 ? (
          <BackgroundPicture
            src={gameInfo && gameInfo.setPictures.gameIntro_D}
            alt="2022高校制服大賞活動辦法"
          />
        ) : (
          <BackgroundPicture
            src={gameInfo && gameInfo.setPictures.gameIntro_M}
            alt="2022高校制服大賞活動辦法"
          />
        )} */}
    </ActiveContainerStyle>
  );
};
