import { useState } from "react";
import { SUPERPOWERS_GAME_ID } from "config/CONSTANTS";
import { uploadImage, singUpForm } from "services/vote/2023superpowers";
import upload from "assets/vote/2023superpowers/DESKTOP/upload.png";
import uploadHover from "assets/vote/2023superpowers/DESKTOP/upload_hover.png";
import { useForm } from "react-hook-form";
import gtag from "ga-gtag";

import Modal from "components/vote/Modal";
import Alert from "components/vote/Alert";

import CloseButton from "components/vote/2023super/CloseButton";
import useVideoTokenMessage from "hooks/vote/useVideoTokenMessage";
import { useEffect } from "react";
import { useRef } from "react";
import { videoUploadServices } from "services/vote/2023superpowers/videoUploadServices";
import Video from "./Video";
import Loading from "components/vote/Loading";
const styles = {
  input:
    "text-black-4 outline-none border-none rounded-full appearance-none relative block w-full px-[12px] py-[8px]  placeholder-black-6  rounded-30px text-base",
  error: "absolute left-12px -bottom-[20px] text-red-500 text-xs italic",
};
const SignUp = (props) => {
  const [uploadImageSize, setUploadImageSize] = useState(false);
  const uploadImageModalHandler = () => {
    setUploadImageSize((preState) => !preState);
  };
  const videoRef = useRef(null);
  const [videoHeaderId, setVideoHeaderId] = useState(null);
  const [videoFile, setVideoFile] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [singUpErrorMessage, setSingUpErrorMessage] = useState([]);
  const [videoLoading, setVideoLoading] = useState(false);
  const { userSession, setToggleSignUpModal, refIFrame } = props;
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm();
  // const { uploadVideo } = useUploadVideo({
  //   videoHeaderId,
  //   videoFile,
  //   setVideoUrl,
  //   setVideoHeaderId,
  //   setSingUpErrorMessage,
  //   setUploadImageSize,
  //   setVideoFile,
  //   refIFrame,
  //   setValue,
  //   watch,
  // });
  // 要分出去的
  useEffect(() => {
    if (videoHeaderId && videoFile) {
      //   let formData = new FormData();
      //   formData.append("file", videoFile);
      //   const {cloudFileId,uploadToken} =videoHeaderId
      //   const iframeItem = refIFrame.current;
      //   iframeItem.contentWindow.postMessage(
      //     JSON.stringify({
      //       method: "videoToken",
      //       data: {
      //         "cloud-file-id": cloudFileId, // 上一個API拿到的 cloudFileId
      // "upload-token": uploadToken,
      // formData
      //       },
      //     }),
      //     "*"
      //   );
      videoUploadServices({ ...videoHeaderId, videoFile })
        .then((res) => {
          setVideoUrl(res?.url);
          setValue("video", res?.url);
          setVideoLoading(false);
        })
        .catch((err) => {
          console.log("axios err=", err);
          setVideoLoading(false);
        });
      setVideoHeaderId(null);
    }
  }, [videoHeaderId, videoFile]);
  useVideoTokenMessage(setVideoHeaderId, refIFrame, setVideoLoading);
  const uploadVideo = async (e, type) => {
    const file = e.target.files[0];
    const allowedExtensions = "video/";

    if (file) {
      if (!file.type.includes(allowedExtensions)) {
        alert("您上傳的檔案格式不支援，請重新上傳～謝謝!");
        setValue("video", null);
        return false;
      }
      validateFile(file);
    }
    function validateFile(file) {
      let video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);

        if (video.duration > 60) {
          setUploadImageSize(true);
          setSingUpErrorMessage([
            "上傳影片長度過長",
            "影片不可超過 60秒 喔，請重新上傳檔案，謝謝!",
            false,
            0,
          ]);
          setValue("video", null);
          return false;
        }
        if (file.size > 100000000) {
          setUploadImageSize(true);
          setSingUpErrorMessage([
            "上傳影片檔案過大",
            "影片不可超過 100MB 喔，請重新上傳檔案，謝謝!",
            false,
          ]);
          setValue("video", null);
          return false;
        }
        if (video.videoWidth / video.videoHeight !== 9 / 16) {
          // alert(
          //   JSON.stringify({
          //     寬度: video.videoWidth,
          //     高度: video.videoHeight,
          //     比例: video.videoWidth / video.videoHeight,
          //   })
          // );
          setUploadImageSize(true);
          setSingUpErrorMessage([
            "上傳影片比例錯誤",
            "請上傳9:16直式影片，請重新上傳檔案，謝謝!",
            false,
            0,
          ]);
          setValue("video", null);
          return false;
        }
        // console.log("成功");
        setVideoFile(file);
        getIframeVideo();
      };

      video.src = URL.createObjectURL(file);
    }
    const getIframeVideo = () => {
      setVideoLoading(true);
      const iframeItem = refIFrame.current;
      iframeItem.contentWindow.postMessage(
        JSON.stringify({
          method: "videoToken",
          data: {
            size: file.size,
          },
        }),
        "*"
      );
    };
  };
  // 分出去的結束
  const introValue = watch("intro");

  const onSubmit = (data) => {
    if (isSubmitting) return;
    const { email, hobby, ig, intro, lineId, nickname, phone, tiktok } = data;
    const gameId = SUPERPOWERS_GAME_ID;

    const userId = userSession;

    const singUpFormData = {
      gameId,
      userId,
      nickname,
      email,
      phone,
      intro,
      playerVideo: { url: videoUrl },
      socialLinks: {
        instagram: ig,
        line: lineId,
        tikTok: tiktok,
      },
      info: { 興趣專長: hobby },
    };

    singUpForm(singUpFormData)
      .then((res) => {
        if (res.playerId) {
          // ga4 api
          gtag("event", "送出報名", {
            gameId: SUPERPOWERS_GAME_ID,
            userSession: userSession,
            send_to: "G-SR7XCS1GYX",
          });

          // bat api
          window.bat.track("buttonClick", {
            buttonNm: "送出報名",
            gameId: SUPERPOWERS_GAME_ID,
            userSession: userSession,
          });
          gtag("event", "conversion", {
            send_to: "AW-862236629/LwrUCMy88dwDENXfkpsD",
          });
          //fb
          window.fbq("track", "CompleteRegistration", {
            gameId: SUPERPOWERS_GAME_ID,
          });
          //line
          window._lt(
            "send",
            "cv",
            {
              type: "CompleteRegistration",
            },
            ["4255d428-e2bb-4c10-8a89-562815a6d9de"]
          );
          alert("報名成功,請等候米特管理員審核喔，謝謝!");
          setToggleSignUpModal(false);
          // setUploadImageSize(true)
          // setSingUpErrorMessage(['報名成功','請等候米特管理員審核喔，謝謝!',true])
          // setUploadImageSize(false)
          return;
        }
      })
      .catch((err) => {
        if (err) {
          setUploadImageSize(true);
          setSingUpErrorMessage([
            "報名失敗",
            err.error + "，若有任何疑問請聯絡官方人員，謝謝!",
            false,
          ]);
          return;
        }
      });
  };
  return (
    <>
      <div className="relative bg-black-4 text-white max-w-xl flex flex-col items-center justify-center rounded-[30px] py-[40px] px-[12px] sm:pl-[48px] sm:pr-[32px] sm:px-[80px] w-[320px] sm:w-[670px]    max-h-[670px] h-[90%] font-NotoSansTC">
        <CloseButton
          size="50"
          fill={"#F3D353"}
          handleClose={() => setToggleSignUpModal(false)}
        />
        <div className="w-full px-[10px] sm:pl-0 sm:pr-[16px] overflow-y-scroll h-full scroll">
          <div className="flex items-center justify-center mb-[40px] sm:mb-[48px]">
            <span className=" text-center sm:text-32px text-[24px] font-black text-yellow-3 whitespace-pre-line">
              2023 Meteor{"\u000A"}奇人異士大賞報名
            </span>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-[24px] relative">
              <label
                htmlFor="nickname"
                className="block  text-base font-bold mb-2"
              >
                <span className="before:content-['*'] before:ml-0.5 before:text-[#FE666C]">
                  暱稱/英文名 (票選活動使用)
                </span>
              </label>
              <input
                id="nickname"
                name="nickname"
                type="text"
                autoComplete="nickname"
                className={styles.input}
                placeholder="暱稱最長5個字"
                {...register("nickname", { required: true, maxLength: 5 })}
              />
              {errors.nickname && (
                <small className={styles.error}>
                  請輸入暱稱或不可超過五個字數
                </small>
              )}
            </div>
            <div className="mb-[24px] relative">
              <label
                htmlFor="email-address"
                className="block  text-base font-bold mb-2"
              >
                <span className="before:content-['*'] before:ml-0.5 before:text-[#FE666C]">
                  聯絡信箱
                </span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className={styles.input}
                placeholder="請輸入電子信箱"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <small className={styles.error}>請輸入電子信箱</small>
              )}
            </div>

            <div className="mb-[24px] relative">
              <label
                htmlFor="phone"
                className="block  text-base font-bold mb-2"
              >
                <span className="before:content-['*'] before:ml-0.5 before:text-[#FE666C]">
                  連絡電話(僅作活動得獎聯繫之用)
                </span>
              </label>
              <input
                id="phone"
                name="phone"
                type="number"
                autoComplete="phone"
                className={styles.input}
                {...register("phone", { required: true, min: 1 })}
              />
              {errors.phone && (
                <small className={styles.error}>請輸入聯絡電話</small>
              )}
            </div>

            <div className="mb-[24px] relative">
              <label
                htmlFor="lineid"
                className="block  text-base font-bold mb-2"
              >
                <span className="before:content-['*'] before:ml-0.5 before:text-[#FE666C]">
                  LINE ID(僅活動過程中若需與本人聯繫之用)
                </span>
              </label>
              <input
                id="lineid"
                name="lineid"
                type="text"
                autoComplete="lineid"
                className={styles.input}
                // placeholder=""
                {...register("lineId", { required: true })}
              />
              {errors.lineId && (
                <small className={styles.error}>請輸入LINE ID</small>
              )}
            </div>
            <div className="mb-[24px] relative">
              <label htmlFor="ig" className="block  text-base font-bold mb-2">
                Instagram 帳號 (選填/會顯示在個人票選頁面)
              </label>
              <input
                id="ig"
                name="ig"
                type="text"
                autoComplete="ig"
                className={styles.input}
                placeholder="範例: meteor.tw"
                {...register("ig")}
              />
            </div>
            <div className="mb-[24px] relative">
              <label
                htmlFor="TikTok"
                className="block  text-base font-bold mb-2"
              >
                TikTok 帳號 (選填/會顯示在個人票選頁面)
              </label>
              <input
                id="TikTok"
                name="TikTok"
                type="text"
                autoComplete="TikTok"
                className={styles.input}
                placeholder="範例：meteor_tw"
                {...register("tiktok")}
              />
            </div>
            <div className="mb-[24px] relative">
              <label
                htmlFor="hobby"
                className="block  text-base font-bold mb-2"
              >
                <span className="before:content-['*'] before:ml-0.5 before:text-[#FE666C]">
                  興趣專長
                </span>
              </label>
              <input
                id="hobby"
                name="hobby"
                type="text"
                autoComplete="hobby"
                className={styles.input}
                {...register("hobby", { required: true, min: 1 })}
              />
              {errors.hobby && (
                <small className={styles.error}>請輸入興趣專長</small>
              )}
              {introValue && introValue.length > 51 ? (
                <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                  不可超過50字數限制
                </small>
              ) : (
                ""
              )}
            </div>
            <div className="mb-[24px] relative">
              <label
                htmlFor="intro"
                className="block  text-base font-bold mb-2"
              >
                <span className="before:content-['*'] before:ml-0.5 before:text-[#FE666C]">
                  影片介紹
                </span>
              </label>
              <textarea
                id="intro"
                name="intro"
                type="text"
                autoComplete="intro"
                className={styles.input}
                rows="4"
                placeholder="字數限制50字"
                {...register("intro", { required: true, maxLength: 50 })}
              ></textarea>
              {errors.intro && (
                <small className={styles.error}>
                  請輸入影片介紹 or 不可超過50字數限制
                </small>
              )}
              {introValue && introValue.length > 51 ? (
                <small className="relative left-12px top-N18px text-red-500 text-xs italic">
                  不可超過50字數限制
                </small>
              ) : (
                ""
              )}
            </div>

            <div className=" flex flex-col items-center bg-grey-lighter mb-[24px] relative">
              <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-1 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-yellow-4  group">
                <img
                  src={upload}
                  alt={"上傳icon"}
                  className={"blcok group-hover:hidden w-[32px]"}
                />
                <img
                  src={uploadHover}
                  alt={"上傳icon"}
                  className={"hidden group-hover:block w-[32px]"}
                />
                <span className="mt-2 text-base font-bold leading-normal text-black-4">
                  {!videoFile ? "上傳60秒直式影片" : "重新上傳"}
                </span>
                <span className="block text-xs text-red-500">
                  請上傳一個最多60秒的直式影片(9:16)
                </span>
                <span className="block text-xs text-red-500">
                  檔案大小需小於100MB
                </span>
                <input
                  accept="video/*"
                  {...register("video", { required: true })}
                  className="hidden w-full text-sm text-slate-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-sm file:font-semibold
                                file:bg-violet-50 file:text-violet-700
                                hover:file:bg-violet-100"
                  type="file"
                  name="video"
                  onChange={(e) => uploadVideo(e, "video")}
                  ref={videoRef}
                />
              </label>
              {errors.video && (
                <div className={styles.error}>請上傳直式影片</div>
              )}
              {videoUrl ? (
                <div className="w-64 mt-[16px] h-[455.168px] bg-gray-1 relative">
                  <Video
                    video={{
                      url: videoUrl,
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className="relative">
              <div className="flex items-start realtive mb-[24px]">
                <input
                  id="agree1"
                  name="agree1"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500
                            border-gray-300 rounded css-checkbox"
                  {...register("agree1", { required: true })}
                />
                <label className="ml-2 block text-sm " htmlFor="agree1">
                  <span className="squire"></span>
                  <span className="title">
                    我已了解本活動須為Meteor會員本人報名且為本人之特殊才能影片，一旦經查證或告發假借不真實身分參加本活動，將取消其參加活動資格，並追究法律責任。
                  </span>
                </label>
              </div>
              <div className="flex items-start">
                <input
                  id="agree2"
                  name="agree2"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500
                            border-gray-300 rounded css-checkbox"
                  {...register("agree2", { required: true })}
                />

                <label
                  className="ml-2 block text-sm text-medium "
                  htmlFor="agree2"
                >
                  <span className="squire"></span>
                  <span className="title">
                    我已閱讀
                    <a
                      className="text-yellow-4 underline"
                      href={
                        window.location.origin +
                        window.location.pathname +
                        "#activities"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      活動注意事項
                    </a>
                    及
                    <a
                      href="https://wooo.tw/3r7aMhG"
                      className="text-yellow-4 underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      個人資料蒐集同意書
                    </a>
                    ，並同意上傳影片後同時授權主辦單位有永久、無償利用，利用方式包括但不限於重製、散布、公開傳輸、公開播送、編輯、改作等一切必要權利。
                  </span>
                </label>
              </div>
              {(errors.agree1 || errors.agree2) && (
                <div className={`${styles.error}`}>請勾選注意事項</div>
              )}
            </div>

            <div className="flex w-full justify-center">
              <button
                type="submit"
                className={`bg-yellow-4 text-black-4 text-20px rounded-full font-black flex justify-center items-center  w-[130px] h-[44px] shadow-superButton `}
                disabled={isSubmitting}
              >
                送出報名
              </button>
            </div>
          </form>
          <div className="w-full text-sm mt-24px  text-center">
            報名時遇到問題嗎？
            <a
              href="https://m.me/MeteorApp"
              className="text-yellow-4 underline"
              target="_blank"
              rel="noreferrer"
            >
              請聯絡粉絲專頁！
            </a>
          </div>
        </div>
      </div>
      <Modal
        handleClose={() => uploadImageModalHandler(false)}
        isOpen={uploadImageSize}
      >
        <Alert
          title={singUpErrorMessage[0]}
          content={singUpErrorMessage[1]}
          success={setSingUpErrorMessage[2]}
          uploadImageModalHandler={uploadImageModalHandler}
        />
      </Modal>
      {videoLoading ? <Loading /> : null}
    </>
  );
};

export default SignUp;
